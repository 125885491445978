import React, { useEffect } from "react";
import TenantList from "./tenant-list/TenantList";
import AddNewTenant from "./add-new-tenant/AddNewTenant";
import { useDispatch, useSelector } from "react-redux";
import { tenantManagementActions } from "../../store";

const TenantManagementLayout = () => {
    const dispatch = useDispatch();
    const loadData = useSelector((state) => state.tenantManagement.loadData);

    useEffect(() => {
        dispatch(tenantManagementActions.getTenantInfos());
    }, []);
    useEffect(() => {
        loadData && dispatch(tenantManagementActions.getTenantInfos());
    }, [loadData]);

    return (
        <div className="w-full flex flex-row space-x-6">
            <div className="w-2/3">
                <div className="text-2xl mb-3 font-bold">Tenant Listesi</div>
                <TenantList />
            </div>
            <div className="w-1/3">
                <div className="font-semibold text-xl">Yeni Tenant Ekle</div>
                <AddNewTenant />
            </div>
        </div>
    );
};

export default TenantManagementLayout;
