import React, { useState } from "react";
import { Modal } from "../../components/modal/Modal";
import { useDispatch, useSelector } from "react-redux";
import { tableManagementActions } from "../../store";

const AddTableModal = ({ handleSave, modalTitle }) => {
    const dispatch = useDispatch();

    const tablesField = useSelector((state) => state.tableManagement.allScope);
    const allTable = useSelector((state) => state.tableManagement.tables);
    const selectedTableId = useSelector(
        (state) => state.tableManagement.parentTableId,
    );
    const selectedTableScopeId = useSelector(
        (state) => state.tableManagement.parentTableIdScopeId,
    );
    const selectedTableTitle = useSelector(
        (state) => state.tableManagement.parentTableTitle,
    );

    const [form, setForm] = useState({
        name: selectedTableTitle || "",
        selectedCategory: selectedTableScopeId || "",
        id: selectedTableId || "",
    });

    const onChange = (e) => {
        setForm({ ...form, [e.target.name]: e.target.value });
    };

    const showModal = useSelector(
        (state) => state.tableManagement.showTableAddModal,
    );

    const findTable = allTable.find(
        (table) => parseInt(table.id) === parseInt(selectedTableId),
    );

    const qrCode = findTable?.qrcodeimage;
    const qrImageSrc = qrCode ? `/qr-images/${qrCode}` : null;
    const qrCodeDownload = (e) => {
        dispatch(tableManagementActions.downloadTableQrCode(e));
    };

    return (
        <Modal
            modalTitle={modalTitle}
            setIsActive={(e) =>
                dispatch(tableManagementActions.setShowTableAddModal(e))
            }
            isActive={showModal}
            handleSave={() => {
                handleSave(form);
                setForm({
                    selectedCategory: "",
                    name: "",
                });
            }}
            disabledBtn={!form.selectedCategory || !form.name?.trim()}
        >
            {qrImageSrc && (
                <div className="my-4">
                    <h5 className="!text-purple-700">Restoran İçi QR Kod</h5>
                    <div className="w-48 h-48">
                        <img src={qrImageSrc} alt="QR Code" />
                    </div>
                    <button
                        onClick={() => qrCodeDownload(form)}
                        className="!text-purple-700 h-8 !hover:text-white border !border-purple-700 !hover:bg-purple-800 !focus:ring-4 !focus:outline-none !focus:ring-purple-300 !font-medium !rounded-lg !text-xs !px-5 !text-center !me-2 !mb-2 dark:border-purple-400 dark:text-purple-400 dark:hover:text-white dark:hover:bg-purple-500 dark:focus:ring-purple-900"
                    >
                        QR Kodu İndir
                    </button>
                </div>
            )}
            {selectedTableScopeId ? (
                <div className="flex flex-col space-y-5 gap-3">
                    <div>
                        <div className="font-bold text-sm">Alan Seçiniz:</div>
                        <select
                            disabled
                            name="selectedCategory"
                            value={selectedTableScopeId}
                            className="border rounded p-2 w-full" // Basit bir stil eklenmiştir
                        >
                            {tablesField.map((table) => {
                                const tableId = Number(table.id);
                                const selectedId = Number(selectedTableScopeId);
                                if (tableId === selectedId) {
                                    return (
                                        <option key={tableId} value={tableId}>
                                            {table.title}
                                        </option>
                                    );
                                }
                                return null;
                            })}
                        </select>
                    </div>
                    <div>
                        <label className="block font-bold text-sm">
                            Etiket:
                        </label>
                        <input
                            type="text"
                            value={form.name}
                            placeholder="Masa İsmi"
                            name="name"
                            onChange={onChange}
                            className="border rounded p-2 w-full" // Basit bir stil eklenmiştir
                        />
                    </div>
                </div>
            ) : (
                <div className="flex flex-col space-y-5 gap-3">
                    <div>
                        <small className="font-bold">Alan Seçiniz:</small>
                        <select
                            placeholder="Alan Seçiniz"
                            value={form.selectedCategory}
                            name="selectedCategory"
                            onChange={onChange}
                            className="border rounded p-2 w-full" // Basit bir stil eklenmiştir
                        >
                            {tablesField.map((table) => (
                                <option key={table.id} value={table.id}>
                                    {table.title}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div>
                        <label className="block font-bold text-sm">
                            Etiket:
                        </label>
                        <input
                            type="text"
                            placeholder="Masa İsmi"
                            value={form.name}
                            name="name"
                            onChange={onChange}
                            className="border rounded p-2 w-full" // Basit bir stil eklenmiştir
                        />
                    </div>
                </div>
            )}
        </Modal>
    );
};

export default AddTableModal;
