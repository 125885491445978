export const replaceTurkishChars = (str) => {
    if (typeof str !== "string") return "";

    const turkishMap = {
        ç: "c",
        Ç: "C",
        ğ: "g",
        Ğ: "G",
        ı: "i",
        İ: "I",
        ö: "o",
        Ö: "O",
        ş: "s",
        Ş: "S",
        ü: "u",
        Ü: "U",
    };
    return str.replace(/[çğışöüÇĞİŞÖÜ]/g, (char) => turkishMap[char] || char);
};
