import React, { useEffect, useState } from "react";

const MenuTab = ({ options, onChange }) => {
    const [menuOptions, setMenuOptions] = useState(options);

    useEffect(() => {
        setMenuOptions(options);
    }, [options]);

    const handleMenuOptionChange = (optionName, checked) => {
        const newMenuOptions = menuOptions.map((option) => {
            if (option.name === optionName) {
                return {
                    ...option,
                    value: checked,
                };
            }
            return option;
        });
        setMenuOptions(newMenuOptions);
        onChange && onChange(newMenuOptions);
    };

    return (
        <div className="grid grid-cols-2 gap-4">
            {menuOptions?.length > 0 &&
                menuOptions.map((option, i) => (
                    <div
                        key={i}
                        className="flex flex-row space-x-3 items-center"
                    >
                        <label className="relative inline-flex items-center cursor-pointer">
                            <input
                                type="checkbox"
                                checked={option?.value}
                                onChange={(e) =>
                                    handleMenuOptionChange(
                                        option.name,
                                        e.target.checked,
                                    )
                                }
                                className="sr-only peer"
                            />
                            <div className="w-11 h-6 bg-gray-300 peer-focus:outline-none peer-focus:ring-2 peer-focus:ring-green-500 rounded-full peer peer-checked:bg-green-600 peer-checked:before:translate-x-5 peer-checked:before:bg-white before:content-[''] before:absolute before:top-0.5 before:left-[2px] before:bg-gray-500 before:border before:rounded-full before:h-5 before:w-5 before:transition-all"></div>
                        </label>
                        <span
                            className={`${
                                option?.value
                                    ? "!text-green-600"
                                    : "text-gray-300"
                            } font-bold`}
                        >
                            {option.text}
                        </span>
                    </div>
                ))}
        </div>
    );
};

export default MenuTab;
