import React, { useState } from "react";
import Input from "../../../v2-components/input/Input";
import { BaseButton } from "../../../v2-components/button/Button";
import { useDispatch } from "react-redux";
import { tenantManagementActions } from "../../../store";

const AddNewTenant = () => {
    const dispatch = useDispatch();

    const [company, setCompany] = useState("");
    const [domain, setDomain] = useState("");

    const handleSave = () => {
        dispatch(tenantManagementActions.addTenant({ company, domain }));
        setCompany("");
        setDomain("");
    };
    return (
        <div className="bg-white p-2 shadow-md rounded-xl mt-4">
            <div className="flex flex-col  gap-2 mt-3">
                <label htmlFor="company">Şirket Adı:</label>
                <Input
                    placeholder="Şirket adını giriniz"
                    name="company"
                    value={company}
                    onChange={(e) => setCompany(e.target.value)}
                />
            </div>
            <div className="flex flex-col  gap-2 mt-3">
                <label htmlFor="domain">Sub Domain:</label>
                <Input
                    placeholder="Sub domain giriniz"
                    name="domain"
                    value={domain}
                    onChange={(e) => setDomain(e.target.value)}
                />
            </div>

            <div className="mt-3 w-full flex justify-end ">
                <BaseButton
                    onClick={handleSave}
                    className="bg-mm-secondary text-white"
                >
                    Ekle
                </BaseButton>
            </div>
        </div>
    );
};

export default AddNewTenant;
