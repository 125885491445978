import React from "react";

function DeleteModal({
    title,
    onClose,
    isOpen,
    text,
    handleDelete,
    setSelectedTableDeleteCategory,
    setSelectedDeleteTableId,
}) {
    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 z-[2000] flex items-center justify-center bg-black bg-opacity-50">
            <div className="bg-white rounded-lg shadow-lg p-6 w-1/3">
                <h2 className="text-xl font-bold">{title}</h2>
                <button
                    className="absolute top-2 right-2 text-gray-600 hover:text-gray-900"
                    onClick={() => {
                        setSelectedTableDeleteCategory(null);
                        setSelectedDeleteTableId(null);
                        onClose();
                    }}
                >
                    &times; {/* Kapatma simgesi */}
                </button>
                <p className="mt-4">{text}</p>
                <div className="mt-4 flex justify-end gap-2">
                    <button
                        className="px-4 py-2 !bg-gray-300 !rounded-md mr-2"
                        onClick={() => {
                            setSelectedTableDeleteCategory(null);
                            setSelectedDeleteTableId(null);
                            onClose();
                        }}
                    >
                        Hayır
                    </button>
                    <button
                        className="px-4 py-2 !bg-red-500 text-white !rounded-md"
                        onClick={() => {
                            handleDelete();
                            onClose();
                        }}
                    >
                        Evet
                    </button>
                </div>
            </div>
        </div>
    );
}

export default DeleteModal;
