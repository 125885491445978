import React, { useEffect } from "react";
import PaymentList from "./payment-list/PaymentList";
import { useDispatch } from "react-redux";
import { paymentManagementActions } from "../../store";
const PaymentManagementLayout = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(paymentManagementActions.getPaymentInfos());
    }, []);
    return (
        <div className="w-full">
            <PaymentList />
        </div>
    );
};

export default PaymentManagementLayout;
