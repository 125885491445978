import React, { useEffect, useState } from "react";
import { Modal } from "../../components/modal/Modal";
import { useDispatch, useSelector } from "react-redux";
import Input from "../../components/input/Input";
import RemovedMaterialInput from "./components/RemovedMaterialInput";
import ImageInput from "../../components/image-input/ImageInput";
import { IoIosCloseCircle } from "react-icons/io";
import { menuServiceActions } from "../../store/menu.service.slice";
import ProductPreferencesList from "./components/ProductPreferencesList";
import LanguageSelector from "../../components/language-selector/LanguageSelector";
import PreferenceModal from "./components/PreferenceModal";
import { SemiSolidButton } from "../../v2-components/button/Button";

const emptyLangObj = { TRTR: "", ENUS: "" };

const ProductModal = ({ modalTitle, handleSave }) => {
    const [activeTab, setActiveTab] = useState("temelBilgiler");

    const handleTabClick = (tab) => {
        setActiveTab(tab);
    };
    const dispatch = useDispatch();
    const {
        showProductModal,
        parentCategoryId,
        parentCategoryTitle,
        selectedProduct,
        modalLoading,
    } = useSelector((state) => state.menuService);

    const [previewImage, setPreviewImage] = useState(null);
    const [detailPreviewImages, setDetailPreviewImages] = useState([]);
    const [editedPreference, setEditedPreference] = useState(null);
    const [formData, setFormData] = useState({
        id: null,
        title: emptyLangObj,
        description: emptyLangObj,
        price: "",
        allergen: emptyLangObj,
        removed_material: emptyLangObj,
        image: null,
        detailImages: [],
        category_id: parentCategoryId,
        preferences: [],
    });

    useEffect(() => {
        if (selectedProduct) {
            setFormData({
                id: selectedProduct?.id,
                title: selectedProduct?.title || emptyLangObj,
                description: selectedProduct?.description || emptyLangObj,
                price: selectedProduct?.price || "",
                allergen: selectedProduct?.allergen || emptyLangObj,
                removed_material:
                    selectedProduct?.removed_material || emptyLangObj,
                image: selectedProduct?.image || null,
                category_id: selectedProduct?.category_id || parentCategoryId,
                detailImages: selectedProduct?.detailImages || [],
                preferences: selectedProduct?.preferences || [],
            });
        }
    }, [selectedProduct]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    function generateId() {
        const characters =
            "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
        let id = "";
        for (let i = 0; i < 5; i++) {
            id += characters.charAt(
                Math.floor(Math.random() * characters.length),
            );
        }
        return id;
    }

    const handleDetailImageChange = (e) => {
        const file = e.target.files[0];
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = () => {
            const base64String = reader.result.split(",")[1];
            const image = { id: generateId(), image: base64String };
            setDetailPreviewImages([...detailPreviewImages, image]);

            setFormData({
                ...formData,
                detailImages: [...formData.detailImages, image],
            });
        };
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = () => {
            const base64String = reader.result.split(",")[1];
            setPreviewImage(base64String);

            setFormData({
                ...formData,
                image: base64String,
            });
        };
    };

    const deleteDetailImage = (id) => {
        if (detailPreviewImages?.length > 0) {
            const updated = detailPreviewImages?.filter(
                (item) => item.id !== id,
            );
            setDetailPreviewImages(updated);
        }

        setFormData({
            ...formData,
            detailImages: formData?.detailImages?.filter(
                (item) => item.id !== id,
            ),
        });
    };

    const handleLangChange = (lang, e, field) => {
        setFormData({
            ...formData,
            [field]: { ...formData[field], [lang]: e.target.value },
        });
    };

    return (
        <Modal
            modalTitle={modalTitle}
            disabledBtn={
                formData.title?.TRTR === undefined ||
                !formData.title?.TRTR.trim()
            }
            isActive={showProductModal}
            setIsActive={(e) =>
                dispatch(menuServiceActions.setShowProductModal(e))
            }
            handleSave={() => handleSave(formData)}
            isLoading={modalLoading}
        >
            <div className="flex items-center gap-2">
                <label>Kategori Adı :</label>
                <label className="text-teal-600">{parentCategoryTitle}</label>
            </div>
            <div className="border-b border-gray-300">
                <div className="flex space-x-4">
                    <button
                        className={`py-2 px-4 ${
                            activeTab === "temelBilgiler"
                                ? "text-teal-600 border-b-2 border-teal-600"
                                : "text-teal-600 border-b-2 border-transparent hover:border-teal-600"
                        } focus:outline-none`}
                        onClick={() => handleTabClick("temelBilgiler")}
                    >
                        Temel Bilgiler
                    </button>
                    <button
                        className={`py-2 px-4 ${
                            activeTab === "detaylar"
                                ? "text-teal-600 border-b-2 border-teal-600"
                                : "text-teal-600 border-b-2 border-transparent hover:border-teal-600"
                        } focus:outline-none`}
                        onClick={() => handleTabClick("detaylar")}
                    >
                        Detaylar
                    </button>
                    <button
                        className={`py-2 px-4 ${
                            activeTab === "tercihler"
                                ? "text-teal-600 border-b-2 border-teal-600"
                                : "text-teal-600 border-b-2 border-transparent hover:border-teal-600"
                        } focus:outline-none`}
                        onClick={() => handleTabClick("tercihler")}
                    >
                        Tercihler
                    </button>
                </div>
                <div className="mt-4">
                    {/* Temel Bilgiler Tabı */}
                    {activeTab === "temelBilgiler" && (
                        <div className="space-y-10">
                            <LanguageSelector
                                data={formData.title}
                                onChange={(lang, value) =>
                                    handleLangChange(lang, value, "title")
                                }
                            >
                                <Input label="İsim" />
                            </LanguageSelector>

                            <LanguageSelector
                                data={formData.description}
                                onChange={(lang, value) =>
                                    handleLangChange(lang, value, "description")
                                }
                            >
                                <Input label="Açıklama" />
                            </LanguageSelector>

                            <Input
                                type="number"
                                label="Fiyat"
                                name="price"
                                value={formData.price}
                                onChange={handleChange}
                            />
                        </div>
                    )}

                    {/* Detaylar Tabı */}
                    {activeTab === "detaylar" && (
                        <div className="space-y-10">
                            <LanguageSelector
                                data={formData.removed_material}
                                onChange={(lang, value) =>
                                    setFormData({
                                        ...formData,
                                        removed_material: {
                                            ...formData.removed_material,
                                            [lang]: value,
                                        },
                                    })
                                }
                            >
                                <RemovedMaterialInput label="Malzemeleri Girin:" />
                            </LanguageSelector>
                            <LanguageSelector
                                data={formData.allergen}
                                onChange={(lang, value) =>
                                    handleLangChange(lang, value, "allergen")
                                }
                            >
                                <Input label="Alerjen Bilgileri" />
                            </LanguageSelector>

                            <div>
                                <label className="font-semibold">Görsel</label>

                                <ImageInput
                                    previewImage={previewImage}
                                    dataImage={formData.image}
                                    handleFileChange={handleFileChange}
                                />
                            </div>

                            <div className="mt-5">
                                <label>Detay Görselleri</label>
                                <div className="flex space-x-2 flex-wrap my-3">
                                    {formData?.detailImages?.map((item) => (
                                        <span
                                            className="relative"
                                            key={item?.id}
                                        >
                                            <img
                                                className="!max-w-14 !mr-5"
                                                src={`data:image/jpeg;base64,${item?.image}`}
                                                alt="image"
                                            />

                                            <span
                                                onClick={() =>
                                                    deleteDetailImage(item?.id)
                                                }
                                                className="absolute top-[-10px] right-[15px] cursor-pointer"
                                            >
                                                <IoIosCloseCircle size={20} />
                                            </span>
                                        </span>
                                    ))}
                                </div>

                                <label
                                    htmlFor="detailImages"
                                    className="bg-gray-100 !border border-gray-400 hover:bg-gray-300 text-border-gray-400 font-semibold py-2 px-4 rounded inline-flex items-center max-h-10 cursor-pointer"
                                >
                                    Dosya Seç
                                </label>
                                <input
                                    id="detailImages"
                                    name="detailImages"
                                    type="file"
                                    accept="image/*"
                                    className="hidden"
                                    onChange={handleDetailImageChange}
                                />
                            </div>
                        </div>
                    )}

                    {/* Tercihler Tabı */}
                    {activeTab === "tercihler" && (
                        <div className="flex flex-col gap-y-3">
                            <SemiSolidButton
                                onClick={() =>
                                    dispatch(
                                        menuServiceActions.setShowPreferenceModal(
                                            true,
                                        ),
                                    )
                                }
                            >
                                Tercih Ekle
                            </SemiSolidButton>
                            {formData.preferences && (
                                <ProductPreferencesList
                                    preferences={formData.preferences}
                                    onEdit={(e) => {
                                        setEditedPreference(e);
                                        dispatch(
                                            menuServiceActions.setShowPreferenceModal(
                                                true,
                                            ),
                                        );
                                    }}
                                />
                            )}
                        </div>
                    )}

                    <PreferenceModal
                        preferences={formData.preferences}
                        editedPreference={editedPreference}
                        setEditedPreference={setEditedPreference}
                        onSave={(preferences) =>
                            setFormData({ ...formData, preferences })
                        }
                        title={"Yeni Tercih Ekle"}
                    />
                </div>
            </div>
        </Modal>
    );
};

export default ProductModal;
