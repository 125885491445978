import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { feedbackActions } from "../../store/feedback.slice";
import Card from "../../components/card/Card";

const FeedBackLayout = () => {
    const dispatch = useDispatch();
    const feedback = useSelector((state) => state.feedback.feedback);

    useEffect(() => {
        dispatch(feedbackActions.getFeedback());
    }, [dispatch]);

    return (
        <div className="p-6">
            <div className="text-2xl mb-3 font-bold">Geri Bildirimler</div>
            <Card>
                <table className="min-w-full">
                    <thead>
                        <tr>
                            <th className="px-4 py-2 border-b-2">ID</th>
                            <th className="px-4 py-2 border-b-2">
                                Kullanıcı Adı
                            </th>
                            <th className="px-4 py-2 border-b-2">
                                Geri Bildirim
                            </th>
                            <th className="px-4 py-2 border-b-2">Puan</th>
                            <th className="px-4 py-2 border-b-2">Durum</th>
                        </tr>
                    </thead>
                    <tbody>
                        {feedback && feedback.length > 0 ? (
                            feedback.map((item) => (
                                <tr key={item.id} className="hover:bg-gray-50">
                                    <td className="px-4 py-2 border-b">
                                        {item.id}
                                    </td>
                                    <td className="px-4 py-2 border-b">
                                        {item.name}
                                    </td>
                                    <td className="px-4 py-2 border-b">
                                        {item.note}
                                    </td>
                                    <td className="px-4 py-2 border-b">
                                        {item.rate}
                                    </td>
                                    <td className="px-4 py-2 border-b">
                                        {item.status === 1 ? "Aktif" : "Pasif"}
                                    </td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td
                                    colSpan="5"
                                    className="px-4 py-2 text-center border-b"
                                >
                                    Geri Bildirim Yok
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </Card>
        </div>
    );
};

export default FeedBackLayout;
