import React, { useRef, useEffect } from "react";
import { IoMdClose } from "react-icons/io";
import Loading from "../loading/Loading";

export const Modal = ({
    isActive,
    setIsActive,
    children,
    modalTitle,
    handleSave = () => {},
    disabledBtn = false,
    isLoading = false,
}) => {
    const modalRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (modalRef.current && !modalRef.current.contains(event.target)) {
                setIsActive(false);
            }
        };

        if (isActive) {
            document.addEventListener("mousedown", handleClickOutside);
        } else {
            document.removeEventListener("mousedown", handleClickOutside);
        }

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [isActive, setIsActive]);

    return (
        <>
            <div
                ref={modalRef}
                className={`fixed top-0 right-0 z-[9999]  ${isActive ? "" : "hidden"} w-[500px] h-screen bg-white transition duration-300 ease-in-out z-[9999] rounded-l-lg `}
            >
                <div className="relative h-full">
                    <div className="flex px-4 py-2 !border-b border-gray-400 font-semibold text-lg items-center space-x-4">
                        <span
                            onClick={() => setIsActive(false)}
                            className="cursor-pointer"
                        >
                            <IoMdClose className="text-gray-500" />
                        </span>
                        <span>{modalTitle}</span>
                    </div>
                    <div className="p-3 overflow-y-auto max-h-[80vh]">
                        {isLoading ? <Loading /> : children}
                    </div>
                    <div className="!border-t border-gray-400 h-20 p-3 w-full absolute bottom-0 left-0 flex flex-row justify-end !space-x-5">
                        <button
                            onClick={() => setIsActive(false)}
                            className="px-3 py-2 h-10 font-semibold !bg-white !text-gray-500 !border border-gray-500 !rounded-xl"
                        >
                            İptal
                        </button>
                        <button
                            disabled={disabledBtn}
                            onClick={handleSave}
                            className={`px-3 py-2 h-10 font-semibold !bg-teal-500 text-white !rounded-xl flex space-x-1 items-center ${disabledBtn && "opacity-30 cursor-default"}`}
                        >
                            <span>Kaydet</span>
                        </button>
                    </div>
                </div>
            </div>
            {isActive && (
                <div className="fixed top-0 left-0 w-full h-full bg-black opacity-50 z-[9998]"></div>
            )}
        </>
    );
};
