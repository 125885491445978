import React, { useEffect, useState } from "react";
import { FaPlus, FaMinus } from "react-icons/fa6";
import InputArea from "../../../components/input-area/InputArea";

const Basket = ({ basketProducts, setBasketProducts }) => {
    const [groupedBasket, setGroupedBasket] = useState([]);

    useEffect(() => {
        const mergedBasket = {};

        basketProducts.forEach((product) => {
            const { id, title, price, quantity } = product;
            if (!mergedBasket[id]) {
                mergedBasket[id] = { id, title, price, quantity };
            } else {
                mergedBasket[id].quantity += quantity;
            }
        });

        const mergedBasketArray = Object.values(mergedBasket);
        setGroupedBasket(mergedBasketArray);
    }, [basketProducts]);

    const handleDecrease = (id) => {
        const updatedBasket = basketProducts
            .map((product) => {
                if (product.id === id) {
                    const newQuantity = product.quantity - 1;
                    if (newQuantity < 0) {
                        return null;
                    } else {
                        return { ...product, quantity: newQuantity };
                    }
                }
                return product;
            })
            ?.filter(Boolean);

        setBasketProducts(updatedBasket);
    };

    const handleIncrease = (id) => {
        const updatedBasket = basketProducts.map((product) => {
            if (product.id === id) {
                return { ...product, quantity: product.quantity + 1 };
            }
            return product;
        });

        setBasketProducts(updatedBasket);
    };

    const handleNoteChange = (id, note) => {
        const updatedBasket = basketProducts.map((product) => {
            if (product.id === id) {
                return { ...product, note };
            }
            return product;
        });

        setBasketProducts(updatedBasket);
    };
    const calculateTotal = () => {
        return groupedBasket.reduce((total, product) => {
            return total + product.price * product.quantity;
        }, 0);
    };

    return (
        <div>
            <table className="min-w-full border-collapse">
                <thead>
                    <tr className="bg-gray-200">
                        <th className="py-2  border border-gray-300">Ürün</th>
                        <th className="py-2  border border-gray-300">Adet</th>
                        <th className="py-2 border border-gray-300">Not</th>
                        <th className="py-2  border border-gray-300"> </th>
                    </tr>
                </thead>
                <tbody>
                    {groupedBasket.length === 0 ? (
                        <tr>
                            <td colSpan="4" className="text-center py-4">
                                Sepet Boş
                            </td>
                        </tr>
                    ) : (
                        groupedBasket.map((product) => (
                            <tr key={product.id}>
                                <td className="py-2 px-4 border border-gray-300">
                                    {product.title.TRTR}
                                    <br />
                                    {product.price} ₺
                                </td>
                                <td className="py-2 px-4 border border-gray-300">
                                    {product.quantity}
                                </td>
                                <td className="py-2  border border-gray-300">
                                    <InputArea
                                        name={`note-${product.id}`}
                                        className="!w-full"
                                        value={product?.note}
                                        onChange={(e) =>
                                            handleNoteChange(
                                                product?.id,
                                                e.target.value,
                                            )
                                        }
                                    />
                                </td>
                                <td className="py-2 border border-gray-300">
                                    <div className="flex justify-evenly">
                                        <button
                                            className="!bg-red-500 p-1 rounded text-white "
                                            onClick={() =>
                                                handleDecrease(product.id)
                                            }
                                        >
                                            <FaMinus />
                                        </button>
                                        <button
                                            className="text-white !bg-green-500 p-1 rounded"
                                            onClick={() =>
                                                handleIncrease(product.id)
                                            }
                                        >
                                            <FaPlus />
                                        </button>
                                    </div>
                                </td>
                            </tr>
                        ))
                    )}
                </tbody>
            </table>
            <div className="flex justify-between p-2">
                <p>Toplam :</p>
                <p>{calculateTotal()} ₺</p>
            </div>
        </div>
    );
};

export default Basket;
