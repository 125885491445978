import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { tenantManagementActions } from "../../../store";
import Input from "../../../v2-components/input/Input";
import { BaseButton } from "../../../v2-components/button/Button";
import Dropdown from "../../../components/dropdown/Dropdown"; // Dropdown bileşenini import ediyoruz

const AddNewUser = () => {
    const dispatch = useDispatch();
    const tenants = useSelector((state) => state.tenantManagement.tenants);

    const [tenantId, setTenantId] = useState("");
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const handleSave = () => {
        dispatch(
            tenantManagementActions.addUserByTenant({
                tenantId,
                name,
                email,
                password,
            }),
        );
        setTenantId("");
        setName("");
        setEmail("");
        setPassword("");
    };

    // Dropdown için options dizisini oluşturuyoruz
    const tenantOptions =
        tenants?.map((tenant) => ({
            name: tenant.id,
            text: tenant.company,
        })) || [];

    return (
        <div className="bg-white p-2 shadow-md  rounded-xl mt-4">
            <div className="flex flex-col gap-2 mt-3">
                <label htmlFor="company">Şirket Adı:</label>
                <Dropdown
                    options={tenantOptions}
                    selectedOption={tenantId}
                    onSelect={(option) => setTenantId(option.name)}
                    text="Kullanıcıyı eklemek istediğiniz şirket seçiniz"
                />
            </div>
            <div className="flex flex-col gap-2 mt-3">
                <label htmlFor="name">Kullanıcı Adı:</label>
                <Input
                    key={"name"}
                    placeholder="Kullanıcı adı giriniz"
                    type="text"
                    name="name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                />
            </div>

            <div className="flex flex-col gap-2 mt-3">
                <label htmlFor="email">Email:</label>
                <Input
                    key={"email"}
                    placeholder="Email giriniz"
                    type="email"
                    name="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
            </div>

            <div className="flex flex-col gap-2 mt-3">
                <label htmlFor="password">Şifre:</label>
                <Input
                    placeholder="Şifre giriniz"
                    type="password"
                    name="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                />
            </div>

            <div className="mt-3 w-full flex justify-end">
                <BaseButton onClick={handleSave} className="bg-mm-secondary">
                    <p className="text-white"> Ekle</p>
                </BaseButton>
            </div>
        </div>
    );
};

export default AddNewUser;
