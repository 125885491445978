import React from "react";
import Badge from "../badge/Badge";

const Input = ({
    label,
    type = "text",
    badgeText,
    IconComponent,
    ...props
}) => {
    return (
        <div>
            <div className="flex gap-2 items-center">
                <span className="font-semibold">{label}</span>
                {badgeText && <Badge text={badgeText} />}
                {IconComponent}
            </div>
            <input
                type={type}
                {...props}
                className="w-full !border !px-2 !border-gray-300 rounded-md h-8 !focus:border-teal-500 !focus:outline-none"
            />
        </div>
    );
};

export default Input;
