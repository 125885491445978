import React from "react";
import { useDispatch } from "react-redux";
import { orderManagementActions } from "../../../store/order-management.slice";
import { replaceTurkishChars } from "../../../helpers/textHelpers";
const ModalAccounts = ({ data, orders, tableName, tableId, setIsActive }) => {
    const dispatch = useDispatch();
    const total = orders?.reduce((acc, order) => {
        return acc + order?.quantity * order?.price;
    }, 0);

    const formatDate = (time) => {
        const date = new Date(time);
        const hour = date.getHours();
        const minute = date.getMinutes();
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();
        const formattedDate = `${day}/${month}/${year} ${hour}:${minute}`;
        return formattedDate;
    };
    const user = JSON.parse(localStorage.getItem("user"));

    const printAccount = () => {
        const total =
            orders?.reduce(
                (acc, item) => acc + (item?.quantity * item?.price || 0),
                0,
            ) || 0;
        const tip = data[0]?.tip || 0;

        const content = `
        <html>
            <head>
                <title>Print</title>
                <style>
                    @media print {
                        body * {
                            visibility: hidden;
                        }
                        .order-box, .order-box * {
                            visibility: visible;
                        }
                        .order-box {
                            position: absolute;
                            left: 5mm;
                            top: 5mm;
                            width: calc(100% - 10mm);
                            height: auto;
                            font-size: 15px;
                            font-weight: bold;
                        }
                        table {
                            width: 100%;
                            border-collapse: collapse;
                            font-size: 13px;
                            margin-bottom: 10px;
                        }
                        th, td {
                            padding: 5px;
                            text-align: left;
                        }
                        th {
                            font-size: 14px;
                        }
                        p {
                            margin: 0; /* Bu satırı ekleyerek p etiketlerinin margin'ini sıfırlıyoruz */
                            padding: 0; /* Padding'i de sıfırlıyoruz */
                        }
                        hr {
                            border: none;
                            border-top: 2px dashed #000; /* Çizginin tipi ve rengi */
                            margin: 10px 0; /* Üst ve alt boşluk */
                        }
                    }
                </style>
            </head>
            <body>
                <div class="order-box">
                    <p>TARİH: ${replaceTurkishChars(formatDate(orders[0]?.created_at))}</p>
                    <p>KULLANICI: ${user.name}</p>
                    <p>GARSON: ${user.name}</p>
                    <p>MASA: ${replaceTurkishChars(tableName)}</p>
        
                    <!-- Üst Yatay Çizgi -->
                    <hr />
        
                    <table>
                        <thead>
                            <tr>
                                <th>MİK</th>
                                <th>ÜRÜN</th>
                                <th>FİYAT</th>
                                 <th>TUTAR</th>
                            </tr>
                        </thead>
                        <tbody>
                            ${orders
                                ?.map(
                                    (item) => `
                                <tr>
                                    <td>${item?.quantity}x</td>
                                    <td>${replaceTurkishChars(item?.title.TRTR)}</td>
                                    <td>${item?.price} ₺</td>
                                    <td>${(item?.quantity * item?.price).toFixed(2)} ₺</td>
                                </tr>
                            `,
                                )
                                .join("")}
                        </tbody>
                    </table>
                    
                    <hr />
        
                    <p>Bahşiş: ${tip.toFixed(2)} ₺</p>
                    <p>Toplam Tutar: ${(total + tip).toFixed(2)} ₺</p>
                </div>
            </body>
        </html>
    `;

        const iframe = document.createElement("iframe");
        iframe.style.position = "absolute";
        iframe.style.top = "-10000px";
        iframe.style.left = "-10000px";
        iframe.style.width = "0";
        iframe.style.height = "0";
        iframe.style.border = "none";
        document.body.appendChild(iframe);

        const iframeWindow = iframe.contentWindow;
        const iframeDoc = iframe.contentDocument || iframeWindow.document;
        iframeDoc.open();
        iframeDoc.write(content);
        iframeDoc.close();
        iframeWindow.focus();
        iframeWindow.print();

        setTimeout(() => {
            document.body.removeChild(iframe);
        }, 1000);
    };

    const closeAccount = () => {
        dispatch(orderManagementActions?.closeTableAccount(tableId));
        setIsActive(false);
    };

    const handleStatusChange = (id, selectedOption) => {
        const requestData = { id, status: selectedOption };
        dispatch(orderManagementActions?.updateAccountStatus(requestData));
    };

    return (
        <div className="flex">
            <div className="w-1/2 p-1">
                <table className="min-w-full divide-y divide-gray-200">
                    {data ? null : (
                        <caption className="text-center mb-4">
                            Masanın siparişi bulunmamaktadır.
                        </caption>
                    )}
                    <thead>
                        <tr className="bg-green-200">
                            <th className="py-2 px-4 border border-gray-300">
                                Ürün Adı
                            </th>
                            <th className="py-2 px-4 border border-gray-300">
                                Ürün Adeti
                            </th>
                            <th className="py-2 px-4 border border-gray-300">
                                Ürün Fiyatı
                            </th>
                        </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                        {orders?.map((order) => (
                            <tr key={order?.orderItemId}>
                                <td className="px-4 py-2">
                                    {order?.title?.TRTR}
                                </td>
                                <td className="px-4 py-2">{order?.quantity}</td>
                                <td className="px-4 py-2">{order?.price}</td>
                            </tr>
                        ))}
                    </tbody>
                    <tfoot>
                        <tr>
                            <th className="px-4 py-2">Toplam</th>
                            <th className="px-4 py-2"></th>
                            <th className="px-4 py-2">{total}</th>
                        </tr>
                    </tfoot>
                </table>
            </div>
            <div className="w-1/2 p-1">
                <table className="min-w-full divide-y divide-gray-200">
                    {data ? null : (
                        <caption className="text-center mb-4">
                            Masanın hesap talebi bulunmamaktadır.
                        </caption>
                    )}
                    <thead>
                        <tr className="bg-green-200">
                            <th className="py-2 px-4 border border-gray-300">
                                Ödeme Şekli
                            </th>
                            <th className="py-2 px-4 border border-gray-300">
                                Bahşİş Miktarı
                            </th>
                            <th className="py-2 px-4 border border-gray-300">
                                Hesap Durumu
                            </th>
                            <th className="py-2 px-4 border border-gray-300">
                                Toplam Tutar
                            </th>
                        </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                        {data?.map((account) => (
                            <tr key={account?.id}>
                                <td className="px-4 py-2">{account?.pay}</td>
                                <td className="px-4 py-2">{account?.tip}</td>
                                <td className="px-4 py-2">
                                    <select
                                        className="border border-gray-300 rounded px-2 py-1 text-xs"
                                        defaultValue={account?.status}
                                        onChange={(e) =>
                                            handleStatusChange(
                                                account?.id,
                                                e.target.value,
                                            )
                                        }
                                    >
                                        <option value="0">Yeni Hesap</option>
                                        <option value="1">
                                            Hesap Hazırlanıyor
                                        </option>
                                    </select>
                                </td>
                                <td className="px-4 py-2">
                                    {parseInt(account?.tip) + parseInt(total)}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                <div className="flex justify-center mt-4">
                    {orders?.length > 0 ? (
                        <div className="flex space-x-2">
                            <button
                                onClick={printAccount}
                                className="px-3 py-2 h-10 font-semibold bg-teal-500 text-white rounded-xl flex items-center"
                            >
                                <span>Fiş Yazdır</span>
                            </button>
                            <button
                                onClick={closeAccount}
                                className="px-3 py-2 h-10 font-semibold bg-red-500 text-white rounded-xl flex items-center"
                            >
                                <span>Hesabı Kapat</span>
                            </button>
                        </div>
                    ) : null}
                </div>
            </div>
        </div>
    );
};

export default ModalAccounts;
