import React, { useEffect } from "react";
import { CloseIcon } from "../../icons";
import { Button } from "../button/Button";
import { Backdrop } from "../backdrop/Backdrop";
import { PositionElement } from "../position-element/PositionElement";

const ModalHeader = ({ title, onDismiss, ...props }) => {
    return (
        <div
            className="flex items-center  p-3 text-white bg-menumate"
            onClick={(e) => e.stopPropagation()}
            {...props}
        >
            <span className="flex-1 font-semibold"> {title}</span>
            <CloseIcon onClick={onDismiss} width="0.75em" height="0.75em" />
        </div>
    );
};

const ModalFooter = ({
    className = "",
    onDismiss = () => {},
    onDismissBtnProps = {},
    onConfirm = () => {},
    onConfirmBtnProps = {},
    children,
    ...props
}) => {
    const dismissButtonProps = {
        colorTheme: "solid-gray",
        children: "Cancel",
        onClick: onDismiss,
        ...onDismissBtnProps,
    };

    const confirmButtonProps = {
        colorTheme: "solid",
        children: "Save",
        onClick: onConfirm,
        ...onConfirmBtnProps,
    };
    return (
        <div
            {...props}
            className={`flex justify-end border-t border-gray-500 p-2 ${className}`}
        >
            {children ?? (
                <>
                    <Button {...dismissButtonProps} />
                    <Button {...confirmButtonProps} />
                </>
            )}
        </div>
    );
};

const ModalContainer = ({
    className = "",
    onClick,
    position = "center",
    ...props
}) => (
    <div
        className={`${
            position === "center"
                ? "bg-white flex flex-col w-[75vw]  max-h-[80vh] rounded-lg overflow-auto"
                : "bg-white flex flex-col min-w-[400px] w-[33vw] h-full overflow-auto"
        } ${className}`}
        onClick={(e) => {
            e.stopPropagation();
            onClick && onClick(e);
        }}
        {...props}
    ></div>
);
export const ModalComponent = ({
    title,
    children,
    position = "center",
    onConfirm,
    onConfirmBtnProps = {
        children: "Save",
    },
    onDismiss,
    onDismissBtnProps = {
        children: "Cancel",
    },
    headerProps,
    footerProps,
    ...props
}) => {
    return (
        <ModalContainer {...props} position={position}>
            {title || headerProps ? (
                <ModalHeader
                    {...headerProps}
                    onDismiss={onDismiss}
                    title={headerProps?.title || title}
                />
            ) : null}

            <div className="flex-1 overflow-auto p-4">{children}</div>

            {onConfirm || onConfirmBtnProps?.onClick ? (
                <ModalFooter
                    {...footerProps}
                    onDismiss={onDismiss}
                    onConfirm={onConfirm}
                    onDismissBtnProps={onDismissBtnProps}
                    onConfirmBtnProps={onConfirmBtnProps}
                />
            ) : null}
        </ModalContainer>
    );
};

export const Modal = ({
    onDismiss,
    position = "center",
    children,
    ...props
}) => {
    useEffect(() => {
        const onKeyDown = (ev) => {
            if (ev.code === "Escape") {
                onDismiss(ev);
            }
        };

        document.addEventListener("keydown", onKeyDown);
        return () => {
            document.removeEventListener("keydown", onKeyDown);
        };
    }, []);

    return (
        <Backdrop {...props} onClick={onDismiss}>
            <PositionElement className="w-full h-full" position={position}>
                {children}
            </PositionElement>
        </Backdrop>
    );
};

export const CardModal = ({ onDismiss, onConfirm, position, ...props }) => (
    <Modal
        position={position}
        onDismiss={onDismiss || props.onDismissBtnProps?.onClick}
    >
        <ModalComponent
            {...props}
            position={position}
            onConfirm={onConfirm}
            onDismiss={onDismiss || props.onDismissBtnProps?.onClick}
        />
    </Modal>
);
