import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { orderManagementActions } from "../../../store/order-management.slice";

const formatTime = (timeString) => {
    const date = new Date(timeString);
    const hour = date.getHours().toString().padStart(2, "0");
    const minute = date.getMinutes().toString().padStart(2, "0");
    return `${hour}:${minute}`;
};

const ModalWaiters = () => {
    const dispatch = useDispatch();

    const waiters =
        useSelector((state) => state.orderManagement.table.waiters) || [];

    const handleStatusChange = (id, selectedOption) => {
        const requestData = { id, status: selectedOption };
        dispatch(orderManagementActions?.updateWaiterStatus(requestData));
    };

    return (
        <div>
            <table className="min-w-full bg-white border border-gray-300">
                {waiters.length > 0 ? null : (
                    <caption className="text-center py-2 text-gray-500">
                        Masanın garson talebi bulunmamaktadır.
                    </caption>
                )}
                <thead>
                    <tr className="bg-blue-200">
                        <th className="py-2 px-4 border border-gray-300">
                            Müşteri Adı
                        </th>
                        <th className="py-2 px-4 border border-gray-300">
                            Garson Notu
                        </th>
                        <th className="py-2 px-4 border border-gray-300">
                            Talep Durumu
                        </th>
                        <th className="py-2 px-4 border border-gray-300">
                            Saat
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {waiters.map((waiter) => (
                        <tr key={waiter?.id} className="hover:bg-gray-100">
                            <td className="py-2 px-4 border border-gray-300">
                                {waiter?.name}
                            </td>
                            <td className="py-2 px-4 border border-gray-300">
                                {waiter?.note}
                            </td>
                            <td className="py-2 px-4 border border-gray-300">
                                <select
                                    className="border border-gray-300 rounded p-1 text-sm"
                                    defaultValue={waiter?.status}
                                    onChange={(e) =>
                                        handleStatusChange(
                                            waiter?.id,
                                            e.target.value,
                                        )
                                    }
                                >
                                    <option value="0">Yeni Talep</option>
                                    <option value="1">Talep Onaylandı</option>
                                </select>
                            </td>
                            <td className="py-2 px-4 border border-gray-300">
                                {formatTime(waiter?.created_at)}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default ModalWaiters;
