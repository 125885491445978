import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { orderManagementActions } from "../../../store/order-management.slice";

const formatTime = (timeString) => {
    const date = new Date(timeString);
    const hour = date.getHours().toString().padStart(2, "0");
    const minute = date.getMinutes().toString().padStart(2, "0");
    return `${hour}:${minute}`;
};

const ModalSongs = () => {
    const dispatch = useDispatch();

    const songs =
        useSelector((state) => state.orderManagement.table.songs) || [];

    const handleStatusChange = (id, selectedOption) => {
        const requestData = { id, status: selectedOption };
        dispatch(orderManagementActions?.updateSongStatus(requestData));
    };

    return (
        <div>
            <table className="min-w-full bg-white border border-gray-300">
                {songs.length > 0 ? null : (
                    <caption className="text-center py-2 text-gray-500">
                        Masanın şarkı talebi bulunmamaktadır.
                    </caption>
                )}
                <thead>
                    <tr className="bg-purple-200">
                        <th className="py-2 px-4 border border-gray-300">
                            Müşteri Adı
                        </th>
                        <th className="py-2 px-4 border border-gray-300">
                            Garson Notu
                        </th>
                        <th className="py-2 px-4 border border-gray-300">
                            Talep Durumu
                        </th>
                        <th className="py-2 px-4 border border-gray-300">
                            Saat
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {songs.map((song) => (
                        <tr key={song?.id} className="hover:bg-gray-100">
                            <td className="py-2 px-4 border border-gray-300">
                                {song?.name}
                            </td>
                            <td className="py-2 px-4 border border-gray-300">
                                {song?.note}
                            </td>
                            <td className="py-2 px-4 border border-gray-300">
                                <select
                                    className="border border-gray-300 rounded p-1 text-sm"
                                    defaultValue={song?.status}
                                    onChange={(e) =>
                                        handleStatusChange(
                                            song?.id,
                                            e.target.value,
                                        )
                                    }
                                >
                                    <option value="0">Yeni İstek</option>
                                    <option value="1">İstek Onaylandı</option>
                                </select>
                            </td>
                            <td className="py-2 px-4 border border-gray-300">
                                {formatTime(song?.created_at)}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default ModalSongs;
