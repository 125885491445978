import React, { useState } from "react";
import Input from "../../../components/input/Input";

const Products = ({ products, addBasket, searchProducts }) => {
    const [searchTerm, setSearchTerm] = useState("");

    const handleSearchChange = (e) => {
        const value = e.target.value;
        setSearchTerm(value);
        searchProducts(value);
    };

    return (
        <div>
            <Input
                placeholder="Bul"
                value={searchTerm}
                onChange={handleSearchChange}
            />
            <div className="flex flex-wrap ">
                {products?.map((product) => (
                    <div key={product?.id} className="p-1 w-1/4">
                        <button
                            onClick={() => addBasket(product)}
                            className="mt-2 items-center p-4 h-full w-full text-center justify-center !bg-gray-100 shadow-md !rounded-[15px]"
                        >
                            {product?.title?.TRTR}
                            <br />
                            {product?.price}₺
                        </button>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Products;
