import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { baseUrl } from "../helpers/baseUrlHelpers";

const extraActions = createExtraActions();
const initialState = {
    loading: false,
    statistics: null,
};

export const slice = createSlice({
    name: "Statistics",
    initialState,
    reducers: {
        increment: (state) => {
            state.value += 1;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(extraActions.getStatistics.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(
            extraActions.getStatistics.fulfilled,
            (state, { payload }) => {
                state.loading = false;
                state.statistics = payload.statistics;
            },
        );
        builder.addCase(extraActions.getStatistics.rejected, (state) => {
            state.loading = false;
            state.loginData = null;
        });
    },
});

function createExtraActions() {
    return {
        getStatistics: getStatistics(),
    };

    function getStatistics() {
        return createAsyncThunk("statistics/getStatistics", async () => {
            try {
                const response = await axios.get(`${baseUrl}/GetStatistics`);
                const statistics = response.data;
                return {
                    statistics,
                };
            } catch (error) {
                console.error("Statistics getirilirken hata oluştu:", error);
                throw error;
            }
        });
    }
}

export const statisticsActions = { ...slice.actions, ...extraActions };

export const statisticsReducer = slice.reducer;
