import React, { useRef, useEffect, useState } from "react";
import { IoMdClose } from "react-icons/io";
import LoadingSmall from "../../components/loading/LoadingSmall";
import { useDispatch, useSelector } from "react-redux";
import { orderManagementActions } from "../../store/order-management.slice";
import Basket from "./components/Basket";
import Products from "./components/Products";
import Categories from "./components/Categories";

export const OrderModal = ({
    isActive,
    setIsActive,
    title,
    isLoading = false,
    tableId,
    getSubCategories,
}) => {
    const [basketProducts, setBasketProducts] = useState([]);
    const [products, setProducts] = useState([]);

    const modalRef = useRef(null);
    const dispatch = useDispatch();
    const categories = useSelector(
        (state) => state?.orderManagement?.categories?.categories,
    );
    const productsInCategories = useSelector(
        (state) => state?.orderManagement?.categories?.products,
    );

    useEffect(() => {
        setProducts(productsInCategories);
    }, [productsInCategories]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (modalRef.current && !modalRef.current.contains(event.target)) {
                setIsActive(false);
            }
        };
        setBasketProducts([]);
        if (isActive) {
            document.addEventListener("mousedown", handleClickOutside);
        } else {
            document.removeEventListener("mousedown", handleClickOutside);
        }

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [isActive, setIsActive]);

    const addBasket = (product) => {
        const existingIndex = basketProducts.findIndex(
            (item) => item.id === product.id,
        );

        if (existingIndex !== -1) {
            const updatedBasketProducts = [...basketProducts];
            updatedBasketProducts[existingIndex].quantity += 1;
            setBasketProducts(updatedBasketProducts);
        } else {
            setBasketProducts([...basketProducts, { ...product, quantity: 1 }]);
        }
    };

    const postOrders = (tableId) => {
        const reqData = [tableId, basketProducts];
        dispatch(orderManagementActions?.postOrders(reqData));
        setIsActive(false);
    };
    const productsAll = useSelector(
        (state) => state?.orderManagement?.products,
    );
    const searchProducts = (word) => {
        const matchedProducts = [];
        productsAll.forEach((product) => {
            const lowercaseTitle = product.title.toLowerCase();
            const lowercaseWord = word.toLowerCase();
            if (lowercaseTitle.includes(lowercaseWord)) {
                matchedProducts.push(product);
            }
        });
        setProducts(matchedProducts);
    };
    return (
        <>
            <div
                ref={modalRef}
                className={`fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-[9999] ${
                    isActive ? "" : "hidden"
                } w-[100%] h-[100vh] bg-white transition duration-300 ease-in-out z-[9999] rounded-lg`}
            >
                <div className="relative h-full">
                    <div className="flex justify-between px-4 py-2 !border-b border-gray-400 font-semibold text-lg items-center ">
                        <span>{title}</span>
                        <span
                            onClick={() => setIsActive(false)}
                            className="cursor-pointer"
                        >
                            <IoMdClose size={20} />
                        </span>
                    </div>
                    <div className="flex">
                        <div className="w-[24%] !border-r-2 p-1 h-[80vh] overflow-y-auto">
                            <Basket
                                basketProducts={basketProducts}
                                setBasketProducts={setBasketProducts}
                            />
                        </div>
                        <div className="w-[38%] !border-r-2 p-1 h-[80vh] overflow-y-auto">
                            <Products
                                products={products}
                                addBasket={addBasket}
                                searchProducts={(word) => searchProducts(word)}
                            />
                        </div>
                        <div className="w-[38%] p-1 h-[80vh]">
                            <Categories
                                categories={categories}
                                getSubCategories={(id) => getSubCategories(id)}
                            />
                        </div>
                    </div>

                    <div className="!border-t border-gray-400 h-20 p-3 w-full absolute bottom-0 left-0 flex flex-row justify-between !space-x-5">
                        <button
                            onClick={() => postOrders(tableId)}
                            className="px-5 py-3 h-10 font-semibold !bg-teal-500 text-white !rounded-xl flex space-x-1 items-center"
                        >
                            <span>Sipariş Ekle</span>
                            {isLoading && <LoadingSmall />}
                        </button>
                        <button
                            onClick={() => setIsActive(false)}
                            className="px-3 py-2 h-10 font-semibold !bg-white !text-gray-500 !border border-gray-500 !rounded-xl"
                        >
                            İptal
                        </button>
                    </div>
                </div>
            </div>
            {isActive && (
                <div className="fixed top-0 left-0 w-full h-full bg-black opacity-50 z-[9998]"></div>
            )}
        </>
    );
};
