import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { tableManagementActions } from "../../store";
import Card from "../../components/card/Card";
import { BsPlusLg } from "react-icons/bs";

const AddTableSpace = () => {
    const dispatch = useDispatch();
    const tablesField = useSelector((state) => state.tableManagement.allScope);
    const isChecked = useSelector(
        (state) => state.tableManagement.findVisibleStatus,
    );

    return (
        <div>
            <Card className="w-auto bg-white mt-3 p-3">
                <div className="flex flex-wrap items-center">
                    <label className="p-1 flex items-center cursor-pointer">
                        <input
                            type="checkbox"
                            className="form-checkbox text-purple-600"
                            checked={
                                isChecked &&
                                isChecked.every(
                                    (field) => field.visible === true,
                                )
                            }
                            onChange={() => {}}
                        />
                        <span className="ml-2">Hepsi</span>
                    </label>
                    {tablesField?.map((tab) => (
                        <label
                            key={tab.id}
                            className="p-1 flex items-center cursor-pointer"
                        >
                            <input
                                type="checkbox"
                                className="form-checkbox text-purple-600"
                                onChange={() =>
                                    dispatch(
                                        tableManagementActions.setFindVisibleStatus(
                                            {
                                                selectedId: tab.id,
                                                tablesField,
                                            },
                                        ),
                                    )
                                }
                            />
                            <span className="ml-2">{tab.title}</span>
                        </label>
                    ))}
                    <button
                        className="bg-transparent text-purple-800 text-sm mx-2 border rounded-sm border-purple-600 flex items-center px-2 py-1"
                        onClick={() => {
                            dispatch(
                                tableManagementActions.setShowTableCategoryAddModal(
                                    true,
                                ),
                            );
                            dispatch(
                                tableManagementActions.setSelectedAddCategoryId(
                                    null,
                                ),
                            );
                        }}
                    >
                        <BsPlusLg className="mr-1" />
                        Alan Ekle
                    </button>
                </div>
            </Card>
        </div>
    );
};

export default AddTableSpace;
