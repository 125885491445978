import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { toaster } from "../helpers/toaster";
import { baseUrl } from "../helpers/baseUrlHelpers";

const extraActions = createExtraActions();
const initialState = {
    loading: false,
    tenants: null,
    adminUsers: null,
    loadData: false,
    loadUsersData: false,
};

export const slice = createSlice({
    name: "tenantManagement",
    initialState,
    reducers: {
        increment: (state) => {
            state.value += 1;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(extraActions.getTenantInfos.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(
            extraActions.getTenantInfos.fulfilled,
            (state, { payload }) => {
                state.loading = false;
                state.tenants = payload;
                state.loadData = false;
            },
        );
        builder.addCase(extraActions.addTenant.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(
            extraActions.addTenant.fulfilled,
            (state, { payload }) => {
                state.loading = false;
                if (payload?.succeed) {
                    toaster.success({ title: payload?.message });
                    state.loadData = true;
                } else {
                    toaster.error({ title: payload?.message });
                }
            },
        );
        builder.addCase(extraActions.addTenant.rejected, () => {
            toaster.error({ autoClose: false });
        });
        builder.addCase(extraActions.getUsers.pending, (state) => {
            state.loading = false;
        });
        builder.addCase(
            extraActions.getUsers.fulfilled,
            (state, { payload }) => {
                state.loading = false;
                state.adminUsers = payload;
                state.loadUsersData = false;
            },
        );
        builder.addCase(extraActions.addUserByTenant.pending, (state) => {
            state.loading = false;
        });
        builder.addCase(
            extraActions.addUserByTenant.fulfilled,
            (state, { payload }) => {
                state.loading = false;
                if (payload?.succeed) {
                    toaster.success({ title: payload?.message });
                    state.loadUsersData = true;
                } else {
                    toaster.error({ title: payload?.message });
                }
            },
        );
    },
});

function createExtraActions() {
    return {
        getTenantInfos: getTenantInfos(),
        addTenant: addTenant(),
        getUsers: getUsers(),
        addUserByTenant: addUserByTenant(),
    };

    function getTenantInfos() {
        return createAsyncThunk("tenantManagement/getTenantInfos", async () => {
            try {
                const response = await axios.get(`${baseUrl}/GetTenantInfos`);
                return response.data;
            } catch (error) {
                console.error("Tenants getirilirken hata oluştu:", error);
                throw error;
            }
        });
    }

    function getUsers() {
        return createAsyncThunk("tenantManagement/getUsers", async () => {
            try {
                const response = await axios.get(`${baseUrl}/GetUsers`);
                return response.data;
            } catch (error) {
                console.error("Users getirilirken hata oluştu:", error);
                throw error;
            }
        });
    }

    function addTenant() {
        return createAsyncThunk("tenantManagement/addTenant", async (data) => {
            const response = await axios.post(`${baseUrl}/AddTenant`, data);

            return response.data;
        });
    }

    function addUserByTenant() {
        return createAsyncThunk(
            "tenantManagement/addUserByTenant",
            async (data) => {
                const response = await axios.post(
                    `${baseUrl}/AddUserByTenant`,
                    data,
                );

                return response.data;
            },
        );
    }
}

export const tenantManagementActions = { ...slice.actions, ...extraActions };

export const tenantManagementReducer = slice.reducer;
