import React, { useEffect, useState } from "react";
import { Modal } from "../../components/modal/Modal";
import { useDispatch, useSelector } from "react-redux";
import Input from "../../components/input/Input";
import ImageInput from "../../components/image-input/ImageInput";
import { menuServiceActions } from "../../store/menu.service.slice";
import LanguageSelector from "../../components/language-selector/LanguageSelector";

const emptyLangObj = { TRTR: "", ENUS: "" };

const CategoryModal = ({ modalTitle, handleSave }) => {
    const dispatch = useDispatch();
    const {
        showCategoryModal,
        parentCategoryId,
        parentCategoryTitle,
        selectedCategory,
        modalLoading,
    } = useSelector((state) => state.menuService);

    const [previewImage, setPreviewImage] = useState(null);

    const [formData, setFormData] = useState({
        id: null,
        title: emptyLangObj,
        image: null,
        parent_id: parentCategoryId || 0,
    });

    useEffect(() => {
        if (selectedCategory) {
            setFormData({
                id: selectedCategory?.id,
                title: selectedCategory?.title || emptyLangObj,
                image: selectedCategory?.image,
                parent_id: selectedCategory?.parent_id,
            });
        }
    }, [selectedCategory]);

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = () => {
            const base64String = reader.result.split(",")[1];
            setPreviewImage(base64String);

            setFormData({
                ...formData,
                image: base64String,
            });
        };
    };

    return (
        <Modal
            disabledBtn={
                formData.title?.TRTR === undefined ||
                !formData.title?.TRTR.trim()
            }
            modalTitle={modalTitle}
            isActive={showCategoryModal}
            setIsActive={(e) =>
                dispatch(menuServiceActions.setShowCategoryModal(e))
            }
            handleSave={() => handleSave(formData)}
            isLoading={modalLoading}
        >
            <div className="flex flex-col space-y-5">
                <div>
                    <label className="font-semibold text-lg">
                        {`${parentCategoryId ? parentCategoryTitle : "Ana Kategori"}`}
                    </label>
                </div>

                <LanguageSelector
                    data={formData.title}
                    onChange={(lang, e) =>
                        setFormData((prev) => ({
                            ...prev,
                            title: { ...prev.title, [lang]: e.target.value },
                        }))
                    }
                >
                    <Input label="İsim" />
                </LanguageSelector>

                <div>
                    <label className="font-semibold">Görsel</label>

                    <ImageInput
                        previewImage={previewImage}
                        dataImage={formData.image}
                        handleFileChange={handleFileChange}
                    />
                </div>
            </div>
        </Modal>
    );
};

export default CategoryModal;
