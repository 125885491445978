import React, { useEffect, useState } from "react";
import MenuTab from "./MenuTab";
import OverviewTab from "./OverviewTab";
import Loading from "../../components/loading/Loading";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { settingsActions } from "../../store/settings.slice";

const menuOptionsJson = [
    {
        text: "Garson Talebi",
        value: false,
        name: "waiterRequest",
    },
    {
        text: "İstek Şarkı",
        value: false,
        name: "songRequest",
    },
    {
        text: "Hesap Talebi",
        value: false,
        name: "accountRequest",
    },
    {
        text: "Sipariş Durumu",
        value: false,
        name: "orderStatus",
    },
    {
        text: "Instagram",
        value: false,
        name: "instagram",
    },
    {
        text: "Whatsapp",
        value: false,
        name: "whatsapp",
    },
    {
        text: "Google Maps",
        value: false,
        name: "googleMaps",
    },
    {
        text: "Bizi Değerlendirin",
        value: false,
        name: "reviewStatus",
    },
];

const formatPhoneNumber = (value) => {
    return value.replace(/[^\d]/g, "");
};

const TabComponent = () => {
    const dispatch = useDispatch();
    const settings = useSelector((state) => state.settings.settings);
    const isLoading = useSelector((state) => state.settings.loading);
    const [overviewOptions, setOverviewOptions] = useState({
        restaurantName: "",
        orderCancelTime: "",
        instagramUrl: "",
        whatsappPhone: "",
        mapUrl: "",
        logo: null,
        qrBackgroundImage: null,
        orderRequestStatus: false,
    });

    const [menuOptions, setMenuOptions] = useState(menuOptionsJson);
    const [activeTab, setActiveTab] = useState("general");

    useEffect(() => {
        if (settings) {
            const formData = {
                restaurantName: settings?.restaurantName || "",
                orderCancelTime: settings?.cancelTime || "",
                instagramUrl: settings?.instagramUrl || "",
                mapUrl: settings?.mapUrl || "",
                whatsappPhone: settings?.whatsappPhone || "",
                logo: settings?.logo || null,
                qrBackgroundImage: settings?.qrBackgroundImage || null,
                orderRequestStatus: settings?.orderRequest || false,
            };
            setOverviewOptions(formData);
            const newMenuOptions = menuOptions.map((option) => {
                return {
                    ...option,
                    value: settings[option.name],
                };
            });
            setMenuOptions(newMenuOptions);
        }
    }, [settings]);

    useEffect(() => {
        dispatch(settingsActions.getSettings());
    }, []);

    const handleSaveSettings = () => {
        const getMenuOptionValue = (name) => {
            return menuOptions.find((item) => item.name === name)?.value;
        };
        const formData = {
            ...overviewOptions,
            whatsappPhone: overviewOptions.whatsappPhone
                ? formatPhoneNumber(overviewOptions.whatsappPhone)
                : null,
            orderStatus: getMenuOptionValue("orderStatus"),
            waiterRequestStatus: getMenuOptionValue("waiterRequest"),
            accountRequest: getMenuOptionValue("accountRequest"),
            songRequestStatus: getMenuOptionValue("songRequest"),
            instagramStatus: getMenuOptionValue("instagram"),
            whatsappStatus: getMenuOptionValue("whatsapp"),
            mapStatus: getMenuOptionValue("googleMaps"),
            reviewStatus: getMenuOptionValue("reviewStatus"),
        };
        dispatch(settingsActions.postSettings(formData));
        toast.success("Ayarlar Başarıyla Kaydedildi", {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
        });
    };

    return (
        <div className="bg-white p-4 min-h-full relative">
            {isLoading ? (
                <Loading height="80vh" />
            ) : (
                <div className="mt-4">
                    <div className="flex space-x-4">
                        <button
                            className={`py-2 px-4  ${
                                activeTab === "general"
                                    ? " !text-mm-secondary  !border-b !border-solid !border-mm-secondary"
                                    : " text-gray-700"
                            }`}
                            onClick={() => setActiveTab("general")}
                        >
                            Genel
                        </button>
                        <button
                            className={`py-2 px-4  ${
                                activeTab === "menu"
                                    ? " !text-mm-secondary  !border-b !border-solid !border-mm-secondary"
                                    : " text-gray-700"
                            }`}
                            onClick={() => setActiveTab("menu")}
                        >
                            Menü
                        </button>
                    </div>

                    <div className="tab-content mt-4">
                        {activeTab === "general" ? (
                            <OverviewTab
                                options={overviewOptions}
                                onChange={(e) => setOverviewOptions(e)}
                            />
                        ) : (
                            <MenuTab
                                options={menuOptions}
                                onChange={(e) => setMenuOptions(e)}
                            />
                        )}
                    </div>

                    <div className="mt-3 w-full flex justify-end absolute top-0 right-5">
                        <button
                            onClick={handleSaveSettings}
                            className="py-2 px-4 !bg-red-500 text-white rounded"
                        >
                            Ayarları Kaydet
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default TabComponent;
