import React from "react";

const AlertModal = ({
    isOpen,
    onClose = () => {},
    onSave = () => {},
    title = "",
    message = "",
}) => {
    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 !z-[2000] flex items-center justify-center bg-black bg-opacity-50">
            <div className="bg-white rounded-lg shadow-lg p-6 w-1/3">
                <h2 className="text-xl font-bold">{title}</h2>
                <p className="font-semibold mt-2">{message}</p>
                <div className="mt-4 flex justify-end">
                    <button
                        className="px-4 py-2 bg-gray-300 rounded-md mr-2"
                        onClick={onClose}
                    >
                        Hayır
                    </button>
                    <button
                        className="px-4 py-2 !bg-red-500 text-white rounded-md"
                        onClick={onSave}
                    >
                        Evet
                    </button>
                </div>
            </div>
        </div>
    );
};

export default AlertModal;
