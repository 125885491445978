import React from "react";

const IconContainer = ({
    onClick = () => {},
    as: IconComponent, // Icon bileşeni
    className = "",
    color = "text-black", // Tailwind sınıfı
}) => {
    return (
        <span onClick={onClick} className={`cursor-pointer ${className}`}>
            <IconComponent className={` ${color}`} />
        </span>
    );
};

export default IconContainer;
