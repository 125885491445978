import React from "react";

const PaymentFailure = () => {
    return (
        <div className="flex items-center justify-center min-h-screen bg-red-100">
            <div className="max-w-md w-full bg-white shadow-md rounded-lg p-6">
                <div className="flex items-center justify-center mb-4">
                    <svg
                        className="w-12 h-12 text-red-600"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M18.364 5.636a9 9 0 111.415 1.415L13.414 12l6.365 6.364a9 9 0 01-1.415 1.415L12 13.414l-6.364 6.365a9 9 0 01-1.415-1.415L10.586 12 4.222 5.636a9 9 0 111.415-1.415L12 10.586l6.364-6.365z"
                        />
                    </svg>
                </div>
                <h1 className="text-2xl font-bold text-red-600 mb-4">
                    Ödeme Başarısız
                </h1>
                <p className="text-gray-700 mb-4">
                    İşleminiz tamamlanamadı. Lütfen ödeme bilgilerinizi kontrol
                    edin ve tekrar deneyin.
                </p>
            </div>
        </div>
    );
};

export default PaymentFailure;
