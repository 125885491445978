import React from "react";
import { IoIosLogOut } from "react-icons/io";
import { useDispatch } from "react-redux";
import { authenticationActions } from "../store/authentication.slice";
import { toast } from "react-toastify";
import { RiFullscreenLine } from "react-icons/ri";
const Header = () => {
    const dispatch = useDispatch();

    const logout = async () => {
        try {
            const response = await dispatch(
                authenticationActions.logout(),
            ).unwrap();

            if (response.success) {
                localStorage.clear();
                window.location.href = "/";
            }
        } catch (error) {
            toast.warning("Çıkış Başarısız.", {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
        }
    };

    const fullScreen = () => {
        if (document.fullscreenElement) {
            document.exitFullscreen().catch((err) => {
                console.error("Tam ekrandan çıkılamadı:", err);
            });
        } else {
            document.documentElement.requestFullscreen().catch((err) => {
                console.error("Tam ekran moduna geçilemedi:", err);
            });
        }
    };

    return (
        <header className="bg-white fixed w-full  pl-2  z-[1002] ">
            <div className="flex justify-between items-center mx-auto h-[70px] pr-[12px]">
                <span>
                    <img
                        src="/assets/images/menumate-logo.png"
                        alt="logo-light"
                        width={250}
                    />
                </span>

                <div className="gap-2 flex items-center ">
                    <button
                        type="button"
                        className="!text-mm-primary items-center p-2"
                        onClick={fullScreen}
                    >
                        <RiFullscreenLine size={20} />
                    </button>
                    <button
                        onClick={logout}
                        className="!text-mm-secondary hover:bg-gray-100 border border-solid !rounded-lg !border-mm-border flex items-center p-2"
                    >
                        <IoIosLogOut size={20} />
                        Çıkış Yap
                    </button>
                </div>
            </div>
        </header>
    );
};

export default Header;
