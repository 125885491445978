import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { baseUrl } from "../helpers/baseUrlHelpers";

const extraActions = createExtraActions();
const initialState = {
    loading: false,
    modalLoading: false,
    loadingChildren: {},
    categories: [],
    loadedCategoryIds: [],
    showCategoryModal: false,
    showProductModal: false,
    parentCategoryId: null,
    parentCategoryTitle: null,
    selectedCategory: null,
    selectedProduct: null,
    showPreferenceModal: false,
};

const addCategoryToState = (categoriesState, newCategory, parentId) => {
    const updateCategoriesRecursively = (categories) => {
        return categories.map((category) => {
            if (category.id === parentId) {
                if (
                    category.subCategories?.some(
                        (item) => item.id === newCategory.id,
                    )
                ) {
                    return {
                        ...category,
                        subCategories: category.subCategories.map((item) =>
                            item.id === newCategory.id ? newCategory : item,
                        ),
                    };
                } else {
                    return {
                        ...category,
                        subCategories: [...category.subCategories, newCategory],
                    };
                }
            } else if (
                category.subCategories &&
                category.subCategories.length > 0
            ) {
                return {
                    ...category,
                    subCategories: updateCategoriesRecursively(
                        category.subCategories,
                    ),
                };
            } else {
                return category;
            }
        });
    };

    const updatedCategories = updateCategoriesRecursively(
        Object.values(categoriesState),
    );

    const updatedState = updatedCategories.reduce((acc, category) => {
        acc[category.id] = category;
        return acc;
    }, {});

    return updatedState;
};

const removeCategoryToState = (categoriesState, categoryId) => {
    const updateCategoriesRecursively = (categories) => {
        return categories
            .map((category) => {
                if (category && category.id === categoryId) {
                    return null;
                } else if (
                    category &&
                    category.subCategories &&
                    category.subCategories.length > 0
                ) {
                    return {
                        ...category,
                        subCategories: updateCategoriesRecursively(
                            category.subCategories,
                        ),
                    };
                } else {
                    return category;
                }
            })
            .filter(Boolean);
    };

    const updatedCategories = updateCategoriesRecursively(
        Object.values(categoriesState),
    );

    const updatedState = updatedCategories.reduce((acc, category) => {
        if (category) {
            acc[category.id] = category;
        }
        return acc;
    }, {});

    return updatedState;
};

const addProductToState = (categoriesState, newProduct, categoryId) => {
    const updateCategoriesRecursively = (categories) => {
        return categories.map((category) => {
            if (category.id === categoryId) {
                if (
                    category.products?.some((item) => item.id === newProduct.id)
                ) {
                    return {
                        ...category,
                        products: category.products.map((item) =>
                            item.id === newProduct.id ? newProduct : item,
                        ),
                    };
                } else {
                    return {
                        ...category,
                        products: [...category?.products, newProduct],
                    };
                }
            } else if (
                category.subCategories &&
                category.subCategories.length > 0
            ) {
                return {
                    ...category,
                    subCategories: updateCategoriesRecursively(
                        category.subCategories,
                    ),
                };
            } else {
                return category;
            }
        });
    };

    const updatedCategories = updateCategoriesRecursively(
        Object.values(categoriesState),
    );

    return updatedCategories.reduce((acc, category) => {
        acc[category.id] = category;
        return acc;
    }, {});
};

const removeProductToState = (categoriesState, productId, categoryId) => {
    const updateProductsRecursively = (categories) => {
        return categories.map((category) => {
            if (category.id === categoryId) {
                return {
                    ...category,
                    products: category.products.filter(
                        (item) => item.id !== productId,
                    ),
                };
            } else if (
                category.subCategories &&
                category.subCategories.length > 0
            ) {
                return {
                    ...category,
                    subCategories: updateProductsRecursively(
                        category.subCategories,
                    ),
                };
            } else {
                return category;
            }
        });
    };

    const updatedCategories = updateProductsRecursively(
        Object.values(categoriesState),
    );

    return updatedCategories.reduce((acc, category) => {
        acc[category.id] = category;
        return acc;
    }, {});
};

export const slice = createSlice({
    name: "menu-service",
    initialState,
    reducers: {
        setShowPreferenceModal: (state, { payload }) => {
            state.showPreferenceModal = payload;
        },
        setShowCategoryModal: (state, { payload }) => {
            state.showCategoryModal = payload;
            if (!payload) {
                state.selectedCategory = null;
                state.parentCategoryId = null;
                state.parentCategoryTitle = null;
            }
        },
        setParentCategoryInfo: (state, { payload }) => {
            state.parentCategoryId = payload.id;
            state.parentCategoryTitle = payload.title;
        },
        setShowProductModal: (state, { payload }) => {
            state.showProductModal = payload;
            if (!payload) {
                state.selectedProduct = null;
            }
        },
        setCategories: (state, { payload }) => {
            payload.forEach((item) => {
                state.categories[item.id] = item;
            });
        },
        setProductInfo: (state, { payload }) => {
            const { productId, categoryId, favorite, status } = payload;

            const updateCategoriesRecursively = (categories) => {
                return categories.map((category) => {
                    if (category.id === categoryId) {
                        return {
                            ...category,
                            products: category?.products?.map((product) => {
                                if (product.id === productId) {
                                    return {
                                        ...product,
                                        favorite: favorite || product?.favorite,
                                        status: status || product?.status,
                                    };
                                } else return product;
                            }),
                        };
                    } else if (
                        category.subCategories &&
                        category.subCategories.length > 0
                    ) {
                        return {
                            ...category,
                            subCategories: updateCategoriesRecursively(
                                category.subCategories,
                            ),
                        };
                    } else {
                        return category;
                    }
                });
            };

            const updatedCategories = updateCategoriesRecursively(
                Object.values(state.categories),
            );

            state.categories = updatedCategories.reduce((acc, category) => {
                acc[category.id] = category;
                return acc;
            }, {});
        },
        setLoadingChildren: (state, { payload }) => {
            state.loadingChildren[payload] = true;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(extraActions.getMainCategories.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(
            extraActions.getMainCategories.fulfilled,
            (state, { payload }) => {
                state.loading = false;
                const categoriesObject = {};
                payload?.categories.forEach((category) => {
                    categoriesObject[category.id] = category;
                });
                state.categories = categoriesObject;
            },
        );
        builder.addCase(
            extraActions.getCategoryChildren.fulfilled,
            (state, { payload }) => {
                const { categoryId, subCategories, products } = payload;
                state.loadedCategoryIds = [
                    ...state.loadedCategoryIds,
                    categoryId,
                ];
                const updateCategoriesRecursively = (categories) => {
                    return categories.map((category) => {
                        if (category.id === categoryId) {
                            return {
                                ...category,
                                subCategories,
                                products,
                            };
                        } else if (
                            category.subCategories &&
                            category.subCategories.length > 0
                        ) {
                            return {
                                ...category,
                                subCategories: updateCategoriesRecursively(
                                    category.subCategories,
                                ),
                            };
                        } else {
                            return category;
                        }
                    });
                };

                const updatedCategories = updateCategoriesRecursively(
                    Object.values(state.categories),
                );

                state.categories = updatedCategories.reduce((acc, category) => {
                    acc[category.id] = category;
                    return acc;
                }, {});
                state.loadingChildren[categoryId] = false;
            },
        );
        builder.addCase(
            extraActions.saveProduct.fulfilled,
            (state, { payload }) => {
                const { newProduct, categoryId } = payload;
                state.categories = addProductToState(
                    state.categories,
                    newProduct,
                    categoryId,
                );
                state.loading = false;
            },
        );
        builder.addCase(
            extraActions.saveCategory.fulfilled,
            (state, { payload }) => {
                const { newCategory, parentId } = payload;
                if (parentId === 0) {
                    state.categories[newCategory.id] = newCategory;
                } else {
                    state.categories = addCategoryToState(
                        state.categories,
                        newCategory,
                        parentId,
                    );
                }

                state.loadedCategoryIds = state.loadedCategoryIds?.filter(
                    (id) => id !== newCategory?.id,
                );

                state.loading = false;
            },
        );
        builder.addCase(
            extraActions.getCategoryDetailsById.pending,
            (state) => {
                state.modalLoading = true;
            },
        );
        builder.addCase(
            extraActions.getCategoryDetailsById.fulfilled,
            (state, { payload }) => {
                state.selectedCategory = payload.categoryDetail;
                state.modalLoading = false;
            },
        );
        builder.addCase(extraActions.getProductDetailsById.pending, (state) => {
            state.modalLoading = true;
        });
        builder.addCase(
            extraActions.getProductDetailsById.fulfilled,
            (state, { payload }) => {
                state.selectedProduct = payload.productDetail;
                state.modalLoading = false;
            },
        );
        builder.addCase(
            extraActions.deleteCategory.fulfilled,
            (state, { payload }) => {
                state.categories = removeCategoryToState(
                    state.categories,
                    payload,
                );
            },
        );
        builder.addCase(
            extraActions.deleteProduct.fulfilled,
            (state, { payload }) => {
                const { categoryId, productId } = payload;
                state.categories = removeProductToState(
                    state.categories,
                    productId,
                    categoryId,
                );
            },
        );
    },
});

function createExtraActions() {
    return {
        getMainCategories: getMainCategories(),
        getCategoryChildren: getCategoryChildren(),
        reOrderMainCategories: reOrderMainCategories(),
        modifyProductFavorite: modifyProductFavorite(),
        modifyProductStatus: modifyProductStatus(),
        saveCategory: saveCategory(),
        saveProduct: saveProduct(),
        deleteCategory: deleteCategory(),
        deleteProduct: deleteProduct(),
        getCategoryDetailsById: getCategoryDetailsById(),
        getProductDetailsById: getProductDetailsById(),
        reOrderSubCategories: reOrderSubCategories(),
        reOrderSubCategoryProducts: reOrderSubCategoryProducts(),
    };

    function getMainCategories() {
        return createAsyncThunk("MenuService/getMainCategories", async () => {
            try {
                const res = await axios.get(`${baseUrl}/GetMainCategories`);
                return {
                    categories: res?.data?.mainCategories,
                };
            } catch (error) {
                console.error(error);
                throw error;
            }
        });
    }

    function getCategoryChildren() {
        return createAsyncThunk(
            "MenuService/getCategoryChildren",
            async (categoryId, thunkAPI) => {
                try {
                    thunkAPI.dispatch(
                        menuServiceActions.setLoadingChildren(categoryId),
                    );
                    const res = await axios.get(
                        `${baseUrl}/Category/${categoryId}/children`,
                    );

                    return {
                        categoryId,
                        subCategories: res?.data?.subCategories,
                        products: res?.data?.products,
                    };
                } catch (error) {
                    console.error(error);
                    throw error;
                }
            },
        );
    }

    function reOrderMainCategories() {
        return createAsyncThunk(
            "MenuService/reOrderMainCategories",
            async (data) => {
                try {
                    await axios.post(`${baseUrl}/ReOrderCategories`, data);
                } catch (error) {
                    console.error(error);
                    throw error;
                }
            },
        );
    }

    function modifyProductFavorite() {
        return createAsyncThunk("menu/modifyProductFavorite", async (data) => {
            try {
                await axios.post(`${baseUrl}/ModifyProductFavorite`, data);
            } catch (error) {
                console.error(error);
                throw error;
            }
        });
    }

    function modifyProductStatus() {
        return createAsyncThunk("menu/modifyProductStatus", async (data) => {
            try {
                await axios.post(`${baseUrl}/ModifyProductStatus`, data);
            } catch (error) {
                console.error(error);
                throw error;
            }
        });
    }

    function saveCategory() {
        return createAsyncThunk("menu/saveCategory", async (data, thunkAPI) => {
            const selectedCategory =
                thunkAPI.getState().menuService.selectedCategory;
            const apiEndpoint = selectedCategory
                ? "EditCategory"
                : "AddCategory";

            try {
                const res = await axios.post(`${baseUrl}/${apiEndpoint}`, data);

                return {
                    newCategory: res?.data?.category,
                    parentId: data?.parent_id,
                    message: res?.data?.message,
                };
            } catch (error) {
                console.error(error);
                throw error;
            }
        });
    }

    function saveProduct() {
        return createAsyncThunk("menu/saveProduct", async (data, thunkAPI) => {
            const selectedProduct =
                thunkAPI.getState().menuService.selectedProduct;
            const apiEndpoint = selectedProduct ? "EditProduct" : "AddProduct";

            try {
                const res = await axios.post(`${baseUrl}/${apiEndpoint}`, data);

                return {
                    newProduct: res?.data?.product,
                    categoryId: data?.category_id,
                    message: res?.data?.message,
                };
            } catch (error) {
                console.error(error);
                throw error;
            }
        });
    }

    function deleteCategory() {
        return createAsyncThunk("menu/deleteCategory", async (id) => {
            try {
                await axios.post(`${baseUrl}/DeleteCategory`, { id });

                return id;
            } catch (error) {
                console.error(error);
                throw error;
            }
        });
    }

    function deleteProduct() {
        return createAsyncThunk(
            "menu/deleteProduct",
            async ({ productId, categoryId }) => {
                try {
                    await axios.post(`${baseUrl}/DeleteProduct`, {
                        id: productId,
                    });

                    return {
                        productId,
                        categoryId,
                    };
                } catch (error) {
                    console.error(error);
                    throw error;
                }
            },
        );
    }

    function getCategoryDetailsById() {
        return createAsyncThunk("menu/getCategoryDetailsById", async (id) => {
            try {
                const res = await axios.post(
                    `${baseUrl}/GetCategoryDetailsById`,
                    { id },
                );

                return {
                    categoryDetail: res.data.data,
                };
            } catch (error) {
                console.error(error);
                throw error;
            }
        });
    }

    function getProductDetailsById() {
        return createAsyncThunk("menu/getProductDetailsById", async (id) => {
            try {
                const res = await axios.post(
                    `${baseUrl}/GetProductDetailsById`,
                    { id },
                );

                return {
                    productDetail: res.data.data,
                };
            } catch (error) {
                console.error(error);
                throw error;
            }
        });
    }

    function reOrderSubCategories() {
        return createAsyncThunk("menu/reOrderSubCategories", async (data) => {
            try {
                await axios.post(`${baseUrl}/ReOrderSubCategories`, data);
            } catch (error) {
                console.error(error);
                throw error;
            }
        });
    }

    function reOrderSubCategoryProducts() {
        return createAsyncThunk(
            "menu/reOrderSubCategoryProducts",
            async (data) => {
                try {
                    await axios.post(
                        `${baseUrl}/ReOrderSubCategoryProducts`,
                        data,
                    );
                } catch (error) {
                    console.error(error);
                    throw error;
                }
            },
        );
    }
}

export const menuServiceActions = { ...slice.actions, ...extraActions };

export const menuServiceReducer = slice.reducer;
