import React, { useEffect } from "react";
import TableComponent from "./TableComponent";
import AddTableModal from "./AddTableModal";
import { useDispatch, useSelector } from "react-redux";
import AddTableCategoryModal from "./AddTableCategoryModal";
import { tableManagementActions } from "../../store";

const TableManagementLayout = () => {
    const dispatch = useDispatch();
    const showModal = useSelector(
        (state) => state.tableManagement.showTableAddModal,
    );
    const showCategoryModal = useSelector(
        (state) => state.tableManagement.showTableCategoryAddModal,
    );
    const selectedAddCategoryId = useSelector(
        (state) => state.tableManagement.parentCategoryId,
    );
    const selectedTableId = useSelector(
        (state) => state.tableManagement.parentTableId,
    );
    const handleSaveCategoryModal = (e) => {
        selectedAddCategoryId
            ? dispatch(tableManagementActions.updateTableCategory(e))
            : dispatch(tableManagementActions.addTableCategory(e));

        dispatch(tableManagementActions.setShowTableCategoryAddModal(false));
        dispatch(tableManagementActions.setSelectedAddCategoryId(null));
    };
    const loadData = useSelector((state) => state.tableManagement.loadData);

    const handleSaveTable = (e) => {
        selectedTableId
            ? dispatch(tableManagementActions.updateTable(e))
            : dispatch(tableManagementActions.addTable(e));  
        dispatch(tableManagementActions.setShowTableAddModal(false));
        dispatch(
            tableManagementActions.setParentTableInfo({
                id: null,
                title: null,
                scopeId: null,
            }),
        );
    };
      useEffect(() => {
          dispatch(tableManagementActions.getTables());
          dispatch(tableManagementActions.getAllScope());
      }, []);
      useEffect(() => {
          if (loadData) {
              dispatch(tableManagementActions.getTables());
              dispatch(tableManagementActions.getAllScope());
          }
      }, [loadData]);
    return (
        <div>
            <div className="text-2xl mb-3 font-bold">
                Masa Yönetimi ve Ayarları
            </div>
            <TableComponent />
            {showModal && (
                <AddTableModal
                    modalTitle={
                        selectedAddCategoryId ? "Alan Düzenle" : "Yeni Masa Ekle"
                    }
                    handleSave={handleSaveTable}
                />
            )}
            {showCategoryModal && (
                <AddTableCategoryModal handleSave={handleSaveCategoryModal} />
            )}
        </div>
    );
};

export default TableManagementLayout;
