import React from "react";

const Badge = ({ text, ...props }) => {
    return (
        <span
            {...props}
            className="bg-gray-300 text-black font-semibold rounded-md p-0.5 text-xs"
        >
            {text.toUpperCase()}
        </span>
    );
};

export default Badge;
