import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { baseUrl } from "../helpers/baseUrlHelpers";

const extraActions = createExtraActions();
const initialState = {
    loading: false,
    feedback: null,
};

export const slice = createSlice({
    name: "feedback",
    initialState,
    reducers: {
        increment: (state) => {
            state.value += 1;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(extraActions.getFeedback.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(
            extraActions.getFeedback.fulfilled,
            (state, { payload }) => {
                state.loading = false;
                state.feedback = payload.feedback;
            },
        );
        builder.addCase(extraActions.getFeedback.rejected, (state) => {
            state.loading = false;
            state.loginData = null;
        });
    },
});

function createExtraActions() {
    return {
        getFeedback: getFeedback(),
    };

    function getFeedback() {
        return createAsyncThunk("feedback/getFeedback", async () => {
            try {
                const response = await axios.get(`${baseUrl}/GetFeedback`);
                const feedback = response.data;
                return {
                    feedback,
                };
            } catch (error) {
                console.error("Feedback getirilirken hata oluştu:", error);
                throw error;
            }
        });
    }
}

export const feedbackActions = { ...slice.actions, ...extraActions };

export const feedbackReducer = slice.reducer;
