import React, { useState } from "react";
import Input from "../../../components/input/Input";
import Card from "../../../components/card/Card";
import { useDispatch } from "react-redux";
import { authenticationActions } from "../../../store/authentication.slice";
import { toast } from "react-toastify";

const LoginPage = () => {
    const dispatch = useDispatch();

    const [formData, setFormData] = useState({
        email: "",
        password: "",
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const login = async () => {
        try {
            const response = await dispatch(
                authenticationActions.login(formData),
            ).unwrap();

            if (response.success) {
                localStorage.setItem("user", JSON.stringify(response.user));
                window.location.href = "/admin";
            }
        } catch (error) {
            toast.warning(
                "Giriş Başarısız. Lütfen Bilgilerinizi Kontrol Edin!",
                {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                },
            );
        }
    };

    return (
        <div className="flex items-center justify-center h-screen !bg-gradient-to-br  !from-black  !to-red-600">
            <Card className="w-full md:w-1/3 h-auto md:h-auto p-10">
                <img src="/assets/images/menumate-logo.png" alt="logo" />
                <div className="pt-4">
                    <h3>Email:</h3>
                    <Input
                        name="email"
                        placeholder="Email Giriniz"
                        value={formData.email}
                        onChange={handleChange}
                    />
                </div>
                <div className="pt-4 pb-10">
                    <h3>Parola:</h3>
                    <Input
                        name="password"
                        type="password"
                        placeholder="Parola Giriniz"
                        value={formData.password}
                        onChange={handleChange}
                    />
                </div>
                <button
                    onClick={login}
                    className="font-semibold !bg-gradient-to-br !from-black !to-red-600 !text-white !rounded-2xl h-10 flex items-center w-full justify-center"
                >
                    Giriş Yap
                </button>
            </Card>
        </div>
    );
};

export default LoginPage;
