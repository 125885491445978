import { GoPlus } from "react-icons/go";

import React from "react";

const AddedAccordion = ({
    addProduct,
    addCategory,
    showCategoryAddButton,
    showProductAddButton,
}) => {
    return (
        <button className="bg-white !rounded-[15px] flex mt-2 items-center p-2 h-full w-full">
            <div className="flex flex-row justify-between w-full">
                <div className="flex justify-between w-full p-1 items-center">
                    <div className="flex space-x-7">
                        {showCategoryAddButton && (
                            <div
                                className="flex space-x-2 cursor-pointer items-center !py-[6px]"
                                onClick={addCategory}
                            >
                                <GoPlus size={25} />
                                <span className="!font-semibold">
                                    Kategori Ekle
                                </span>
                            </div>
                        )}
                        {showProductAddButton && (
                            <div
                                className="flex space-x-2 cursor-pointer items-center !py-[6px]"
                                onClick={addProduct}
                            >
                                <GoPlus size={25} />
                                <span className="!font-semibold">
                                    Ürün Ekle
                                </span>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </button>
    );
};

export default AddedAccordion;
