import { configureStore } from "@reduxjs/toolkit";
import { settingsReducer } from "./settings.slice";
import { orderManagementReducer } from "./order-management.slice";
import { menuServiceReducer } from "./menu.service.slice";
import { tableManagementReducer } from "./table-management.slice";
import { tenantManagementReducer } from "./tenant-management.slice";
import { paymentManagementReducer } from "./payment-management.slice";
import { authenticationReducer } from "./authentication.slice";
import { feedbackReducer } from "./feedback.slice";
import { accountHistoryReducer } from "./account-history.slice";
import { statisticsReducer } from "./statistics.slice";
export * from "./actions";

export const store = configureStore({
    reducer: {
        settings: settingsReducer,
        orderManagement: orderManagementReducer,
        menuService: menuServiceReducer,
        tableManagement: tableManagementReducer,
        tenantManagement: tenantManagementReducer,
        paymentManagement: paymentManagementReducer,
        authentication: authenticationReducer,
        feedback: feedbackReducer,
        accountHistory: accountHistoryReducer,
        statistics: statisticsReducer,
    },
});
