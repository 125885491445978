import React from "react";

export const PositionElement = ({
  position = "center",
  className = "",
  children,
  ...props
}) => {
  return (
    <div
      {...props}
      className={`flex ${
        position === "center"
          ? "items-center justify-center"
          : position === "left"
          ? "items-stretch justify-start"
          : position === "right"
          ? "items-stretch justify-end"
          : position === "top"
          ? "flex-col items-stretch"
          : position === "bottom"
          ? "flex-col items-stretch justify-end"
          : ""
      } ${className}`}
    >
      {children}
    </div>
  );
};
