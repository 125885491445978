import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { orderManagementActions } from "../../store/order-management.slice";
import { OrderModal } from "./OrderModal";
import { TableModal } from "./TableModal";
import Echo from "laravel-echo";

const TableList = () => {
    const dispatch = useDispatch();
    const [isActive, setIsActive] = useState(false);
    const [isActiveOrder, setIsActiveOrder] = useState(false);
    const [selectedTable, setSelectedTable] = useState(null);
    const [tableTitle, setTableTitle] = useState(null);
    const [tableId, setTableId] = useState(null);
    const [selectedScope, setSelectedScope] = useState(0);
    const { tableScopes } = useSelector((state) => state.orderManagement);
    const tableValue = useSelector((state) => state.orderManagement.table);
    const isLoading = useSelector((state) => state.orderManagement.loading);

    const echo = new Echo({
        broadcaster: "pusher",
        key: "bcb735b129cc687bcea4",
        cluster: "eu",
        forceTLS: true,
        encrypted: true,
    });

    useEffect(() => {
        dispatch(orderManagementActions.getProducts());
        dispatch(orderManagementActions.getTableScopes());

        const channel = echo.channel("tableScopes");
        channel.listen("TableUpdated", (e) => {
            dispatch(orderManagementActions.addTableScopes(e));
        });

        return () => {
            channel.stopListening("TableUpdated");
        };
    }, []);

    useEffect(() => {
        setSelectedTable(tableValue);
    }, [tableValue]);

    const openModal = async (table) => {
        setTableTitle(table?.name);
        setTableId(table?.id);
        await dispatch(orderManagementActions.getTableDetailById(table.id));
        setIsActive(true);
    };

    const openOrderModal = async () => {
        setIsActiveOrder(true);
        getSubCategories();
    };

    const getSubCategories = (id) => {
        dispatch(orderManagementActions.getCategories(id));
    };

    return (
        <div className="w-full">
            <div className="flex space-x-4  mb-4">
                {tableScopes?.data?.map((scope, index) => (
                    <button
                        key={scope?.scope_id}
                        onClick={() => setSelectedScope(index)}
                        className={`flex-1 py-2 px-4 text-lg font-semibold ${
                            selectedScope === index
                                ? "!border-b-2 !border-orange-300 !text-orange-500 bg-white"
                                : "!border-b-2 border-gray-300 text-gray-600 hover:text-orange-500 bg-white"
                        }`}
                    >
                        {scope?.scope_name}
                    </button>
                ))}
            </div>
            {tableScopes?.data?.map(
                (scope, index) =>
                    selectedScope === index && (
                        <div key={scope?.scope_id} className="flex flex-wrap">
                            {scope?.tables?.map((table) => (
                                <div
                                    key={table?.id}
                                    className="w-1/5 p-1 text-center"
                                >
                                    <button
                                        onClick={() => openModal(table)}
                                        className={`flex mt-2 items-center !py-6 h-full w-full text-center justify-center ${
                                            table.song_count > 0 ||
                                            table.waiter_count > 0 ||
                                            table.order_count > 0
                                                ? "!bg-orange-500 hover:!bg-orange-600 !text-white shadow-md"
                                                : "bg-white hover:!bg-gray-300 shadow-md"
                                        } !rounded-[15px]`}
                                    >
                                        {table?.name}
                                    </button>
                                </div>
                            ))}
                        </div>
                    ),
            )}
            <TableModal
                tableId={tableId}
                isActive={isActive}
                setIsActive={setIsActive}
                isLoading={isLoading}
                table={selectedTable}
                setSelectedTable={setSelectedTable}
                title={tableTitle}
                openOrderModal={openOrderModal}
            />
            <OrderModal
                isActive={isActiveOrder}
                setIsActive={setIsActiveOrder}
                isLoading={isLoading}
                tableId={tableId}
                title={tableTitle}
                getSubCategories={getSubCategories}
            />
        </div>
    );
};

export default TableList;
