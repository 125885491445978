import React from "react";
import NotificationPanel from "./NotificationPanel";
import TableList from "./TableList";

const OrderManagementLayout = () => {
    return (
        <div className="flex gap-2 !max-h-[86vh]">
            <div className="!w-3/4">
                <TableList />
            </div>
            <div className="!w-1/4 !max-h-full">
                <NotificationPanel />
            </div>
        </div>
    );
};

export default OrderManagementLayout;
