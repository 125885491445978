import React from "react";
import { useSelector } from "react-redux";

const TenantList = () => {
    const tenants = useSelector((state) => state.tenantManagement.tenants);

    return (
        <div className="bg-white p-4 shadow-md rounded-xl">
            <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                    <tr>
                        <th className="px-4 py-2 text-left text-sm font-semibold text-gray-700">
                            Tenant Id
                        </th>
                        <th className="px-4 py-2 text-left text-sm font-semibold text-gray-700">
                            Şirket Adı
                        </th>
                        <th className="px-4 py-2 text-left text-sm font-semibold text-gray-700">
                            Sub Domaini
                        </th>
                        <th className="px-4 py-2 text-left text-sm font-semibold text-gray-700">
                            DB Adı
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {tenants?.map((tenant) => (
                        <tr
                            key={tenant.id}
                            className="border-b hover:bg-gray-100"
                        >
                            <td className="px-4 py-2 text-sm text-gray-900">
                                {tenant.id}
                            </td>
                            <td className="px-4 py-2">{tenant.company}</td>
                            <td className="px-4 py-2">{tenant.domain}</td>
                            <td className="px-4 py-2">
                                {tenant.tenancy_db_name}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default TenantList;
