import React from "react";
import { useSelector } from "react-redux";

const UserList = () => {
    const adminUsers = useSelector(
        (state) => state.tenantManagement.adminUsers,
    );
    return (
        <div className="bg-white p-4  rounded-lg shadow-md">
            <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                    <tr>
                        <th className="px-4 py-2 text-left text-sm font-semibold text-gray-700">
                            Kullanıcı Id
                        </th>
                        <th className="px-4 py-2 text-left text-sm font-semibold text-gray-700">
                            Şirket Adı
                        </th>
                        <th className="px-4 py-2 text-left text-sm font-semibold text-gray-700">
                            Kullanıcı Adı
                        </th>
                        <th className="px-4 py-2 text-left text-sm font-semibold text-gray-700">
                            Kullanıcı Maili
                        </th>
                    </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                    {adminUsers?.map((user) => (
                        <tr
                            key={user.id}
                            className="border-b hover:bg-gray-100"
                        >
                            <td className="px-4 py-2 text-sm text-gray-900">
                                {user.id}
                            </td>
                            <td className="px-4 py-2 text-sm text-gray-900">
                                {user.company}
                            </td>
                            <td className="px-4 py-2 text-sm text-gray-900">
                                {user.name}
                            </td>
                            <td className="px-4 py-2 text-sm text-gray-900">
                                {user.email}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default UserList;
