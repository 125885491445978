import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Reorder } from "framer-motion";
import { menuServiceActions } from "../../store/menu.service.slice";
import Loading from "../../components/loading/Loading";
import Category from "./Category";
import AddedAccordion from "./components/AddedAccordion";
import CategoryModal from "./CategoryModal";
import ProductModal from "./ProductModal";

const MenuLayout = () => {
    const dispatch = useDispatch();
    const {
        categories,
        loading,
        showCategoryModal,
        showProductModal,
        selectedCategory,
        selectedProduct,
    } = useSelector((state) => state.menuService);
    const [categoryOrder, setCategoryOrder] = useState([]);

    useEffect(() => {
        const sortedCategories = Object.values(categories).sort(
            (a, b) => a.order - b.order,
        );
        const sortedCategoryIds = sortedCategories.flatMap(
            (category) => category.id,
        );
        setCategoryOrder(sortedCategoryIds);
    }, [categories]);

    useEffect(() => {
        dispatch(menuServiceActions.getMainCategories());
    }, []);

    const handleCategoryOrder = (newOrder) => {
        setCategoryOrder(newOrder);
        const newCategories = newOrder.map((categoryId, index) => ({
            id: categoryId,
            order: index,
        }));
        dispatch(menuServiceActions.reOrderMainCategories(newCategories));
    };

    const handleSaveCategoryModal = (e) => {
        dispatch(menuServiceActions.saveCategory(e));
        dispatch(menuServiceActions.setShowCategoryModal(false));
    };

    const handleSaveProductModal = (e) => {
        dispatch(menuServiceActions.saveProduct(e));
        dispatch(menuServiceActions.setShowProductModal(false));
    };

    return (
        <div className="!p-5">
            {loading ? (
                <Loading height="80vh" />
            ) : (
                <div>
                    <div className="w-full ">
                        <AddedAccordion
                            addCategory={() =>
                                dispatch(
                                    menuServiceActions.setShowCategoryModal(
                                        true,
                                    ),
                                )
                            }
                            showCategoryAddButton
                        />
                    </div>
                    <Reorder.Group
                        axis="y"
                        values={categoryOrder}
                        onReorder={handleCategoryOrder}
                    >
                        {categoryOrder?.map((categoryId) => (
                            <Category
                                key={categoryId}
                                categoryId={categoryId}
                                category={categories[categoryId]}
                            />
                        ))}
                    </Reorder.Group>
                </div>
            )}

            {showCategoryModal && (
                <CategoryModal
                    handleSave={handleSaveCategoryModal}
                    modalTitle={
                        selectedCategory ? "Kategori Düzenle" : "Kategori Ekle"
                    }
                />
            )}

            {showProductModal && (
                <ProductModal
                    handleSave={handleSaveProductModal}
                    modalTitle={selectedProduct ? "Ürün Düzenle" : "Ürün Ekle"}
                />
            )}
        </div>
    );
};

export default MenuLayout;
