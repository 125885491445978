import React, { useRef, useEffect } from "react";
import { IoMdClose } from "react-icons/io";
import LoadingSmall from "../../components/loading/LoadingSmall";
import ModalOrders from "./components/ModalOrders";
import ModalAccounts from "./components/ModalAccounts";
import ModalWaiters from "./components/ModalWaiters";
import ModalSongs from "./components/ModalSongs";

export const TableModal = ({
    isActive,
    setIsActive,
    title,
    isLoading = false,
    table,
    openOrderModal,
    tableId,
    setSelectedTable,
}) => {
    const modalRef = useRef(null);
    const [selectedTab, setSelectedTab] = React.useState(0);

    const modalTabs = [
        {
            title: "Siparişler",
            color: "bg-red-500",
            component: (
                <ModalOrders setSelectedTable={setSelectedTable} data={table} />
            ),
        },
        {
            title: "Hesap İşlemleri",
            color: "bg-green-500",
            component: (
                <ModalAccounts
                    data={table?.accountRequests}
                    orders={table?.orders}
                    tableName={title}
                    tableId={tableId}
                    setIsActive={setIsActive}
                />
            ),
        },
        {
            title: "Garson Talepleri",
            color: "bg-blue-500",
            component: <ModalWaiters data={table?.waiters} />,
        },
        {
            title: "İstek Şarkı",
            color: "bg-purple-500",
            component: <ModalSongs data={table?.songs} />,
        },
    ];

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (modalRef.current && !modalRef.current.contains(event.target)) {
                setIsActive(false);
            }
        };

        if (isActive) {
            document.addEventListener("mousedown", handleClickOutside);
        } else {
            document.removeEventListener("mousedown", handleClickOutside);
        }

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [isActive, setIsActive]);

    return (
        <>
            <div
                ref={modalRef}
                className={`fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-[9999] ${
                    isActive ? "" : "hidden"
                } w-[80%] h-[80vh] overflow-hidden bg-white transition duration-300 ease-in-out rounded-lg`}
            >
                <div className="relative h-full p-2 ">
                    <div className="flex justify-between px-4 py-2 border-b border-gray-400 font-semibold text-lg items-center">
                        <span>{title}</span>
                        <span
                            onClick={() => setIsActive(false)}
                            className="cursor-pointer"
                        >
                            <IoMdClose size={20} color="gray" />
                        </span>
                    </div>

                    {/* Tab List */}
                    <div className="flex space-x-4 border-b border-gray-300 mb-4">
                        {modalTabs.map((tab, index) => (
                            <button
                                key={index}
                                onClick={() => setSelectedTab(index)}
                                className={`flex-1 py-2 px-4 text-lg font-semibold ${
                                    selectedTab === index
                                        ? `text-white ${tab.color} border-b-2`
                                        : "text-gray-600 hover:text-orange-500 border-b-2 border-gray-300"
                                }`}
                            >
                                {tab.title}
                            </button>
                        ))}
                    </div>

                    {/* Tab Content */}
                    <div className="h-[calc(100%-6rem)] overflow-y-auto">
                        {modalTabs[selectedTab]?.component}
                    </div>

                    {/* Footer */}
                    <div className="border-t bg-white border-gray-400 h-20 p-3 w-full absolute bottom-0 left-0 flex flex-row justify-end space-x-5">
                        <button
                            onClick={() => setIsActive(false)}
                            className="px-3 py-2 h-10 font-semibold bg-white text-gray-500 border border-gray-500 rounded-xl"
                        >
                            İptal
                        </button>
                        <button
                            onClick={openOrderModal}
                            className="px-3 py-2 h-10 font-semibold bg-teal-500 text-white rounded-xl flex space-x-1 items-center"
                        >
                            <span>Sipariş Ekle</span>
                            {isLoading && <LoadingSmall />}
                        </button>
                    </div>
                </div>
            </div>

            {/* Overlay */}
            {isActive && (
                <div className="fixed top-0 left-0 w-full h-full bg-black opacity-50 z-[9998]"></div>
            )}
        </>
    );
};
