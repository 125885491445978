import React from "react";
import { toast } from "react-toastify";
import ToastCard from "../components/toast-card/ToastCard";

export const toaster = {
    error: ({ title = "Bir hata oluştu", ...cardProps }, toastProps) =>
        toast.error(<ToastCard type="error" title={title} {...cardProps} />, {
            autoClose: true,
            ...toastProps,
        }),
    warning: ({ title = "Uyarı", ...cardProps }, toastProps) =>
        toast.warning(
            <ToastCard type="warning" title={title} {...cardProps} />,
            {
                autoClose: true,
                ...toastProps,
            },
        ),
    info: ({ title = "Bilgilendirme", ...cardProps }, toastProps) =>
        toast.info(<ToastCard type="info" title={title} {...cardProps} />, {
            autoClose: true,
            ...toastProps,
        }),
    success: ({ title = "Tebrikler", ...cardProps }, toastProps) =>
        toast.success(
            <ToastCard type="success" title={title} {...cardProps} />,
            {
                autoClose: true,
                ...toastProps,
            },
        ),
};
