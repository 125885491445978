import React, { useEffect, useState } from "react";
import { MdOutlineNotifications } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { orderManagementActions } from "../../store/order-management.slice";
import NotificationBox from "./components/NotificationBox";
import OrderBox from "./components/OrderBox";
import Echo from "laravel-echo";
import Card from "../../components/card/Card";
// eslint-disable-next-line no-unused-vars
import Pusher from "pusher-js";

const NotificationPanel = () => {
    const [songs, setSongs] = useState([]);
    const [waiters, setWaiters] = useState([]);
    const [orders, setOrders] = useState([]);
    const [accountRequests, setAccountRequests] = useState([]);
    const dispatch = useDispatch();
    const notifications = useSelector(
        (state) => state?.orderManagement?.notifications,
    );

    const echo = new Echo({
        broadcaster: "pusher",
        key: "bcb735b129cc687bcea4",
        cluster: "eu",
        forceTLS: true,
        encrypted: true,
    });
    useEffect(() => {
        dispatch(orderManagementActions?.getAllNotifications());
    }, []);
    useEffect(() => {
        const channel = echo.channel("orders");
        channel.listen("OrderUpdated", (e) => {
            dispatch(orderManagementActions.addNotification(e));
        });

        return () => {
            channel.stopListening("OrderUpdated");
        };
    }, [dispatch]);

    useEffect(() => {
        if (notifications) {
            setSongs(() => {
                const sortedSongs = [...(notifications?.data?.songs || [])];
                sortedSongs.sort(
                    (a, b) => new Date(b.created_at) - new Date(a.created_at),
                );
                return sortedSongs;
            });

            setWaiters(() => {
                const sortedWaiters = [...(notifications?.data?.waiters || [])];
                sortedWaiters.sort(
                    (a, b) => new Date(b.created_at) - new Date(a.created_at),
                );
                return sortedWaiters;
            });

            setAccountRequests(() => {
                const sortedAccountRequests = [
                    ...(notifications?.data?.account_requests || []),
                ];
                sortedAccountRequests.sort(
                    (a, b) => new Date(b.created_at) - new Date(a.created_at),
                );
                return sortedAccountRequests;
            });

            const ordersArray = Object.values(
                notifications?.data?.orders || {},
            );

            ordersArray.sort((a, b) => {
                const dateA = new Date(a.created_at);
                const dateB = new Date(b.created_at);
                return dateB - dateA;
            });
            setOrders(ordersArray);
        }
    }, [notifications]);

    const confirmAllNotifications = () => {
        dispatch(orderManagementActions?.confirmAllNotifications());
    };
    const hasNotifications =
        songs.length > 0 ||
        waiters.length > 0 ||
        orders.length > 0 ||
        accountRequests.length > 0;
    return (
        <Card className="h-full ">
            <div className="p-2 border flex border-orange-500 justify-center rounded-md gap-2 text-center items-center">
                <MdOutlineNotifications size={17} />
                Bildirim Paneli
            </div>
            <div className="h-full overflow-auto pb-3 text-center">
                {orders?.map((order, index) => (
                    <OrderBox key={index} notification={order} />
                ))}
                {songs?.map((song, index) => (
                    <NotificationBox
                        title={"Şarkı İsteği"}
                        key={index}
                        notification={song}
                        type={0}
                    />
                ))}
                {waiters?.map((waiter, index) => (
                    <NotificationBox
                        title={"Garson Talebi"}
                        key={index}
                        notification={waiter}
                        type={1}
                    />
                ))}
                {accountRequests?.map((request, index) => (
                    <NotificationBox
                        title={"Hesap Talebi"}
                        key={index}
                        notification={request}
                        type={2}
                    />
                ))}
                {hasNotifications && (
                    <button
                        onClick={confirmAllNotifications}
                        className="sticky w-[96%] bottom-4 mt-1 !bg-mm-secondary !text-white p-2 !rounded-md shadow-lg"
                    >
                        Tümünü Sil
                    </button>
                )}
            </div>
        </Card>
    );
};

export default NotificationPanel;
