import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { baseUrl } from "../helpers/baseUrlHelpers";

const extraActions = createExtraActions();
const initialState = {
    loading: false,
    payments: null,
};

export const slice = createSlice({
    name: "paymentManagement",
    initialState,
    reducers: {
        increment: (state) => {
            state.value += 1;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(extraActions.getPaymentInfos.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(
            extraActions.getPaymentInfos.fulfilled,
            (state, { payload }) => {
                state.loading = false;
                state.payments = payload;
            },
        );
    },
});

function createExtraActions() {
    return {
        getPaymentInfos: getPaymentInfos(),
    };

    function getPaymentInfos() {
        return createAsyncThunk(
            "paymentManagement/getPaymentInfos",
            async () => {
                try {
                    const response = await axios.get(
                        `${baseUrl}/GetPaymentInfos`,
                    );
                    return response.data;
                } catch (error) {
                    console.error("Payments getirilirken hata oluştu:", error);
                    throw error;
                }
            },
        );
    }
}

export const paymentManagementActions = { ...slice.actions, ...extraActions };

export const paymentManagementReducer = slice.reducer;
