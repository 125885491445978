import React from "react";
import { useSelector } from "react-redux";

const PaymentList = () => {
    const payments = useSelector((state) => state.paymentManagement.payments);

    return (
        <div className="bg-white p-4 border border-gray-300 rounded-lg shadow-md">
            <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                    <tr>
                        <th className="px-4 py-2 text-left text-sm font-semibold text-gray-700">
                            İş Yeri
                        </th>
                        <th className="px-4 py-2 text-left text-sm font-semibold text-gray-700">
                            Ödeme Durumu
                        </th>
                        <th className="px-4 py-2 text-left text-sm font-semibold text-gray-700">
                            Ödenen Miktar
                        </th>
                    </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                    {payments?.map((payment, index) => (
                        <tr
                            key={index}
                            className={`${
                                !payment.payment_status ? "bg-gray-200" : ""
                            }`}
                        >
                            <td className="px-4 py-2 text-sm text-gray-900">
                                {payment.tenant_id}
                            </td>
                            <td className="px-4 py-2 text-sm text-gray-900">
                                {payment.payment_status
                                    ? "Başarılı"
                                    : "Başarısız"}
                            </td>
                            <td className="px-4 py-2 text-sm text-gray-900">
                                {payment.payment_amount} ₺
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default PaymentList;
