import React from "react";
import { FaTrash } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { orderManagementActions } from "../../../store/order-management.slice";

const formatTime = (timeString) => {
    const date = new Date(timeString);
    const hour = date.getHours().toString().padStart(2, "0");
    const minute = date.getMinutes().toString().padStart(2, "0");
    return `${hour}:${minute}`;
};

const PreferencesPopover = ({ preferences, removedMaterials }) => (
    <div className="absolute z-10 bg-white border border-gray-300 shadow-lg rounded-lg p-4 w-96">
        <div className="font-bold mb-2">Tercihler</div>
        <div className="max-h-60 overflow-y-auto">
            {preferences.map((preference) => (
                <div
                    key={preference.id}
                    className="flex gap-2 border-b border-gray-200 mb-2 pb-2"
                >
                    <div className="text-md font-semibold">
                        {preference.title.TRTR}:
                    </div>
                    <div>
                        {preference.options.map((option) => (
                            <div
                                key={option.optionId}
                                className="flex items-center"
                            >
                                <span className="mr-2">
                                    {option.optionTitle.TRTR}
                                </span>
                            </div>
                        ))}
                    </div>
                </div>
            ))}
            {removedMaterials && (
                <div className="flex gap-2 border-b border-gray-200 mb-2 pb-2">
                    <div className="text-md font-semibold">
                        Çıkarılacak Malzeme:
                    </div>
                    <div>{removedMaterials}</div>
                </div>
            )}
        </div>
    </div>
);

const ModalOrders = ({ setSelectedTable }) => {
    const dispatch = useDispatch();
    const orders =
        useSelector((state) => state.orderManagement.table.orders) || [];

    const deleteOrderItem = (id) => {
        dispatch(orderManagementActions.deleteOrderItem(id));
        const updatedOrders = orders.filter((order) => order.id !== id);
        setSelectedTable({
            ...orders,
            orders: updatedOrders,
        });
    };

    const handleStatusChange = (id, selectedOption) => {
        const requestData = { id, status: selectedOption };
        dispatch(orderManagementActions.updateOrderItemStatus(requestData));
    };

    return (
        <div>
            <table className="min-w-full bg-white border border-gray-300">
                {orders.length > 0 ? null : (
                    <caption className="text-center py-2 text-gray-500">
                        Masanın siparişi bulunmamaktadır.
                    </caption>
                )}
                <thead>
                    <tr className="bg-red-200">
                        <th className="py-2 px-4 border border-gray-300">
                            Ürün Adı
                        </th>
                        <th className="py-2 px-4 border border-gray-300">
                            Ürün Adeti
                        </th>
                        <th className="py-2 px-4 border border-gray-300">
                            Ürün Fiyatı
                        </th>
                        <th className="py-2 px-4 border border-gray-300">
                            Not
                        </th>
                        <th className="py-2 px-4 border border-gray-300">
                            Tercihler
                        </th>
                        <th className="py-2 px-4 border border-gray-300">
                            Sipariş Durumu
                        </th>
                        <th className="py-2 px-4 border border-gray-300">
                            Saat
                        </th>
                        <th className="py-2 px-4 border border-gray-300">
                            Sil
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {orders.map((order) => (
                        <tr
                            key={order.orderItemId}
                            className="hover:bg-gray-100"
                        >
                            <td className="py-2 px-4 border border-gray-300">
                                {order?.title?.TRTR}
                            </td>
                            <td className="py-2 px-4 border border-gray-300">
                                {order?.quantity}
                            </td>
                            <td className="py-2 px-4 border border-gray-300">
                                {order?.price}
                            </td>
                            <td className="py-2 px-4 border border-gray-300">
                                {order?.note}
                            </td>
                            <td className="py-2 px-4 border border-gray-300 relative">
                                {order.preferences?.length > 0 ||
                                order?.removedMaterial ? (
                                    <>
                                        <button className="text-sm text-blue-500">
                                            Tercihleri Gör
                                        </button>
                                        <PreferencesPopover
                                            preferences={order.preferences}
                                            removedMaterials={
                                                order?.removedMaterial
                                            }
                                        />
                                    </>
                                ) : (
                                    <span>Tercih Yok</span>
                                )}
                            </td>
                            <td className="py-2 px-4 border border-gray-300">
                                <select
                                    className="border border-gray-300 rounded p-1 text-sm"
                                    defaultValue={order?.status}
                                    onChange={(e) =>
                                        handleStatusChange(
                                            order?.id,
                                            e.target.value,
                                        )
                                    }
                                >
                                    <option value="0">Sipariş Verildi</option>
                                    <option value="1">Sipariş Onaylandı</option>
                                    <option value="2">
                                        Sipariş Teslim Edildi
                                    </option>
                                </select>
                            </td>
                            <td className="py-2 px-4 border border-gray-300">
                                {formatTime(order?.created_at)}
                            </td>
                            <td className="py-2 px-4 border border-gray-300">
                                <button
                                    className="text-red-500"
                                    onClick={() =>
                                        deleteOrderItem(order.orderItemId)
                                    }
                                >
                                    <FaTrash />
                                </button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default ModalOrders;
