import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { accountHistoryActions } from "../../store/account-history.slice";
import Card from "../../components/card/Card";

const AccountHistoryLayout = () => {
    const dispatch = useDispatch();
    const accountHistory = useSelector(
        (state) => state.accountHistory.accountHistory,
    );

    useEffect(() => {
        dispatch(accountHistoryActions.getAccountHistory());
    }, [dispatch]);

    return (
        <div className="p-6">
            <div className="text-2xl mb-3 font-bold">Hesap Geçmişi</div>
            <Card>
                <table className="min-w-full">
                    <thead>
                        <tr>
                            <th className="px-4 py-2 border-b-2">ID</th>

                            <th className="px-4 py-2 border-b-2">Masa Adı</th>
                            <th className="px-4 py-2 border-b-2">Ürünler</th>

                            <th className="px-4 py-2 border-b-2">Fiyat</th>
                            <th className="px-4 py-2 border-b-2">Adet</th>
                        </tr>
                    </thead>
                    <tbody>
                        {accountHistory && accountHistory.length > 0 ? (
                            accountHistory.map((item) => (
                                <tr key={item.id} className="hover:bg-gray-50">
                                    <td className="px-4 py-2 border-b">
                                        {item.id}
                                    </td>
                                    <td className="px-4 py-2 border-b">
                                        {item.user_id}
                                    </td>
                                    <td className="px-4 py-2 border-b">
                                        {item.product_title}
                                    </td>
                                    <td className="px-4 py-2 border-b">
                                        {item.price}
                                    </td>
                                    <td className="px-4 py-2 border-b">
                                        {item.quantity}
                                    </td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td
                                    colSpan="5"
                                    className="px-4 py-2 text-center border-b"
                                >
                                    Hesap Geçmişi Bilgisi Bulunamadı
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </Card>
        </div>
    );
};

export default AccountHistoryLayout;
