import React, { useState } from "react";
import Input from "../../../components/input/Input";
import Card from "../../../components/card/Card";
import { useDispatch } from "react-redux";
import { authenticationActions } from "../../../store/authentication.slice";
import { toast } from "react-toastify";

const RegisterPage = () => {
    const dispatch = useDispatch();

    const [formData, setFormData] = useState({
        name: "",
        email: "",
        password: "",
        password_confirmation: "",
    });

    const [errors, setErrors] = useState({
        email: "",
        passwordMatch: "",
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
        if (name === "email") {
            const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            setErrors({
                ...errors,
                email: emailPattern.test(value) ? "" : "Geçersiz email adresi",
            });
        }
        if (name === "password_confirmation") {
            setErrors({
                ...errors,
                passwordMatch:
                    value === formData.password ? "" : "Şifreler eşleşmiyor",
            });
        }
    };

    const register = async () => {
        if (errors.email || errors.passwordMatch) {
            toast.warning("Lütfen formu düzgün doldurun", {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
            return;
        }

        try {
            const response = await dispatch(
                authenticationActions.register(formData),
            ).unwrap();

            if (response.success) {
                window.location.href = "/";
                toast.success("İşlem Başarılı!", {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
            }
        } catch (error) {
            toast.warning("İşlem Başarısız", {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
        }
    };

    return (
        <div className="flex items-center justify-center h-screen bg-gradient-to-tl from-red-600 from-0% to-black to-70%">
            <Card className=" w-full md:w-1/3 h-auto md:h-auto  p-10">
                <img src="/assets/images/menumate-logo.png" alt="logo" />

                {/* Name input */}
                <div className="pt-4">
                    <h3>İsim:</h3>
                    <Input
                        name="name"
                        placeholder="İsim Giriniz"
                        value={formData.name}
                        onChange={handleChange}
                    />
                </div>

                {/* Email input */}
                <div className="pt-4">
                    <h3>Email:</h3>
                    <Input
                        name="email"
                        placeholder="Email Giriniz"
                        value={formData.email}
                        onChange={handleChange}
                    />
                    {errors.email && (
                        <h5 className="text-red-500">{errors.email}</h5>
                    )}
                </div>

                {/* Password input */}
                <div className="pt-4">
                    <h3>Parola:</h3>
                    <Input
                        name="password"
                        type="password"
                        placeholder="Parola Giriniz"
                        value={formData.password}
                        onChange={handleChange}
                    />
                </div>

                {/* Confirm password input */}
                <div className="pt-4 pb-4">
                    <h3>Parola (Tekrar):</h3>
                    <Input
                        name="password_confirmation"
                        type="password"
                        placeholder="Parolayı Tekrar Giriniz"
                        value={formData.password_confirmation}
                        onChange={handleChange}
                    />
                    {errors.passwordMatch && (
                        <h5 className="text-red-500">{errors.passwordMatch}</h5>
                    )}
                </div>

                <button
                    onClick={register}
                    className="font-semibold !bg-gradient-to-br !from-black !to-red-600 !text-white !rounded-2xl h-10 flex items-center w-full justify-center"
                >
                    Hesap Oluştur
                </button>
            </Card>
        </div>
    );
};

export default RegisterPage;
