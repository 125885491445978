import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { tenantManagementActions } from "../../store";
import UserList from "./user-list/UserList";
import AddNewUser from "./add-new-user/AddNewUser";

const UserConfigLayout = () => {
    const dispatch = useDispatch();
    const loadUsersData = useSelector(
        (state) => state.tenantManagement.loadUsersData,
    );

    useEffect(() => {
        dispatch(tenantManagementActions.getUsers());
        dispatch(tenantManagementActions.getTenantInfos());
    }, []);

    useEffect(() => {
        loadUsersData && dispatch(tenantManagementActions.getUsers());
    }, [loadUsersData]);

    return (
        <div className="w-full flex flex-row space-x-6 pl-4">
            <div className="w-2/3">
                <div className="text-2xl mb-3 font-bold">Kullanıcı Listesi</div>
                <UserList />
            </div>
            <div className="w-1/3">
                <div className="font-semibold text-xl">Yeni Kullanıcı Ekle</div>
                <AddNewUser />
            </div>
        </div>
    );
};

export default UserConfigLayout;
