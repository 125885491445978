import React from "react";
import MenuLayout from "../pages/menu/MenuLayout";

const MenuView = () => {
    return (
        <div>
            <div className="flex justify-between pl-12">
                <div className="text-2xl mb-3 font-bold">Menü Yönetimi</div>
            </div>
            <MenuLayout />
        </div>
    );
};

export default MenuView;
