import React from "react";
import TabComponent from "./TabComponent";

const SettingsLayout = () => {
    return (
        <div>
            <div className="text-2xl mb-3 font-bold">
                Hesap ve Menü Ayarları
            </div>
            <TabComponent />
        </div>
    );
};

export default SettingsLayout;
