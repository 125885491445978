import React from "react";

const PaymentSuccess = () => {
    return (
        <div className="flex items-center justify-center min-h-screen bg-green-100">
            <div className="max-w-md w-full bg-white shadow-md rounded-lg p-6">
                <div className="flex items-center justify-center mb-4">
                    <svg
                        className="w-12 h-12 text-green-600"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M5 13l4 4L19 7"
                        />
                    </svg>
                </div>
                <h1 className="text-2xl font-bold text-green-600 mb-4">
                    Ödeme Başarılı
                </h1>
                <p className="text-gray-700 mb-4">
                    Ödemeniz başarıyla tamamlandı. Menumate iyi günler diler.
                </p>
            </div>
        </div>
    );
};

export default PaymentSuccess;
