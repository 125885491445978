import React, { useState } from "react";
import { GlobeIcon } from "../../icons";
import { CardModal } from "../../v2-components/modal/Modal";

const languages = [
    {
        key: "TRTR",
        text: {
            TRTR: "Türkçe",
            ENUS: "Turkish",
        },
    },
    {
        key: "ENUS",
        text: {
            TRTR: "İngilizce",
            ENUS: "English",
        },
    },
];

const LanguageSelector = ({ data, onChange, children }) => {
    const [showModal, setShowModal] = useState(false);
    const primaryLanguage = languages[0];

    return (
        <div className="space-y-4">
            <div key={primaryLanguage.key} className="space-y-2">
                {React.Children.map(children, (child) =>
                    React.cloneElement(child, {
                        value: data[primaryLanguage.key] || "",
                        onChange: (e) => onChange(primaryLanguage.key, e),
                        badgeText: primaryLanguage.text?.TRTR,
                        IconComponent: (
                            <GlobeIcon
                                onClick={() => setShowModal(true)}
                                width={12}
                                height={12}
                                className="cursor-pointer"
                            />
                        ),
                    }),
                )}
            </div>

            {showModal ? (
                <CardModal
                    onDismiss={() => setShowModal(false)}
                    className="!w-[500px]"
                >
                    <div className="space-y-6">
                        {" "}
                        {languages.map((lang) => (
                            <div key={lang.key} className="space-y-2">
                                {React.Children.map(children, (child) =>
                                    React.cloneElement(child, {
                                        value: data[lang.key] || "",
                                        onChange: (e) => onChange(lang.key, e),
                                        badgeText: lang.text?.TRTR,
                                    }),
                                )}
                            </div>
                        ))}
                    </div>
                </CardModal>
            ) : null}
        </div>
    );
};

export default LanguageSelector;
