import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { baseUrl } from "../helpers/baseUrlHelpers";

const extraActions = createExtraActions();
const initialState = {
    loading: false,
    tableManagement: null,
    tables: null,
    allScope: null,
    showTableAddModal: false,
    showTableCategoryAddModal: false,
    title: [],
    category: [],
    parentCategoryId: null,
    parentCategoryTitle: null,
    selectedCategory: null,
    findVisibleStatus: [],
    selectedId: [],
    parentTableId: null,
    parentTableTitle: null,
    parentTableIdScopeId: null,
    loadData: false,
};

export const slice = createSlice({
    name: "tableManagement",
    initialState,
    reducers: {
        increment: (state) => {
            state.value += 1;
        },
        setShowTableAddModal: (state, { payload }) => {
            state.showTableAddModal = payload;
            if (!payload) {
                state.selectedCategory = null;
            }
        },
        setShowTableCategoryAddModal: (state, { payload }) => {
            state.showTableCategoryAddModal = payload;
        },
        setParentTableInfo: (state, { payload }) => {
            state.parentTableId = payload.id;
            state.parentTableTitle = payload.title;
            state.parentTableIdScopeId = payload.scopeId;
        },

        setSelectedAddCategoryId: (state, { payload }) => {
            state.parentCategoryId = payload;
        },

        updateScopeVisible: (state, { payload }) => {
            state.allScope = state.allScope.map((scope) => {
                if (scope.id === payload.scopeId) {
                    return {
                        ...scope,
                        visible: payload.visible,
                    };
                }
                return scope;
            });
        },
        setFindVisibleStatus: (state, action) => {
            state.findVisibleStatus = action.payload.tablesField?.map(
                (field) => ({
                    title: field.title,
                    id: field.id,
                    visible: field.visible,
                }),
            );
            const idToAdd = action.payload.selectedId;
            const indexToRemove = state.selectedId.indexOf(idToAdd);

            if (
                !action.payload.selectedId ||
                (Array.isArray(action.payload.selectedId) &&
                    action.payload.selectedId.length === 0)
            ) {
                state.findVisibleStatus = action.payload.tablesField?.map(
                    (field) => ({
                        title: field.title,
                        id: field.id,
                        visible: field.visible,
                    }),
                );
            } else {
                if (indexToRemove !== -1) {
                    state.selectedId.splice(indexToRemove, 1);
                } else {
                    state.selectedId.push(idToAdd);
                }
                state.findVisibleStatus = action.payload.tablesField?.map(
                    (field) => {
                        const isVisible = state.selectedId.includes(field.id); // Seçilen ID'ler içinde ise visible true yap
                        return {
                            title: field.title,
                            id: field.id,
                            visible: isVisible,
                        };
                    },
                );
            }
        },
        setShowDeleteModal: (state, payload) => {
            state.showDeleteModal = payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(extraActions.getTables.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(
            extraActions.getTables.fulfilled,
            (state, { payload }) => {
                state.loading = false;
                state.tables = payload.tables;
                state.loadData = false;
            },
        );

        builder.addCase(extraActions.getAllScope.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(
            extraActions.getAllScope.fulfilled,
            (state, { payload }) => {
                state.loading = false;
                state.allScope = payload.allScope.map((scope) => {
                    return {
                        ...scope,
                        visible: true,
                    };
                });
                state.loadData = false;
            },
        );

        builder.addCase(extraActions.addTableCategory.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(
            extraActions.addTableCategory.fulfilled,
            (state, { payload }) => {
                state.loading = false;
                state.title = payload.title;
                state.loadData = true;
            },
        );
        builder.addCase(extraActions.addTable.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(
            extraActions.addTable.fulfilled,
            (state, { payload }) => {
                state.loading = false;
                state.category = payload.category;
                state.loadData = true;
            },
        );
        builder.addCase(extraActions.deleteTableCategory.fulfilled, (state) => {
            state.loading = false;
            state.loadData = true;
        });
        builder.addCase(extraActions.updateTableCategory.fulfilled, (state) => {
            state.loading = false;
            state.loadData = true;
        });
        builder.addCase(extraActions.deleteTable.fulfilled, (state) => {
            state.loading = false;
            state.loadData = true;
        });
        builder.addCase(extraActions.updateTable.fulfilled, (state) => {
            state.loading = false;
            state.loadData = true;
        });
    },
});

function createExtraActions() {
    return {
        getTables: getTables(),
        getAllScope: getAllScope(),
        addTableCategory: addTableCategory(),
        addTable: addTable(),
        deleteTableCategory: deleteTableCategory(),
        updateTableCategory: updateTableCategory(),
        deleteTable: deleteTable(),
        updateTable: updateTable(),
        downloadTableQrCode: downloadTableQrCode(),
        allDownloadTableQrCode: allDownloadTableQrCode(),
    };

    function getTables() {
        return createAsyncThunk("table/GetTables", async () => {
            const response = await axios.get(`${baseUrl}/GetTables`);
            const tables = response.data;
            return {
                tables,
            };
        });
    }

    function getAllScope() {
        return createAsyncThunk("table/allScope", async () => {
            const response = await axios.get(`${baseUrl}/allScope`);
            const allScope = response.data;
            return {
                allScope,
            };
        });
    }

    function addTableCategory() {
        return createAsyncThunk("table/addTableCategory", async (title) => {
            const response = await axios.post(`${baseUrl}/addTableCategory`, {
                title,
            });
            return response;
        });
    }
    function addTable() {
        return createAsyncThunk("table/tableSave", async (category) => {
            const response = await axios.post(`${baseUrl}/tableSave`, {
                category,
            });
            return response;
        });
    }
    function deleteTableCategory() {
        return createAsyncThunk("table/deleteTableCategory", async (id) => {
            const response = await axios.post(
                `${baseUrl}/deleteTableCategory`,
                { id },
            );
            return response;
        });
    }
    function updateTableCategory() {
        return createAsyncThunk("table/updateTableCategory", async (form) => {
            const response = await axios.post(
                `${baseUrl}/updateTableCategory`,
                {
                    form,
                },
            );

            return response;
        });
    }
    function deleteTable() {
        return createAsyncThunk("table/deleteTable", async (id) => {
            const response = await axios.post(`${baseUrl}/deleteTable`, {
                id,
            });
            return response;
        });
    }
    function updateTable() {
        return createAsyncThunk("table/updateTable", async (form) => {
            const response = await axios.post(`${baseUrl}/updateTable`, {
                form,
            });
            return response;
        });
    }
    function downloadTableQrCode() {
        return createAsyncThunk("table/downloadTableQrCode", async (form) => {
            const response = await axios.post(
                `${baseUrl}/downloadTableQrCode`,
                { form },
                { responseType: "blob" },
            );

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "qr-images.zip");
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
        });
    }
    function allDownloadTableQrCode() {
        return createAsyncThunk("table/allDownloadTableQrCode", async () => {
            const response = await axios.get(
                `${baseUrl}/allDownloadTableQrCode`,
                { responseType: "blob" }, // responseType parametresini doğru şekilde kullan
            );

            const blob = new Blob([response.data]); // response.data'yı blob'a dönüştür

            const url = window.URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "qr-images.zip");
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
        });
    }
}

export const tableManagementActions = { ...slice.actions, ...extraActions };

export const tableManagementReducer = slice.reducer;
