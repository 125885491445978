import React from "react";
import UserConfigLayout from "../pages/user-config/UserConfigLayout";

const UserConfig = () => {
    return (
        <div>
            <UserConfigLayout />
        </div>
    );
};

export default UserConfig;
