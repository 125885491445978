import { BaseButton } from "../../v2-components/button/Button";
import React from "react";
import { BsDownload, BsPlusLg } from "react-icons/bs";
import { useDispatch } from "react-redux";
import { tableManagementActions } from "../../store";

const TableProcess = () => {
    const dispatch = useDispatch();
    const allQrCodeDownland = () => {
        dispatch(tableManagementActions.allDownloadTableQrCode());
    };
    return (
        <div className="d-flex justify-end">
            <div className="gap-4 items-center">
                {/* <SearchBar /> */}
                <BaseButton
                    className="bg-white hover:!bg-purple-700 hover:text-white gap-2"
                    onClick={allQrCodeDownland}
                >
                    <BsDownload size={15} /> Karekodları İndir
                </BaseButton>
                <BaseButton
                    className="!bg-purple-800 text-white gap-2"
                    onClick={() => {
                        dispatch(
                            tableManagementActions.setShowTableAddModal(true),
                        );
                        dispatch(
                            tableManagementActions.setParentTableInfo({
                                id: null,
                                title: null,
                                scopeId: null,
                            }),
                        );
                    }}
                >
                    <BsPlusLg size={15} />
                    Yeni Masa Ekle
                </BaseButton>
            </div>
        </div>
    );
};

export default TableProcess;
