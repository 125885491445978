import React from "react";
import { Provider } from "react-redux";
import { store } from "../store";
import Header from "./Header";
import SideBar from "./SideBar";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import Settings from "./Settings";
import MenuView from "./MenuView";
import { TableManagement } from "./TableManagement";
import OrderManagement from "./OrderManagement";
import TenantManagement from "./TenantManagement";
import PaymentManagement from "./PaymentManagement";
import UserConfig from "./UserConfig";
import PaymentFailure from "../pages/payment/PaymentFailure";
import PaymentSuccess from "../pages/payment/PaymentSuccess";
import Login from "../pages/auth/login/LoginPage";
import FeedBack from "./FeedBack";
import AccountHistory from "./AccountHistory";
import Register from "../pages/auth/register/RegisterPage";
import Statistics from "./Statistics";
import ReactDOM from "react-dom/client";
import { baseDomain } from "../helpers/baseUrlHelpers";
const AppWrapper = () => {
    const location = useLocation();
    const noHeaderAndSidebarPaths = [
        "/admin/login",
        "/register",
        "/payment-failure",
        "/payment-success",
        "/payment",
    ];
    const routesTenant = [
        { path: "/admin/order-management", element: <OrderManagement /> },
        { path: "/admin/statistics", element: <Statistics /> },
        { path: "/admin/menu", element: <MenuView /> },
        { path: "/admin/history-accounts", element: <AccountHistory /> },
        { path: "/admin/feedback", element: <FeedBack /> },
        { path: "/admin/table-management", element: <TableManagement /> },
        { path: "/admin/settings", element: <Settings /> },
        { path: "/admin/login", element: <Login /> },
        { path: "/register", element: <Register /> },
    ];
    const routesAdmin = [
        { path: "/admin/tenant-management", element: <TenantManagement /> },
        { path: "/admin/user-config", element: <UserConfig /> },
        { path: "/admin/payment-management", element: <PaymentManagement /> },
        { path: "/admin/login", element: <Login /> },
        { path: "/register", element: <Register /> },
        { path: "/payment-failure", element: <PaymentFailure /> },
        { path: "/payment-success", element: <PaymentSuccess /> },
    ];
    const routes =
        baseDomain === process.env.APP_URL ? routesAdmin : routesTenant;
    const showHeaderAndSidebar = !noHeaderAndSidebarPaths.includes(
        location.pathname,
    );
    return (
        <>
            {showHeaderAndSidebar && (
                <>
                    <Header />
                    <SideBar />
                </>
            )}
            <ToastContainer />
            <div
                className={
                    showHeaderAndSidebar ? "pl-[250px] pt-[92px] pr-4" : ""
                }
            >
                <Routes>
                    {routes.map(({ path, element }) => (
                        <Route key={path} path={path} element={element} />
                    ))}
                </Routes>
            </div>
        </>
    );
};

const renderApp = (element) => {
    if (element) {
        const Index = ReactDOM.createRoot(element);
        Index.render(
            <BrowserRouter>
                <Provider store={store}>
                    <AppWrapper />
                </Provider>
            </BrowserRouter>,
        );
    }
};
const renderPages = [
    { id: "settings_page", component: <Settings /> },
    { id: "menu_view_page", component: <MenuView /> },
    { id: "table_management_page", component: <TableManagement /> },
    { id: "payment_failure_page", component: <PaymentFailure /> },
    { id: "payment_success_page", component: <PaymentSuccess /> },
    { id: "tenant_management_page", component: <TenantManagement /> },
    { id: "payment_management_page", component: <PaymentManagement /> },
    { id: "user_config_page", component: <UserConfig /> },
    { id: "order_management_page", component: <OrderManagement /> },
    { id: "admin_login_page", component: <Login /> },
    { id: "admin_register_page", component: <Register /> },
    { id: "admin_feedback_page", component: <FeedBack /> },
    { id: "admin_account_history_page", component: <AccountHistory /> },
    { id: "admin_statistics_page", component: <Statistics /> },
];
renderPages.forEach(({ id, component }) =>
    renderApp(document.getElementById(id), component),
);
