import React from "react";
import { Link, useLocation } from "react-router-dom";
import { GiForkKnifeSpoon, GiHotMeal } from "react-icons/gi";
import { GoGraph, GoHistory, GoCpu } from "react-icons/go";
import { IoMdNotificationsOutline, IoMdSettings } from "react-icons/io";
import {
    MdTableBar,
    MdOutlineManageAccounts,
    MdOutlinePayments,
} from "react-icons/md";
import { baseDomain } from "../helpers/baseUrlHelpers";

const Sidebar = () => {
    const location = useLocation();

    const routesTenant = [
        {
            path: "/admin/order-management",
            name: "Sipariş Yönetimi",
            icon: <GiForkKnifeSpoon size={15} />,
        },
        {
            path: "/admin/statistics",
            name: "İstatistikler",
            icon: <GoGraph size={15} />,
        },
        {
            path: "/admin/menu",
            name: "Menü Yönetimi",
            icon: <GiHotMeal size={15} />,
        },
        {
            path: "/admin/history-accounts",
            name: "Geçmiş Hesaplar",
            icon: <GoHistory size={15} />,
        },
        {
            path: "/admin/feedback",
            name: "Geri Bildirimler",
            icon: <IoMdNotificationsOutline size={15} />,
        },
        {
            path: "/admin/table-management",
            name: "Masa Yönetimi",
            icon: <MdTableBar size={15} />,
        },
        {
            path: "/admin/settings",
            name: "Ayarlar",
            icon: <IoMdSettings size={15} />,
        },
    ];

    const routesAdmin = [
        {
            path: "/admin/tenant-management",
            name: "Tenant Yönetimi",
            icon: <GoCpu size={15} />,
        },
        {
            path: "/admin/user-config",
            name: "Kullanıcı Ayarları",
            icon: <MdOutlineManageAccounts size={15} />,
        },
        {
            path: "/admin/payment-management",
            name: "Ödeme Yönetimi",
            icon: <MdOutlinePayments size={15} />,
        },
    ];
    const user = JSON.parse(localStorage.getItem("user"));
    const routes =
        baseDomain === process.env.APP_URL ? routesAdmin : routesTenant;

    return (
        <div className="w-[240px] box-border z-[1001] fixed top-[70px]  p-3 shadow-[1px_0_20px_rgba(0,0,0,0.05)]  h-[91%]  bg-white">
            <div className="h-100 text-center overflow-y-auto">
                <h5 className=" !mb-2 !text-mm-primary"> {user?.name}</h5>
                <ul className="!pl-0">
                    {routes.map((route, index) => (
                        <li key={index} className="h-10 mb-2">
                            <Link
                                to={route.path}
                                className={`!flex items-center gap-2 p-2 transition-colors hover:text-black ${
                                    location.pathname === route.path
                                        ? "!bg-gray-100 border-1 border-solid !border-mm-border rounded-md"
                                        : "hover:bg-gray-100"
                                }`}
                            >
                                <span className="text-mm-primary">
                                    {route.icon}
                                </span>
                                {route.name}
                            </Link>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
};

export default Sidebar;
