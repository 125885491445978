import React from "react";

const Input = ({
    type = "text",
    value,
    onChange,
    placeholder,
    id,
    name,
    className = "",
    label,
    multiline = false,
    ...props
}) => {
    const commonProps = {
        value,
        onChange,
        placeholder,
        id,
        name,
        className: `px-3 py-2 border border-gray-300 rounded-md outline-gray-500 transition duration-150 ease-in-out `,
        ...props,
    };

    return (
        <div className={`flex flex-col ${className}`}>
            {label && (
                <label htmlFor={id} className="text-gray-700 font-medium mb-1">
                    {label}
                </label>
            )}
            {multiline ? (
                <textarea
                    rows="4"
                    cols="50"
                    {...commonProps}
                    style={{ boxShadow: "none" }}
                    className={`resize-none ${commonProps.className} text-sm text-gray-500`}
                />
            ) : (
                <input
                    type={type}
                    {...commonProps}
                    style={{ boxShadow: "none" }}
                />
            )}
        </div>
    );
};

export default Input;
