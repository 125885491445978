import React from "react";
import PaymentManagementLayout from "../pages/payment-management/PaymentManagementLayout";

const PaymentManagement = () => {
    return (
        <div className="pl-4">
            <div className="flex justify-between ">
                <div className="text-2xl mb-3 font-bold">Ödeme Yönetimi</div>
            </div>
            <PaymentManagementLayout />
        </div>
    );
};

export default PaymentManagement;
