import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { baseUrl } from "../helpers/baseUrlHelpers";

const extraActions = createExtraActions();
const initialState = {
    loading: false,
    settings: null,
};

export const slice = createSlice({
    name: "settings",
    initialState,
    reducers: {
        increment: (state) => {
            state.value += 1;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(extraActions.getSettings.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(
            extraActions.getSettings.fulfilled,
            (state, { payload }) => {
                state.loading = false;
                state.settings = payload.settings;
            },
        );
    },
});

function createExtraActions() {
    return {
        getSettings: getSettings(),
        postSettings: postSettings(),
    };

    function getSettings() {
        return createAsyncThunk("settings/getSettings", async () => {
            try {
                const response = await axios.get(`${baseUrl}/GetSettings`);
                const settings = response.data;
                return {
                    settings,
                };
            } catch (error) {
                console.error("Settings getirilirken hata oluştu:", error);
                throw error;
            }
        });
    }

    function postSettings() {
        return createAsyncThunk("settings/postSettings", async (data) => {
            await axios.post(`${baseUrl}/PostSettings`, data);
        });
    }
}

export const settingsActions = { ...slice.actions, ...extraActions };

export const settingsReducer = slice.reducer;
