import React from "react";

const InputArea = ({ label, value, name, onChange }) => {
    return (
        <div>
            <label htmlFor={name} className="block text-sm font-semibold mb-1">
                {label}
            </label>
            <textarea
                id={name}
                name={name}
                value={value}
                onChange={onChange}
                rows="4"
                className="w-full border px-2 h-6 border-gray-300 rounded-md focus:border-teal-500 focus:outline-none resize-y"
            />
        </div>
    );
};

export default InputArea;
