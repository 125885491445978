import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { tableManagementActions } from "../../store";
import { Modal } from "../../components/modal/Modal";
import Input from "../../components/input/Input";

function AddTableCategoryModal({ handleSave }) {
    const dispatch = useDispatch();
    const tablesField = useSelector((state) => state.tableManagement.allScope);
    const showCategoryModal = useSelector(
        (state) => state.tableManagement.showTableCategoryAddModal,
    );
    const selectedAddCategoryId = useSelector(
        (state) => state.tableManagement.parentCategoryId,
    );
    const [form, setForm] = useState({
        name: "",
        selectedCategory: selectedAddCategoryId || "",
    });

    const onChange = (e) => {
        setForm({ ...form, [e.target.name]: e.target.value });
    };

    return (
        <Modal
            modalTitle={
                selectedAddCategoryId
                    ? "Masa Kategorisi Düzenle"
                    : "Masa Kategorisi Ekle"
            }
            setIsActive={(e) =>
                dispatch(tableManagementActions.setShowTableCategoryAddModal(e))
            }
            isActive={showCategoryModal}
            handleSave={() => handleSave(form)}
        >
            {selectedAddCategoryId ? (
                <div className="flex flex-col space-y-5 gap-3">
                    <div>
                        <div className="font-bold text-sm">Alan Seçiniz:</div>
                        <select
                            disabled
                            name="selectedCategory"
                            value={selectedAddCategoryId}
                        >
                            {tablesField.map((table) => {
                                const tableId = Number(table.id);
                                const selectedId = Number(
                                    selectedAddCategoryId,
                                );
                                if (tableId === selectedId) {
                                    return (
                                        <option key={tableId} value={tableId}>
                                            {table.title}
                                        </option>
                                    );
                                }
                                return null;
                            })}
                        </select>
                    </div>
                    <Input
                        label="Alan İsmi"
                        placeholder="Alan İsmi"
                        value={form.name}
                        name="name"
                        onChange={onChange}
                    />
                </div>
            ) : (
                <div className="flex flex-col space-y-5">
                    <Input
                        label="Alan İsmi"
                        placeholder="Alan İsmi"
                        value={form.name}
                        name="name"
                        onChange={onChange}
                    />
                </div>
            )}
        </Modal>
    );
}

export default AddTableCategoryModal;
