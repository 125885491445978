import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { baseUrl } from "../helpers/baseUrlHelpers";

const extraActions = createExtraActions();
const initialState = {
    loading: false,
    notifications: null,
    tableScopes: null,
    table: {
        waiters: [],
        songs: [],
        accountRequests: [],
        orders: [],
    },
    categories: null,
    products: null,
};

export const slice = createSlice({
    name: "orderManagement",
    initialState,
    reducers: {
        addNotification: (state, action) => {
            if (!state.notifications) {
                state.notifications = action.payload;
            } else {
                state.notifications = { data: action.payload };
            }
        },
        addTableScopes: (state, action) => {
            // Gelen bildirimi mevcut notification'lara ekle
            state.tableScopes = { data: action.payload };
        },
    },
    extraReducers: (builder) => {
        builder.addCase(extraActions.getAllNotifications.pending, () => {
            // state.loading = true;
        });
        builder.addCase(
            extraActions.getAllNotifications.fulfilled,
            (state, { payload }) => {
                state.loading = false;
                state.notifications = payload.notifications;
            },
        );
        builder.addCase(extraActions.getTableScopes.pending, () => {});

        builder.addCase(
            extraActions.getTableScopes.fulfilled,
            (state, { payload }) => {
                state.loading = false;
                state.tableScopes = payload.scopes;
            },
        );
        builder.addCase(extraActions.getTableDetailById.pending, (state) => {
            state.loading = true;
        });

        builder.addCase(
            extraActions.getTableDetailById.fulfilled,
            (state, { payload }) => {
                state.loading = false;
                const tableDetail = payload.tableDetail;
                state.table = {
                    waiters: tableDetail.waiters,
                    songs: tableDetail.songs,
                    accountRequests: tableDetail.accountRequests,
                    orders: tableDetail.orders?.flatMap(
                        (order) => order.orderItems,
                    ),
                };
            },
        );
        builder.addCase(extraActions.getCategories.pending, (state) => {
            state.loading = true;
        });

        builder.addCase(
            extraActions.getCategories.fulfilled,
            (state, { payload }) => {
                state.loading = false;
                state.categories = payload.categories;
            },
        );
        builder.addCase(extraActions.postOrders.pending, (state) => {
            state.loading = true;
        });

        builder.addCase(
            extraActions.getProducts.fulfilled,
            (state, { payload }) => {
                state.loading = false;
                state.products = payload.products;
            },
        );
        builder.addCase(extraActions.getProducts.pending, (state) => {
            state.loading = true;
        });

        builder.addCase(
            extraActions.postOrders.fulfilled,
            (state, { payload }) => {
                state.loading = false;
                state.orders = payload.orders;
            },
        );

        builder.addCase(extraActions.deleteOrderItem.pending, (state) => {
            state.loading = true;
        });

        builder.addCase(
            extraActions.deleteOrderItem.fulfilled,
            (state, { payload }) => {
                state.loading = false;
                state.orderItem = payload.orderItem;
            },
        );
        builder.addCase(extraActions.updateOrderItemStatus.pending, (state) => {
            state.loading = true;
        });

        builder.addCase(
            extraActions.updateOrderItemStatus.fulfilled,
            (state, { payload }) => {
                state.loading = false;
                state.orderItem = payload.orderItem;
            },
        );
        builder.addCase(extraActions.updateAccountStatus.pending, (state) => {
            state.loading = true;
        });

        builder.addCase(
            extraActions.updateAccountStatus.fulfilled,
            (state, { payload }) => {
                state.loading = false;
                state.waiter = payload.waiter;
            },
        );
        builder.addCase(extraActions.updateWaiterStatus.pending, (state) => {
            state.loading = true;
        });

        builder.addCase(
            extraActions.updateWaiterStatus.fulfilled,
            (state, { payload }) => {
                state.loading = false;
                state.waiter = payload.waiter;
            },
        );
        builder.addCase(extraActions.updateSongStatus.pending, (state) => {
            state.loading = true;
        });

        builder.addCase(
            extraActions.updateSongStatus.fulfilled,
            (state, { payload }) => {
                state.loading = false;
                state.song = payload.song;
            },
        );
        builder.addCase(
            extraActions.confirmAllNotifications.pending,
            (state) => {
                state.loading = true;
            },
        );

        builder.addCase(
            extraActions.confirmAllNotifications.fulfilled,
            (state) => {
                state.loading = false;
                state.notifications = null;
            },
        );
    },
});

function createExtraActions() {
    return {
        getProducts: getProducts(),
        getAllNotifications: getAllNotifications(),
        getTableScopes: getTableScopes(),
        getTableDetailById: getTableDetailById(),
        getCategories: getCategories(),
        postOrders: postOrders(),
        closeTableAccount: closeTableAccount(),
        deleteOrderItem: deleteOrderItem(),
        updateOrderItemStatus: updateOrderItemStatus(),
        updateAccountStatus: updateAccountStatus(),
        updateWaiterStatus: updateWaiterStatus(),
        updateSongStatus: updateSongStatus(),
        confirmNotification: confirmNotification(),
        confirmAllNotifications: confirmAllNotifications(),
    };

    function getAllNotifications() {
        return createAsyncThunk(
            "notifications/GetAllNotifications",
            async () => {
                try {
                    const notifications = await axios.post(
                        `${baseUrl}/GetAllNotifications`,
                    );

                    return {
                        notifications,
                    };
                } catch (error) {
                    console.error(
                        "Notifications getirilirken hata oluştu:",
                        error,
                    );
                    throw error;
                }
            },
        );
    }
    function getTableScopes() {
        return createAsyncThunk("scopes/GetTableScopes", async () => {
            try {
                const scopes = await axios.post(`${baseUrl}/GetTableScopes`);

                return {
                    scopes,
                };
            } catch (error) {
                console.error(
                    "Tablo kapsamları getirilirken hata oluştu:",
                    error,
                );
                throw error;
            }
        });
    }
    function getTableDetailById() {
        return createAsyncThunk("tables/getTableDetailById", async (id) => {
            const response = await axios.post(`${baseUrl}/GetTableDetailById`, {
                id,
            });
            return {
                tableDetail: response.data?.data,
            };
        });
    }
    function getCategories() {
        return createAsyncThunk("tables/GetCategories", async (data) => {
            const req = { id: data };
            try {
                const categories = await axios.post(
                    `${baseUrl}/GetCategories`,
                    req,
                );
                return {
                    categories: categories.data,
                };
            } catch (error) {
                console.error("Masa verileri getirilirken hata oluştu:", error);
                throw error;
            }
        });
    }
    function getProducts() {
        return createAsyncThunk("tables/GetProducts", async () => {
            try {
                const products = await axios.post(`${baseUrl}/GetProducts`);
                return {
                    products: products.data,
                };
            } catch (error) {
                console.error("Ürünler getirilirken hata oluştu:", error);
                throw error;
            }
        });
    }
    function closeTableAccount() {
        return createAsyncThunk("tables/CloseTableAccount", async (data) => {
            const req = { id: data };
            try {
                const account = await axios.post(
                    `${baseUrl}/CloseTableAccount`,
                    req,
                );
                return {
                    account: account.data,
                };
            } catch (error) {
                console.error("Masa verileri getirilirken hata oluştu:", error);
                throw error;
            }
        });
    }
    function postOrders() {
        return createAsyncThunk("tables/saveOrderForPanel", async (data) => {
            const req = { user_id: data[0], orders: data[1] };
            try {
                const categories = await axios.post(
                    `${baseUrl}/SaveOrderForPanel`,
                    req,
                );
                return {
                    categories,
                };
            } catch (error) {
                console.error("Masa verileri getirilirken hata oluştu:", error);
                throw error;
            }
        });
    }
    function updateOrderItemStatus() {
        return createAsyncThunk(
            "tables/UpdateOrderItemStatus",
            async (data) => {
                try {
                    const categories = await axios.post(
                        `${baseUrl}/UpdateOrderItemStatus`,
                        data,
                    );
                    return {
                        categories,
                    };
                } catch (error) {
                    console.error(
                        "Masa verileri getirilirken hata oluştu:",
                        error,
                    );
                    throw error;
                }
            },
        );
    }
    function updateAccountStatus() {
        return createAsyncThunk("tables/UpdateAccountStatus", async (data) => {
            try {
                const categories = await axios.post(
                    `${baseUrl}/UpdateAccountStatus`,
                    data,
                );
                return {
                    categories,
                };
            } catch (error) {
                console.error("Masa verileri getirilirken hata oluştu:", error);
                throw error;
            }
        });
    }
    function updateWaiterStatus() {
        return createAsyncThunk("tables/UpdateWaiterStatus", async (data) => {
            try {
                const categories = await axios.post(
                    `${baseUrl}/UpdateWaiterStatus`,
                    data,
                );
                return {
                    categories,
                };
            } catch (error) {
                console.error("Masa verileri getirilirken hata oluştu:", error);
                throw error;
            }
        });
    }
    function updateSongStatus() {
        return createAsyncThunk("tables/UpdateSongStatus", async (data) => {
            try {
                const categories = await axios.post(
                    `${baseUrl}/UpdateSongStatus`,
                    data,
                );
                return {
                    categories,
                };
            } catch (error) {
                console.error("Masa verileri getirilirken hata oluştu:", error);
                throw error;
            }
        });
    }
    function confirmNotification() {
        return createAsyncThunk("tables/confirmNotification", async (data) => {
            const response = await axios.post(
                `${baseUrl}/ConfirmNotification`,
                data,
            );
            return response;
        });
    }

    function confirmAllNotifications() {
        return createAsyncThunk("tables/confirmAllNotifications", async () => {
            const response = await axios.post(
                `${baseUrl}/ConfirmAllNotifications`,
            );
            return response;
        });
    }
    function deleteOrderItem() {
        return createAsyncThunk("tables/deleteOrderItemById", async (data) => {
            const req = { orderitem_id: data };
            try {
                const account = await axios.post(
                    `${baseUrl}/DeleteOrderItemById`,
                    req,
                );
                return {
                    account: account.data,
                };
            } catch (error) {
                console.error("Silme işlemi sırasında hata oluştu", error);
                throw error;
            }
        });
    }
}

export const orderManagementActions = { ...slice.actions, ...extraActions };

export const orderManagementReducer = slice.reducer;
