import React, { useEffect, useState } from "react";
import { BsFillTrashFill, BsPencil, BsPlusLg } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import DeleteModal from "./DeleteModal";
import { tableManagementActions } from "../../store";

const TableScreening = () => {
    const dispatch = useDispatch();
    const [selectedDeleteTableId, setSelectedDeleteTableId] = useState(null);
    const [selectedTableDeleteCategory, setSelectedTableDeleteCategory] =
        useState("");
    const tables = useSelector((state) => state.tableManagement.tables);
    const tablesField = useSelector((state) => state.tableManagement.allScope);
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        dispatch(tableManagementActions.setFindVisibleStatus({ tablesField }));
    }, [tablesField]);

    const isCheckedTableCategory = useSelector(
        (state) => state.tableManagement.findVisibleStatus,
    );
    const visibleTables = isCheckedTableCategory?.filter(
        (item) => item.visible,
    );

    const DeleteTableCategory = () => {
        selectedDeleteTableId
            ? dispatch(
                  tableManagementActions.deleteTable(selectedDeleteTableId),
              )
            : dispatch(
                  tableManagementActions.deleteTableCategory(
                      selectedTableDeleteCategory,
                  ),
              );
        setSelectedTableDeleteCategory(null);
        setSelectedDeleteTableId(null);
    };

    const handleOpen = () => setIsOpen(true);
    const handleClose = () => setIsOpen(false);

    return (
        <>
            {visibleTables?.map((table) => (
                <div
                    key={table?.id}
                    className="mt-2 bg-white shadow-md rounded-md p-4"
                >
                    <h2 className="flex items-center justify-between">
                        <div className="flex-1 text-left">
                            <input
                                type="checkbox"
                                className="mr-2"
                                id={`checkbox-${table?.id}`}
                            />
                            <label
                                htmlFor={`checkbox-${table?.id}`}
                                className="font-semibold"
                            >
                                {table?.title}
                            </label>
                        </div>
                        <div className="flex gap-2">
                            <button
                                className="text-purple-600 hover:bg-purple-100 p-2 rounded-md transition duration-150"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    dispatch(
                                        tableManagementActions.setShowTableCategoryAddModal(
                                            true,
                                        ),
                                    );
                                    dispatch(
                                        tableManagementActions.setSelectedAddCategoryId(
                                            table.id,
                                        ),
                                    );
                                }}
                            >
                                <BsPencil size={20} />
                            </button>
                            <button
                                className="text-red-600 hover:bg-red-100 p-2 rounded-md transition duration-150"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    handleOpen();
                                    setSelectedTableDeleteCategory(table.id);
                                }}
                            >
                                <BsFillTrashFill size={20} />
                            </button>
                        </div>
                    </h2>
                    <div className="mt-2">
                        {tables
                            ?.filter(
                                (tab) =>
                                    parseInt(tab?.scope_id) ===
                                    parseInt(table?.id),
                            )
                            ?.map((filteredTab) => (
                                <div
                                    key={filteredTab?.id}
                                    className="flex items-center justify-between bg-gray-100 rounded-md p-2 mt-2 hover:bg-gray-200"
                                >
                                    <input
                                        type="checkbox"
                                        className="mr-2"
                                        id={`checkbox-${filteredTab?.id}`}
                                    />
                                    <label
                                        htmlFor={`checkbox-${filteredTab?.id}`}
                                        className="flex-1"
                                    >
                                        {filteredTab?.name}
                                    </label>
                                    <div className="flex gap-2">
                                        <button
                                            className="text-purple-600 hover:bg-purple-100 p-2 rounded-md transition duration-150"
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                dispatch(
                                                    tableManagementActions.setShowTableAddModal(
                                                        true,
                                                    ),
                                                );
                                                dispatch(
                                                    tableManagementActions.setParentTableInfo(
                                                        {
                                                            id: filteredTab.id,
                                                            title: filteredTab.name,
                                                            scopeId:
                                                                filteredTab.scope_id,
                                                        },
                                                    ),
                                                );
                                            }}
                                        >
                                            <BsPencil />
                                        </button>
                                        <button
                                            className="text-red-600 hover:bg-red-100 p-2 rounded-md transition duration-150"
                                            onClick={() => {
                                                handleOpen();
                                                setSelectedDeleteTableId(
                                                    filteredTab.id,
                                                );
                                            }}
                                        >
                                            <BsFillTrashFill />
                                        </button>
                                    </div>
                                </div>
                            ))}
                        <div className="mt-4">
                            <button
                                className="bg-transparent text-black p-2 rounded-md border border-gray-300 hover:bg-gray-100 flex items-center"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    dispatch(
                                        tableManagementActions.setShowTableAddModal(
                                            true,
                                        ),
                                    );
                                    dispatch(
                                        tableManagementActions.setParentTableInfo(
                                            {
                                                id: null,
                                                title: null,
                                                scopeId: table.id,
                                            },
                                        ),
                                    );
                                }}
                            >
                                <BsPlusLg className="mr-2" /> Yeni Masa Ekle
                            </button>
                        </div>
                    </div>
                </div>
            ))}

            <DeleteModal
                text={
                    selectedDeleteTableId
                        ? "Bu masa kalıcı olarak silinecektir"
                        : "Bu masa kategorisi ile birlikte var ise alt masaları silinecektir"
                }
                title={
                    selectedDeleteTableId
                        ? "Masayı silmek istediğinize emin misiniz?"
                        : "Masa Kategorisini silmek istediğinize emin misiniz?"
                }
                onClose={handleClose}
                isOpen={isOpen}
                setSelectedDeleteTableId={setSelectedDeleteTableId}
                setSelectedTableDeleteCategory={setSelectedTableDeleteCategory}
                handleDelete={DeleteTableCategory}
            />
        </>
    );
};

export default TableScreening;
