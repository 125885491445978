import React from "react";

const Checkbox = ({ label, className = "", ...props }) => {
    return (
        <div className={`flex  ${className}`}>
            <input type="checkbox" {...props} />
            {label && (
                <label className="ml-2 text-gray-700 font-semibold">
                    {label}
                </label>
            )}
        </div>
    );
};

export default Checkbox;
