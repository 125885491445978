import React, { useState } from "react";

const Dropdown = ({
    options,
    selectedOption,
    onSelect,
    text,
    className = "",
}) => {
    const [isOpen, setIsOpen] = useState(false);

    const handleToggle = () => setIsOpen(!isOpen);
    const handleSelect = (option) => {
        onSelect(option);
        setIsOpen(false);
    };

    const selectedOptionItem = options.find(
        (item) => item.name === selectedOption,
    );

    const selectedOptionText = selectedOptionItem
        ? selectedOptionItem.text
        : "";

    return (
        <div className={`relative ${className}`}>
            <button
                onClick={handleToggle}
                className="flex items-center justify-between w-full px-4 py-2 text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-teal-500 focus:border-teal-500"
            >
                {selectedOptionText || text}
                <svg
                    className={`w-5 h-5 transition-transform duration-200 ${isOpen ? "transform rotate-180" : ""}`}
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                >
                    <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M19 9l-7 7-7-7"
                    />
                </svg>
            </button>
            {isOpen && (
                <div className="absolute z-10 w-full bg-white border border-gray-300 rounded-md shadow-lg">
                    <ul className="max-h-60 overflow-y-auto m-0 p-0">
                        {options.map((option) => (
                            <li
                                key={option.name}
                                onClick={() => handleSelect(option)}
                                className="cursor-pointer w-full px-4 py-2 hover:bg-teal-300 hover:text-teal-600"
                            >
                                {option.text}
                            </li>
                        ))}
                    </ul>
                </div>
            )}
        </div>
    );
};

export default Dropdown;
