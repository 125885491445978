import React from "react";

const ImageInput = ({ previewImage, dataImage, handleFileChange }) => {
    return (
        <div className="flex flex-row space-x-5">
            {previewImage && (
                <img
                    className="!max-w-24 !mr-5"
                    src={`data:image/jpeg;base64,${previewImage}`}
                    alt="image"
                />
            )}
            {!previewImage && dataImage && (
                <img
                    className="!max-w-24 !mr-5"
                    src={`${process.env.APP_URL}/storage/${dataImage}`}
                    alt="image"
                />
            )}
            <label
                htmlFor="image"
                className="bg-gray-100 !border border-gray-400 hover:bg-gray-300 text-border-gray-400 font-semibold py-2 px-4 rounded inline-flex items-center max-h-10 cursor-pointer"
            >
                Dosya Seç
            </label>
            <input
                id="image"
                name="image"
                type="file"
                accept="image/*"
                className="hidden"
                onChange={handleFileChange}
            />
        </div>
    );
};

export default ImageInput;
