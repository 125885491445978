import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { menuServiceActions } from "../../../store";
import { CardModal } from "../../../v2-components/modal/Modal";
import LanguageSelector from "../../../components/language-selector/LanguageSelector";
import Input from "../../../components/input/Input";
import { RedButton } from "../../../components/button/Button";
import Checkbox from "../../../components/checkbox/Checkbox";
import Dropdown from "../../../components/dropdown/Dropdown";

const generateId = () => `_${Math.random().toString(36).substr(2, 9)}`;

const defaultPreference = {
    id: generateId(),
    title: { TRTR: "", ENUS: "" },
    isRequired: true,
    optionType: "SINGLE",
    maxOptionSelect: null,
    options: [],
};

const PreferenceModal = ({
    title,
    preferences,
    editedPreference,
    setEditedPreference,
    onSave = () => {},
}) => {
    const dispatch = useDispatch();
    const showModal = useSelector(
        (state) => state.menuService.showPreferenceModal,
    );
    const [preference, setPreference] = useState(defaultPreference);

    const resetState = () => {
        setPreference({ ...defaultPreference, id: generateId() });
    };
    const setShowModal = (e) => {
        dispatch(menuServiceActions.setShowPreferenceModal(e));

        if (!e) {
            setEditedPreference(null);
        }
    };

    useEffect(() => {
        if (editedPreference) {
            setPreference(editedPreference);
        }
    }, [editedPreference]);

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;

        setPreference({
            ...preference,
            [name]: type === "checkbox" ? checked : value,
        });
    };

    const handleOptionLangChange = (index, lang, e) => {
        const updatedOptions = [...preference.options];
        updatedOptions[index] = {
            ...updatedOptions[index],
            title: { ...updatedOptions[index].title, [lang]: e.target.value },
        };
        setPreference({ ...preference, options: updatedOptions });
    };

    const handleOptionChange = (index, e) => {
        const { name, value } = e.target;
        const updatedOptions = [...preference.options];
        updatedOptions[index] = { ...updatedOptions[index], [name]: value };
        setPreference({ ...preference, options: updatedOptions });
    };

    const handleSavePreference = () => {
        if (editedPreference) {
            const updatedPreference = preferences?.map((item) => {
                if (item.id !== editedPreference.id) return item;
                return preference;
            });

            onSave(updatedPreference);
        } else {
            onSave([...preferences, preference]);
        }
        resetState();
        setShowModal(false);
    };

    const isDisabledAddPreferencesBtn =
        !(preference.title.TRTR || preference.title.ENUS) &&
        preference.options.length === 0;

    return (
        <>
            {showModal ? (
                <CardModal
                    className="!w-[500px]"
                    onConfirmBtnProps={{
                        disabled: isDisabledAddPreferencesBtn,
                        onClick: handleSavePreference,
                        children: "Tercihi Kaydet",
                    }}
                    onDismissBtnProps={{
                        onClick: () => {
                            setShowModal(false);
                            resetState();
                        },
                        children: "İptal",
                    }}
                    title={title}
                >
                    <div>
                        <h5 className="font-semibold mb-3">Tercih Başlığı</h5>
                        <div className="flex flex-col space-y-3">
                            <Checkbox
                                label="Zorunlu Alan"
                                name="isRequired"
                                checked={preference.isRequired}
                                onChange={handleChange}
                            />
                            <LanguageSelector
                                data={preference.title}
                                onChange={(lang, e) =>
                                    setPreference({
                                        ...preference,
                                        title: {
                                            ...preference.title,
                                            [lang]: e.target.value,
                                        },
                                    })
                                }
                            >
                                <Input label="İsim" />
                            </LanguageSelector>

                            <Dropdown
                                text="Seçim Tipi"
                                options={[
                                    { name: "SINGLE", text: "Tekli Seçim" },
                                    { name: "MULTIPLE", text: "Çoklu Seçim" },
                                ]}
                                selectedOption={preference.optionType}
                                onSelect={(option) =>
                                    setPreference({
                                        ...preference,
                                        optionType: option.name,
                                    })
                                }
                            />
                        </div>
                        {preference.optionType === "MULTIPLE" && (
                            <div className="mt-2">
                                <Input
                                    label="Maximum Seçim Sayısı"
                                    type="number"
                                    name="maxOptionSelect"
                                    value={preference.maxOptionSelect}
                                    onChange={handleChange}
                                />
                            </div>
                        )}

                        <div className="flex justify-end my-3">
                            <RedButton
                                onClick={() =>
                                    setPreference({
                                        ...preference,
                                        options: [
                                            {
                                                title: { TRTR: "", ENUS: "" },
                                                extraPrice: "",
                                                productId: null,
                                            },
                                            ...preference.options,
                                        ],
                                    })
                                }
                            >
                                Opsiyon Ekle
                            </RedButton>
                        </div>

                        {preference.options.map((option, index) => (
                            <div
                                className="flex flex-col gap-3 mt-4 border !border-teal-500 p-2 rounded-lg"
                                key={index}
                            >
                                <LanguageSelector
                                    data={option.title}
                                    onChange={(lang, value) =>
                                        handleOptionLangChange(
                                            index,
                                            lang,
                                            value,
                                        )
                                    }
                                >
                                    <Input label="Başlık" />
                                </LanguageSelector>

                                <Input
                                    label="Ekstra Fiyat"
                                    type="number"
                                    name="extraPrice"
                                    value={option.extraPrice}
                                    onChange={(e) =>
                                        handleOptionChange(index, e)
                                    }
                                />
                            </div>
                        ))}
                    </div>
                </CardModal>
            ) : null}
        </>
    );
};

export default PreferenceModal;
