import React from "react";

const ToastCard = ({ type, title, description, onConfirm }) => {
    return (
        <div className={`toast-card ${type}`}>
            <div className="toast-header">
                <strong className="mr-auto">{title}</strong>
            </div>
            {description && <div className="toast-body">{description}</div>}
            {onConfirm && (
                <div className="toast-footer">
                    <button onClick={onConfirm}>Tamam</button>
                </div>
            )}
        </div>
    );
};

export default ToastCard;
