import React, { useState, useEffect } from "react";

const Categories = ({ categories, getSubCategories }) => {
    const [currentCategory, setCurrentCategory] = useState(null);
    const [mainCategories, setMainCategories] = useState([]); // Ana kategoriler state'i

    useEffect(() => {
        if (categories?.length > 0 && mainCategories.length === 0) {
            setMainCategories(categories); // Ana kategorileri ilk kez al
        }
    }, [categories]); // categories değiştiğinde kontrol edilir

    const handleBackClick = () => {
        getSubCategories(0);
        setCurrentCategory(null);
    };

    const handleCategoryClick = (categoryId) => {
        getSubCategories(categoryId);
        setCurrentCategory(categoryId);
    };

    // Kategoriler boşsa, mainCategories döndürülür
    const displayedCategories =
        categories?.length > 0 ? categories : mainCategories;

    return (
        <div className="flex flex-wrap">
            {/* Geri Butonu */}
            {currentCategory !== null && (
                <div className="p-1 w-full">
                    <button
                        onClick={handleBackClick}
                        className="mt-2 items-center p-2 h-full w-20 text-center justify-center bg-gray-100 shadow-md rounded-[15px]"
                    >
                        Geri
                    </button>
                </div>
            )}

            {/* Kategoriler */}
            {displayedCategories?.map((category) => (
                <div key={category?.id} className="p-1 w-1/3">
                    <button
                        onClick={() => handleCategoryClick(category?.id)}
                        className="mt-2 items-center p-4 h-full w-full text-center justify-center bg-gray-100 shadow-md rounded-[15px]"
                    >
                        {category?.title?.TRTR}
                    </button>
                </div>
            ))}
        </div>
    );
};

export default Categories;
