import React from "react";
import { BallTriangle } from "react-loader-spinner";

const Loading = ({ height }) => {
    return (
        <div
            style={{ height: height || "100vh" }}
            className="flex justify-center items-center"
        >
            <BallTriangle
                height={100}
                width={100}
                radius={5}
                color="#319795"
                ariaLabel="ball-triangle-loading"
                wrapperClass={{}}
                wrapperStyle=""
                visible={true}
            />
        </div>
    );
};

export default Loading;
