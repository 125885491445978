import React, { useEffect, useState } from "react";

const OverviewTab = ({ options, onChange }) => {
    const [overviewOptions, setOverviewOptions] = useState(options);

    const formatPhoneNumber = (phoneNumber) => {
        const cleaned = `${phoneNumber}`.replace(/\D/g, "");
        const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
        if (match) {
            return `(${match[1]}) ${match[2]} ${match[3]}`;
        }
        return null;
    };

    useEffect(() => {
        setOverviewOptions(options);
    }, [options]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        const updatedOptions = {
            ...overviewOptions,
            [name]: value,
        };
        setOverviewOptions(updatedOptions);
        onChange && onChange(updatedOptions);
    };

    const handleSwitchChange = (e) => {
        const { name, checked } = e.target;
        const updatedOptions = {
            ...overviewOptions,
            [name]: checked,
        };
        setOverviewOptions(updatedOptions);
        onChange && onChange(updatedOptions);
    };

    const handleFileChange = (e) => {
        const { name, files } = e.target;
        const file = files[0];

        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = () => {
            const base64String = reader.result.split(",")[1];
            const updatedOptions = {
                ...overviewOptions,
                [name]: base64String,
            };
            setOverviewOptions(updatedOptions);
            onChange && onChange(updatedOptions);
        };
    };

    return (
        <div className="grid grid-cols-2 gap-4">
            <div className="flex flex-col gap-2 mt-3">
                <label htmlFor="restaurantName">Mekan Adı:</label>
                <input
                    name="restaurantName"
                    value={overviewOptions.restaurantName}
                    onChange={handleInputChange}
                    className="border border-gray-300 p-2 rounded"
                />
            </div>

            <div className="flex flex-col gap-2 mt-3">
                <label htmlFor="orderCancelTime">Sipariş İptal Süresi:</label>
                <input
                    type="number"
                    name="orderCancelTime"
                    value={overviewOptions.orderCancelTime}
                    onChange={handleInputChange}
                    className="border border-gray-300 p-2 rounded"
                />
            </div>

            <div className="flex flex-col gap-2 mt-3">
                <label htmlFor="instagramUrl">Instagram Linki:</label>
                <input
                    name="instagramUrl"
                    value={overviewOptions.instagramUrl}
                    onChange={handleInputChange}
                    className="border border-gray-300 p-2 rounded"
                />
            </div>

            <div className="flex flex-col gap-2 mt-3">
                <label htmlFor="whatsappPhone">Whatsapp Numarası:</label>
                <input
                    type="tel"
                    placeholder="(123) 456 7890"
                    value={formatPhoneNumber(overviewOptions.whatsappPhone)}
                    onChange={handleInputChange}
                    name="whatsappPhone"
                    className="border border-gray-300 p-2 rounded"
                />
            </div>

            <div className="flex flex-col gap-2 mt-3">
                <label htmlFor="mapUrl">Google Maps Linki:</label>
                <input
                    name="mapUrl"
                    value={overviewOptions.mapUrl}
                    onChange={handleInputChange}
                    className="border border-gray-300 p-2 rounded"
                />
            </div>

            <div className="flex flex-row space-x-3 items-center mt-5">
                <input
                    type="checkbox"
                    className="form-checkbox h-5 w-5 text-green-600"
                    name="orderRequestStatus"
                    checked={overviewOptions?.orderRequestStatus}
                    onChange={handleSwitchChange}
                />
                <label
                    className={`${
                        overviewOptions?.orderRequestStatus
                            ? "text-green-600"
                            : "text-gray-300"
                    } font-bold`}
                >
                    Sipariş Verme
                </label>
            </div>

            <div className="flex flex-col gap-2 mt-3">
                <label htmlFor="logo">Logo:</label>
                <div className="flex flex-row space-x-5">
                    {overviewOptions?.logo && (
                        <img
                            className="max-w-24 mr-5"
                            src={`data:image/jpeg;base64,${overviewOptions?.logo}`}
                            alt="logo"
                        />
                    )}
                    <label
                        htmlFor="logo_select"
                        className="bg-green-500 hover:bg-green-700 text-white font-semibold py-2 px-4 rounded inline-flex items-center max-h-10 cursor-pointer"
                    >
                        Dosya Seç
                    </label>
                    <input
                        id="logo_select"
                        name="logo"
                        type="file"
                        accept="image/*"
                        className="hidden"
                        onChange={handleFileChange}
                    />
                </div>
            </div>

            <div className="flex flex-col space-x-3 gap-2 mt-3">
                <label htmlFor="qrBackgroundImage">QR Arkaplan Tasarımı:</label>
                <div className="flex flex-row space-x-5">
                    {overviewOptions?.qrBackgroundImage && (
                        <img
                            className="max-w-24 mr-5"
                            src={`data:image/jpeg;base64,${overviewOptions?.qrBackgroundImage}`}
                            alt="QR Background"
                        />
                    )}
                    <label
                        htmlFor="qrBackgroundImage"
                        className="bg-green-500 hover:bg-green-700 text-white font-semibold py-2 px-4 rounded inline-flex items-center max-h-10 cursor-pointer"
                    >
                        Dosya Seç
                    </label>
                    <input
                        id="qrBackgroundImage"
                        name="qrBackgroundImage"
                        type="file"
                        accept="image/*"
                        className="hidden"
                        onChange={handleFileChange}
                    />
                </div>
            </div>
        </div>
    );
};

export default OverviewTab;
