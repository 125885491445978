import React from "react";
import DefaultImage from "../../assets/images/default-product-image.jpg";

const ImageContainer = ({ image }) => {
    return (
        <img
            src={
                image ? `${process.env.APP_URL}/storage/${image}` : DefaultImage
            }
            className="!w-10 !h-10 rounded-md"
        />
    );
};

export default ImageContainer;
