import React from "react";
import TenantManagementLayout from "../pages/tenant-management/TenantManagementLayout";

const TenantManagement = () => {
    return (
        <div className="pl-4">
            <TenantManagementLayout />
        </div>
    );
};

export default TenantManagement;
