import React from "react";

const BaseButton = ({
    disabled = false,
    onClick,
    className = "",
    children,
    shape = "rounded",
    ...props
}) => {
    return (
        <button
            disabled={disabled}
            onClick={onClick}
            className={`px-3 py-2 font-semibold ${shape === "rounded" ? "rounded-xl" : "rounded-sm"} flex space-x-1 items-center ${disabled ? "opacity-30 cursor-default" : ""} ${className}`}
            {...props}
        >
            {children}
        </button>
    );
};

const TealButton = ({
    onClick,
    disabled,
    children,
    className = "",
    ...props
}) => (
    <button
        disabled={disabled}
        onClick={onClick}
        className={`px-3 py-2 h-10 font-semibold justify-center !bg-teal-500 text-white !rounded-xl flex space-x-1 items-center ${disabled ? "opacity-30 cursor-default" : ""} ${className}`}
        {...props}
    >
        {children}
    </button>
);

const RedButton = ({
    onClick,
    disabled,
    children,
    className = "",
    ...props
}) => (
    <button
        disabled={disabled}
        onClick={onClick}
        className={`px-3 py-2 h-10 font-semibold justify-center !bg-red-500 text-white !rounded-xl flex space-x-1 items-center ${disabled ? "opacity-30 cursor-default" : ""} ${className}`}
        {...props}
    >
        {children}
    </button>
);

const NeutralButton = (props) => (
    <BaseButton className="bg-gray-300 text-black" {...props} />
);

export { TealButton, RedButton, NeutralButton };
