import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const extraActions = createExtraActions();
const initialState = {
    loading: false,
    loginData: null,
    registerData: null,
};

export const slice = createSlice({
    name: "authentication",
    initialState,
    reducers: {
        increment: (state) => {
            state.value += 1;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(extraActions.login.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(extraActions.login.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.loginData = payload.user;
        });
        builder.addCase(extraActions.login.rejected, (state) => {
            state.loading = false;
            state.loginData = null;
        });
        builder.addCase(extraActions.register.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(
            extraActions.register.fulfilled,
            (state, { payload }) => {
                state.loading = false;
                state.registerData = payload.user;
            },
        );
        builder.addCase(extraActions.logout.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(extraActions.logout.fulfilled, (state) => {
            state.loading = false;
            state.loginData = null;
        });
    },
});

function createExtraActions() {
    return {
        login: login(),
        register: register(),
        logout: logout(),
    };

    function login() {
        return createAsyncThunk("authentication/PostLogin", async (data) => {
            const response = await axios.post(
                `${window.location.origin}/admin/PostLogin`,
                data,
            );

            return response.data;
        });
    }
    function register() {
        return createAsyncThunk("authentication/Register", async (data) => {
            const response = await axios.post(
                `${window.location.origin}/register`,
                data,
            );

            return response.data;
        });
    }
    function logout() {
        return createAsyncThunk("authentication/Logout", async () => {
            const response = await axios.get(
                `${window.location.origin}/logout`,
            );
            return response.data;
        });
    }
}

export const authenticationActions = { ...slice.actions, ...extraActions };

export const authenticationReducer = slice.reducer;
