import React from "react";
import PropTypes from "prop-types";
import { TfiClose } from "react-icons/tfi";
import { orderManagementActions } from "../../../store/order-management.slice";
import { useDispatch } from "react-redux";

const NotificationBox = ({ notification, type, title }) => {
    const createdAt = new Date(notification?.created_at);
    const hour = String(createdAt.getHours()).padStart(2, "0");
    const minute = String(createdAt.getMinutes()).padStart(2, "0");
    const dispatch = useDispatch();
    const formattedTime = `${hour}:${minute}`;

    const handleCloseNotification = () => {
        const requestData = { id: notification?.id, type };
        dispatch(orderManagementActions?.confirmNotification(requestData));
    };

    return (
        <div className="w-full !bg-yellow-300 rounded-md shadow-sm mt-1 p-1">
            <div className="flex justify-between ">
                <p>
                    {title} ({formattedTime})
                </p>
                <button
                    className="text-black bg-transparent border-0 p-0 cursor-pointer"
                    onClick={handleCloseNotification}
                >
                    <TfiClose />
                </button>
            </div>
            {notification?.name} {notification?.user_name}
        </div>
    );
};

NotificationBox.propTypes = {
    notification: PropTypes.object.isRequired,
    type: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
};

export default NotificationBox;
