import React from "react";

export const Backdrop = ({ className = "", ...props }) => {
  return (
    <div
      {...props}
      className={`fixed top-0 left-0 w-screen h-screen bg-[rgba(0,0,0,0.6)] z-50 ${className}`}
    />
  );
};
