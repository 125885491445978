import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { baseUrl } from "../helpers/baseUrlHelpers";

const extraActions = createExtraActions();
const initialState = {
    loading: false,
    accountHistory: null,
};

export const slice = createSlice({
    name: "accountHistory",
    initialState,
    reducers: {
        increment: (state) => {
            state.value += 1;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(extraActions.getAccountHistory.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(
            extraActions.getAccountHistory.fulfilled,
            (state, { payload }) => {
                state.loading = false;
                state.accountHistory = payload.accountHistory;
            },
        );
        builder.addCase(extraActions.getAccountHistory.rejected, (state) => {
            state.loading = false;
            state.loginData = null;
        });
    },
});

function createExtraActions() {
    return {
        getAccountHistory: getAccountHistory(),
    };

    function getAccountHistory() {
        return createAsyncThunk(
            "accountHistory/getAccountHistory",
            async () => {
                try {
                    const response = await axios.get(
                        `${baseUrl}/GetAccountHistory`,
                    );
                    const accountHistory = response.data;
                    return {
                        accountHistory,
                    };
                } catch (error) {
                    console.error(
                        "accountHistory getirilirken hata oluştu:",
                        error,
                    );
                    throw error;
                }
            },
        );
    }
}

export const accountHistoryActions = { ...slice.actions, ...extraActions };

export const accountHistoryReducer = slice.reducer;
