import React, { useEffect, useState } from "react";
import { Reorder } from "framer-motion";
import CategoryAccordion from "./components/CategoryAccordion";
import { useDispatch, useSelector } from "react-redux";
import { menuServiceActions } from "../../store/menu.service.slice";
import ProductAccordion from "./components/ProductAccordion";
import AddedAccordion from "./components/AddedAccordion";
import AlertModal from "../../components/alert-modal/AlertModal";

const Category = ({ categoryId, category }) => {
    return renderCategory(categoryId, category);
};
export default Category;

const renderCategory = (categoryId, category) => {
    const dispatch = useDispatch();
    const loadingChildren = useSelector(
        (state) => state.menuService.loadingChildren[categoryId] || false,
    );
    const [showCategoryAlert, setShowCategoryAlert] = useState(false);
    const { categories, loadedCategoryIds } = useSelector(
        (state) => state.menuService,
    );

    const getChildrenData = (data) => {
        let result = null;
        data?.some((item) => {
            if (item.id === categoryId) {
                result = item;
                return true;
            } else if (item?.subCategories?.length > 0) {
                result = getChildrenData(item.subCategories);
                return !!result;
            }
            return false;
        });
        return result;
    };

    const subCategoryData =
        getChildrenData(Object.values(categories))?.subCategories || [];

    const productData =
        getChildrenData(Object.values(categories))?.products || [];

    const handleClick = () => {
        if (!loadedCategoryIds.includes(categoryId)) {
            dispatch(menuServiceActions.getCategoryChildren(categoryId));
        }
    };

    const addSubCategory = (id, title) => {
        dispatch(menuServiceActions.setParentCategoryInfo({ id, title }));
        dispatch(menuServiceActions.setShowCategoryModal(true));
    };

    const addProduct = (id, title) => {
        dispatch(menuServiceActions.setParentCategoryInfo({ id, title }));
        dispatch(menuServiceActions.setShowProductModal(true));
    };

    const onEditCategory = () => {
        dispatch(menuServiceActions.getCategoryDetailsById(categoryId));
        dispatch(menuServiceActions.setShowCategoryModal(true));
    };

    const onDeleteCategory = () => {
        dispatch(menuServiceActions.deleteCategory(categoryId));
        setShowCategoryAlert(false);
    };

    return (
        <>
            <Reorder.Item
                whileDrag={{ backgroundColor: "#34ebbd", scale: 1.1 }}
                value={categoryId}
            >
                <CategoryAccordion
                    id={category?.id}
                    title={category?.title?.TRTR}
                    image={category?.image}
                    onClickAccordion={handleClick}
                    onEditClick={onEditCategory}
                    onDeleteClick={() => setShowCategoryAlert(true)}
                >
                    {subCategoryData?.length > 0 && !loadingChildren && (
                        <AddedAccordion
                            addCategory={() =>
                                addSubCategory(
                                    category?.id,
                                    category?.title?.TRTR,
                                )
                            }
                            showCategoryAddButton
                        />
                    )}

                    <SubCategories subCategories={subCategoryData} />

                    {productData?.length > 0 && !loadingChildren && (
                        <AddedAccordion
                            addProduct={() =>
                                addProduct(category?.id, category?.title?.TRTR)
                            }
                            showProductAddButton
                        />
                    )}
                    <Products products={productData} categoryId={categoryId} />
                    {productData?.length === 0 &&
                        subCategoryData?.length === 0 &&
                        !loadingChildren && (
                            <AddedAccordion
                                addCategory={() =>
                                    addSubCategory(
                                        category?.id,
                                        category?.title?.TRTR,
                                    )
                                }
                                addProduct={() =>
                                    addProduct(
                                        category?.id,
                                        category?.title?.TRTR,
                                    )
                                }
                                showCategoryAddButton
                                showProductAddButton
                            />
                        )}
                </CategoryAccordion>
            </Reorder.Item>

            <AlertModal
                isOpen={showCategoryAlert}
                title="Kategoriyi silmek istediğinize emin misiniz?"
                message="Bu kategori ile birlikte var ise alt kategorileri ve ürünleri de silinecektir"
                onSave={onDeleteCategory}
                onClose={() => setShowCategoryAlert(false)}
            />
        </>
    );
};

const SubCategories = ({ subCategories }) => {
    const dispatch = useDispatch();
    const [subCategoryOrder, setSubCategoryOrder] = useState([]);

    useEffect(() => {
        const sortedSubCategories = [...subCategories].sort(
            (a, b) => a.order - b.order,
        );
        const sortedSubCategoryIds = sortedSubCategories.map(
            (category) => category.id,
        );
        setSubCategoryOrder(sortedSubCategoryIds);
    }, [subCategories]);

    const handleSubCategoryReorder = (newOrder) => {
        setSubCategoryOrder(newOrder);

        const data = {
            subCategories: newOrder.map((categoryId, index) => ({
                id: categoryId,
                order: index,
            })),
        };

        dispatch(menuServiceActions.reOrderSubCategories(data));
    };

    return (
        <Reorder.Group
            axis="y"
            values={subCategoryOrder}
            onReorder={handleSubCategoryReorder}
        >
            {subCategoryOrder?.map((subCategoryId) => (
                <Category
                    key={subCategoryId}
                    categoryId={subCategoryId}
                    category={subCategories?.find(
                        (x) => x.id === subCategoryId,
                    )}
                />
            ))}
        </Reorder.Group>
    );
};

const Products = ({ products, categoryId }) => {
    const dispatch = useDispatch();
    const [productsOrder, setProductsOrder] = useState([]);
    const [showProductAlert, setShowProductAlert] = useState(false);
    const [deletedProductId, setDeletedProductId] = useState();

    useEffect(() => {
        const sortedProducts = [...products].sort((a, b) => a.order - b.order);
        const sortedProductIds = sortedProducts.map((product) => product.id);
        setProductsOrder(sortedProductIds);
    }, [products]);

    const handleProductsReorder = (newOrder) => {
        setProductsOrder(newOrder);

        const data = {
            products: newOrder.map((id, i) => ({
                id,
                order: i,
            })),
        };
        dispatch(menuServiceActions.reOrderSubCategoryProducts(data));
    };

    const getProduct = (productId) => {
        return products?.find((x) => x.id === productId);
    };

    const onChangeFavorite = (e, productId) => {
        const data = {
            id: productId,
            favorite: e,
        };
        dispatch(menuServiceActions.modifyProductFavorite(data));
        dispatch(
            menuServiceActions.setProductInfo({
                productId,
                categoryId,
                favorite: e,
            }),
        );
    };

    const onChangeStatus = (e, productId) => {
        const data = {
            id: productId,
            status: e,
        };
        dispatch(menuServiceActions.modifyProductStatus(data));
        dispatch(
            menuServiceActions.setProductInfo({
                productId,
                categoryId,
                status: e,
            }),
        );
    };

    const onEditProduct = (id) => {
        dispatch(menuServiceActions.getProductDetailsById(id));
        dispatch(menuServiceActions.setShowProductModal(true));
    };

    const onDeleteProduct = () => {
        dispatch(
            menuServiceActions.deleteProduct({
                productId: deletedProductId,
                categoryId,
            }),
        );
        setShowProductAlert(false);
    };

    return (
        <>
            <Reorder.Group
                axis="y"
                values={productsOrder}
                onReorder={handleProductsReorder}
            >
                {productsOrder?.map((productId) => (
                    <Reorder.Item
                        key={productId}
                        whileDrag={{ scale: 1.1 }}
                        value={productId}
                    >
                        <ProductAccordion
                            id={productId}
                            title={getProduct(productId)?.title?.TRTR}
                            image={getProduct(productId)?.image}
                            status={getProduct(productId)?.status}
                            favorite={getProduct(productId)?.favorite}
                            onChangeStatus={(e) => onChangeStatus(e, productId)}
                            onChangeFavorite={(e) =>
                                onChangeFavorite(e, productId)
                            }
                            onDeleteClick={() => {
                                setShowProductAlert(true);
                                setDeletedProductId(productId);
                            }}
                            onEditClick={() => onEditProduct(productId)}
                        />
                    </Reorder.Item>
                ))}
            </Reorder.Group>

            <AlertModal
                isOpen={showProductAlert}
                title="Ürünü silmek istediğinize emin misiniz?"
                onSave={onDeleteProduct}
                onClose={() => setShowProductAlert(false)}
            />
        </>
    );
};
