import React, { useEffect } from "react";
import Card from "../../components/card/Card";
import { useDispatch, useSelector } from "react-redux";
import { statisticsActions } from "../../store/statistics.slice";
import { Line, Bar } from "react-chartjs-2";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from "chart.js";

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    Title,
    Tooltip,
    Legend,
);

const StatisticsLayout = () => {
    const dispatch = useDispatch();
    const statistics = useSelector((state) => state.statistics.statistics);

    useEffect(() => {
        dispatch(statisticsActions.getStatistics());
    }, [dispatch]);
    const days = [
        "Pazar",
        "Pazartesi",
        "Salı",
        "Çarşamba",
        "Perşembe",
        "Cuma",
        "Cumartesi",
    ];
    let salesData = [];
    if (statistics && statistics.data) {
        salesData = Object.entries(statistics.data).map(([day, sales]) => ({
            day: days[new Date(day).getDay()],
            sales: parseFloat(sales),
        }));
    }
    const bestDay = statistics?.total?.most_sold?.day;
    const bestDayIndex = days.findIndex(
        (d) => d.toLowerCase() === bestDay?.toLowerCase(),
    );
    const bestDayName = bestDayIndex !== -1 ? days[bestDayIndex] : "";
    const barData = {
        labels: salesData.map((d) => d.day),
        datasets: [
            {
                label: "Haftalık Satış Grafiği (₺)",
                data: salesData.map((d) => d.sales),
                backgroundColor: "rgba(255, 99, 132, 0.5)",
                borderColor: "rgba(255, 99, 132, 1)",
                borderWidth: 1,
            },
        ],
    };

    const lineData = {
        labels: salesData.map((d) => d.day),
        datasets: [
            {
                label: "Haftalık Bahşiş Grafiği (₺)",
                data: salesData.map((d) => d.sales),
                fill: false,
                backgroundColor: "teal",
                borderColor: "teal",
            },
        ],
    };
    return (
        <div className="pb-4">
            <div className="flex gap-4">
                <div className="w-1/3">
                    <Card className="flex">
                        <div className="w-5/6">
                            Toplam Günlük Satış
                            <br /> {statistics?.total?.daily_sales}₺
                        </div>
                        <div className="w-1/6 self-center">₺</div>
                    </Card>
                </div>
                <div className="w-1/3">
                    <Card className="flex">
                        <div className="w-5/6">
                            Toplam Günlük Bahşiş
                            <br /> {statistics?.total?.daily_tip}₺
                        </div>
                        <div className="w-1/6 self-center">₺</div>
                    </Card>
                </div>
                <div className="w-1/3">
                    <Card className="flex">
                        <div className="w-5/6">
                            Günlük Toplam
                            <br />{" "}
                            {statistics
                                ? parseFloat(statistics.total.daily_sales) +
                                  parseFloat(statistics.total.daily_tip)
                                : 0}
                            ₺
                        </div>
                        <div className="w-1/6 self-center">₺</div>
                    </Card>
                </div>
            </div>
            <div className="flex justify-center pt-4 gap-4">
                <div className="w-1/3">
                    <Card className="flex">
                        <div className="w-5/6">
                            Haftalık Ortalama Satış
                            <br /> {statistics?.total?.weekly_average}₺
                        </div>
                        <div className="w-1/6 self-center">₺</div>
                    </Card>
                </div>
                <div className="w-1/3">
                    <Card className="flex">
                        <div className="w-5/6">
                            En İyi Gün
                            <br /> {bestDayName} -{" "}
                            {statistics?.total?.most_sold?.sales}₺
                        </div>
                        <div className="w-1/6 self-center">₺</div>
                    </Card>
                </div>
            </div>

            <div className="flex justify-center pt-4 gap-4 ">
                <div className="w-1/2">
                    <Card>
                        <Bar data={barData} />
                    </Card>
                </div>
                <div className="w-1/2">
                    <Card>
                        <Line data={lineData} />
                    </Card>
                </div>
            </div>
        </div>
    );
};

export default StatisticsLayout;
