import React, { useRef } from "react";
import PropTypes from "prop-types";
import { TfiClose } from "react-icons/tfi";
import { useDispatch } from "react-redux";
import { orderManagementActions } from "../../../store/order-management.slice";
import { replaceTurkishChars } from "../../../helpers/textHelpers";

const OrderBox = ({ notification }) => {
    const printContentRef = useRef(null);
    const createdAt = new Date(notification?.created_at);
    const hour = String(createdAt.getHours()).padStart(2, "0");
    const minute = String(createdAt.getMinutes()).padStart(2, "0");
    const formattedTime = `${hour}:${minute}`;
    const printOrders = () => {
        const content = `
            <html>
                <head>
                    <title>Print</title>
                    <style>
                        @media print {
                            body * {
                                visibility: hidden;
                            }
                            .order-box, .order-box * {
                                visibility: visible;
                            }
                            .order-box {
                                position: absolute;
                                left: 5mm;
                                top: 5mm;
                                width: calc(100% - 10mm);
                                height: auto;
                                font-size: 15px;
                                font-weight: bold;
                            }
                            table {
                                width: 100%;
                                border-collapse: collapse;
                                font-size: 13px;
                                margin-bottom: 10px;
                            }
                            th, td {
                                border: 1px solid black;
                                padding: 5px;
                                text-align: left;
                            }
                            th {
                                font-size: 14px;
                            }
                        }
                    </style>
                </head>
                <body>
                    <div class="order-box">
                        <div class="order-title">
                            <p>${replaceTurkishChars(formattedTime)}
                               ${replaceTurkishChars(notification?.name || "")}</p> 
                        </div>
                        <table>
                            <thead>
                                <tr>
                                    <th>Ürün</th>
                                    <th>Miktar</th>
                                </tr>
                            </thead>
                            <tbody>
                                ${notification?.products
                                    ?.filter(
                                        (item) =>
                                            item && item.title && item.quantity,
                                    )
                                    .map(
                                        (item) => ` 
                                        <tr>
                                            <td>${replaceTurkishChars(item?.title.TRTR || "")}</td>
                                            <td style="text-align: right;">${item?.quantity || ""}x</td>
                                        </tr>
                                        ${
                                            item?.note
                                                ? `<tr><td colspan="2">Not: ${replaceTurkishChars(item.note)}</td></tr>`
                                                : ""
                                        }
                                        ${
                                            item?.removed_material
                                                ? `<tr><td colspan="2">Çıkarılacak Malzeme: ${replaceTurkishChars(item.removed_material)}</td></tr>`
                                                : ""
                                        }
                                    `,
                                    )
                                    .join("")}
                            </tbody>
                        </table>
                    </div>
                </body>
            </html>
        `;
        const iframe = document.createElement("iframe");
        iframe.style.position = "absolute";
        iframe.style.top = "-10000px";
        iframe.style.left = "-10000px";
        iframe.style.width = "0";
        iframe.style.height = "0";
        iframe.style.border = "none";
        document.body.appendChild(iframe);
        const iframeWindow = iframe.contentWindow;
        const iframeDoc = iframe.contentDocument || iframeWindow.document;
        iframeDoc.open();
        iframeDoc.write(content);
        iframeDoc.close();

        iframeWindow.focus();
        iframeWindow.print();
        setTimeout(() => {
            document.body.removeChild(iframe);
        }, 1000);
    };

    const dispatch = useDispatch();
    const handleCloseNotification = () => {
        const requestData = { id: notification?.order_id, type: 3 };
        dispatch(orderManagementActions.confirmNotification(requestData));
    };
    return (
        <div className={`w-full !bg-red-300 rounded-md shadow-sm mt-1 p-1`}>
            <div className="flex justify-between ">
                <p className="text-xs">{notification?.name}</p>
                <p className="text-xs">Yeni Sipariş ( {formattedTime})</p>
                <button
                    className="text-black p-1"
                    onClick={handleCloseNotification}
                >
                    <TfiClose />
                </button>
            </div>
            <div ref={printContentRef}>
                {notification?.products?.map((item, index) => (
                    <div key={index} className="flex text-xs ">
                        <p>{item?.quantity}</p>x <div className="w-2" />
                        <p>{item?.title?.TRTR}</p>
                    </div>
                ))}
            </div>

            <button
                className={` mt-2 items-center p-1 h-full w-full text-center justify-center !bg-gray-200  !rounded-[5px]`}
                onClick={printOrders}
            >
                Fiş Yazdır
            </button>
        </div>
    );
};

OrderBox.propTypes = {
    notification: PropTypes.object.isRequired,
};

export default OrderBox;
