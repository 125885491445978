import React from "react";
import IconContainer from "../../../components/icon-container/IconContainer";
import { FaRegEdit } from "react-icons/fa";

const ProductPreferencesList = ({ preferences, onEdit }) => {
    return (
        <div>
            {preferences.map((preference, index) => (
                <div
                    key={index}
                    className="mb-4 p-3 border !border-teal-300 rounded-md"
                >
                    <div className="flex justify-between">
                        <h5 className="text-lg font-semibold">
                            {preference.title?.TRTR}
                        </h5>
                        <div className="text-sm font-semibold text-gray-600">
                            {preference.isRequired ? "Zorunlu" : "Opsiyonel"}
                        </div>
                    </div>

                    <p className="text-sm text-gray-600 font-semibold">
                        Tür:
                        <span className="font-normal ml-1">
                            {preference.optionType === "SINGLE"
                                ? "Tekli Seçim"
                                : "Çoklu Seçim"}
                        </span>
                    </p>
                    {preference.optionType === "MULTIPLE" &&
                        preference.maxOptionSelect && (
                            <p className="text-sm text-gray-600 font-semibold">
                                Maksimum Seçim Sayısı:{" "}
                                <span className="font-normal ml-1">
                                    {preference.maxOptionSelect}
                                </span>
                            </p>
                        )}
                    <div className="flex flex-col">
                        <label>Seçimler:</label>

                        <div className="flex justify-between">
                            <div className="flex-1">
                                {preference.options.map((option, index) => (
                                    <div key={index} className="p-2">
                                        <span>{option.title?.TRTR}</span>
                                        {option.extraPrice && (
                                            <span className="text-teal-500 ml-1">
                                                {`(+ ₺${option.extraPrice})`}
                                            </span>
                                        )}
                                    </div>
                                ))}
                            </div>
                            <div className="flex items-end">
                                <IconContainer
                                    className="cursor-pointer"
                                    onClick={(e) => {
                                        e.stopPropagation();

                                        onEdit(preference);
                                    }}
                                    as={FaRegEdit}
                                    color="teal.500"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default ProductPreferencesList;
