import React from "react";
import TableProcess from "./TableProcess";
import AddTableSpace from "./AddTableSpace";
import TableScreening from "./TableScreening";
const TableComponent = () => {
    return (
        <>
            <TableProcess />
            <AddTableSpace />
            <TableScreening />
        </>
    );
};

export default TableComponent;
