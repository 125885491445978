import React, { memo, useState } from "react";
import IconContainer from "../../../components/icon-container/IconContainer";
import ImageContainer from "../../../components/image-container/ImageContainer";
import { FaRegEdit, FaRegTrashAlt } from "react-icons/fa";
import { IoReorderTwoOutline } from "react-icons/io5";
import { Tooltip } from "react-tooltip";

const ProductAccordion = ({
    id,
    title,
    image,
    status,
    favorite,
    onEditClick = () => {},
    onDeleteClick = () => {},
    onChangeStatus = () => {},
    onChangeFavorite = () => {},
}) => {
    const [localStatus, setLocalStatus] = useState(status || false);
    const [localFavorite, setLocalFavorite] = useState(favorite || false);

    return (
        <div className="bg-white !rounded-[15px] flex mt-2 items-center p-2 h-full w-full cursor-pointer">
            <div className="flex flex-row justify-between w-full">
                <div className="flex justify-between w-full p-1 items-center">
                    <div className="flex space-x-3 items-center">
                        <IconContainer
                            as={IoReorderTwoOutline}
                            color="gray.500"
                        />
                        <ImageContainer image={image} />
                        <div className="font-semibold flex items-center">
                            {title}
                        </div>
                    </div>
                    <div className="flex space-x-3 items-center">
                        <div className="flex space-x-3 items-center">
                            <i
                                className={`fa fa-heart mt-1 ${localFavorite ? "text-teal-500" : "text-gray-500 "} text-xl`}
                                onClick={() => {
                                    onChangeFavorite(!localFavorite);
                                    setLocalFavorite(!localFavorite);
                                }}
                            ></i>
                        </div>
                        <div className="relative pt-2 flex !items-center">
                            <input
                                type="checkbox"
                                className="toggle-checkbox hidden"
                                checked={localStatus}
                                onChange={(e) => {
                                    onChangeStatus(e.target.checked);
                                    setLocalStatus(e.target.checked);
                                }}
                            />
                            <label
                                className="toggle-label flex items-center cursor-pointer"
                                onClick={() => {
                                    setLocalStatus(!localStatus);
                                    onChangeStatus(!localStatus);
                                }}
                            >
                                <div
                                    className={`w-8 h-4 bg-gray-300 rounded-full transition-colors duration-300 ${
                                        localStatus
                                            ? "bg-teal-500"
                                            : "bg-gray-300"
                                    }`}
                                >
                                    <div
                                        className={`w-4 h-4 bg-white rounded-full shadow-md transition-transform duration-300 ${
                                            localStatus
                                                ? "translate-x-5"
                                                : "translate-x-0"
                                        }`}
                                    ></div>
                                </div>
                            </label>
                        </div>

                        <Tooltip
                            className="!text-xs"
                            id={id}
                            data-tooltip-content="Stok Durumu"
                        />
                        <IconContainer
                            className="mr-4 w-5 cursor-pointer"
                            onClick={(e) => {
                                e.stopPropagation();
                                onEditClick();
                            }}
                            as={FaRegEdit}
                            color="teal.500"
                        />

                        <IconContainer
                            className="mr-4 w-5 cursor-pointer"
                            onClick={(e) => {
                                e.stopPropagation();
                                onDeleteClick();
                            }}
                            as={FaRegTrashAlt}
                            color="red.500"
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default memo(ProductAccordion);
