import React from "react";
import Badge from "../../../components/badge/Badge";

const RemovedMaterialInput = ({
    label,
    value = "",
    onChange,
    badgeText,
    IconComponent,
}) => {
    const handleKeyDown = (e) => {
        if (e.key === "Enter") {
            e.preventDefault();
            const newIngredient = e.target.value.trim();
            if (
                newIngredient !== "" &&
                !getIngredients().includes(newIngredient)
            ) {
                onChange(getIngredients().concat(newIngredient).join(", "));
                e.target.value = "";
            }
        }
    };

    const getIngredients = () => {
        return value
            .split(",")
            .map((ingredient) => ingredient.trim())
            .filter(Boolean);
    };

    return (
        <div>
            <div className="flex gap-2">
                <label
                    htmlFor="ingredient"
                    className="font-semibold !flex gap-1 items-center"
                >
                    {label}
                    <span className="italic text-xs font-light ml-2">
                        Malzeme ismini yazıp entera basabilirsiniz.
                    </span>
                    {badgeText && <Badge text={badgeText} />}
                    {IconComponent}
                </label>
            </div>

            {getIngredients().length > 0 && (
                <div className="mb-2">
                    {getIngredients().map((ingredient, index) => (
                        <span
                            key={index}
                            className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2"
                        >
                            {ingredient}
                        </span>
                    ))}
                </div>
            )}

            <input
                type="text"
                id="ingredient"
                onKeyDown={handleKeyDown}
                className="w-full !border px-2 !border-gray-300 rounded-md h-8 !focus:border-teal-500 !focus:outline-none"
            />
        </div>
    );
};

export default RemovedMaterialInput;
